import React, { useLayoutEffect, useMemo } from 'react';
import { SafeAreaView, StyleSheet, Image, View, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { colors, images } from '../../../utils';
import dayjs from 'dayjs';

const NoticeDetail = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const notificationHistoryList = useSelector(
    (state: any) => state.client.notificationHistoryList,
  );
  const currentNotificationId = useSelector(
    (state: any) => state.client.currentNotificationId,
  );

  const currentNotification = useMemo(() => {
    const temp = notificationHistoryList.records.filter(
      (item) => item.article.id === currentNotificationId,
    );
    return temp.length > 0 ? temp[0] : null;
  }, [notificationHistoryList, currentNotificationId]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: currentNotification.merchant.name,
    });
    dispatch({
      type: 'client/markNotificationAsRead',
      payload: { id: currentNotification.article.id },
    });
  }, [navigation, currentNotification, dispatch]);

  return (
    currentNotification && (
      <SafeAreaView style={styles.mainContainer}>
        <View style={styles.container}>
          <Text style={styles.date}>
            {dayjs(
              currentNotification.updateTime
                ? currentNotification.updateTime
                : currentNotification.createTime,
            ).format('YYYY-MM-DD HH:mm')}
          </Text>
          <View style={styles.contentBody}>
            <View>
              <Image
                source={
                  currentNotification.icon
                    ? { uri: currentNotification.icon }
                    : images.LOGO
                }
                style={styles.senderImage}
              />
            </View>
            <View style={styles.msgContainer}>
              <Text style={styles.msgText}>{currentNotification.content}</Text>
              <TriAddon />
            </View>
          </View>
        </View>
      </SafeAreaView>
    )
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    paddingTop: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 20,
    minHeight: 50,
    flex: 1,
  },
  date: { color: colors.GRAY, fontSize: 12, marginBottom: 8 },
  contentBody: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  senderImage: { width: 50, height: 50, borderRadius: 50 },
  msgContainer: {
    backgroundColor: colors.WHITE,
    flex: 1,
    marginLeft: 15,
    borderRadius: 4,
    alignSelf: 'flex-start',
  },
  msgText: {
    color: colors.TUNDORA,
    fontSize: 14,
    margin: 10,
    height: 'auto',
  },
});

const triAddonStyles = StyleSheet.create({
  whiteLeft: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 5.66,
    borderRightWidth: 11.32,
    borderBottomWidth: 5.66,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: colors.WHITE,
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    position: 'absolute',
    top: 21,
    left: -5.5,
  },
});

const TriAddon = () => {
  return <View style={triAddonStyles.whiteLeft} />;
};

export default NoticeDetail;
