import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';
import Flex from '@ant-design/react-native/lib/flex';
import BackgroundView from '../../../../components/MBackgroundView';
import imageUtil from '../../../../utils/image';

type PaymentButtonProps = {
  type?: 'wechat' | 'alipay';
  selected?: boolean;
} & TouchableOpacityProps;

const layouts = {
  wechat: {
    iconImage: imageUtil.WEIXIN_ICON,
    label: '微信提现',
  },
  alipay: {
    iconImage: imageUtil.ZHIFUBAO_ICON,
    label: '支付宝提现',
  },
};

const PaymentButton = ({
  type = 'wechat',
  selected = false,
  ...rest
}: PaymentButtonProps) => {
  const bgImage = selected ? 'PERIOD_FOCUSED' : 'PERIOD_BLURRED';
  const labelColor = selected ? '#D90000' : '#444444';
  const { label, iconImage } = layouts[type];

  return (
    <TouchableOpacity {...rest}>
      <BackgroundView
        style={styles.container}
        image={bgImage}
        Style={{ Image: { width: 140, height: 47.6 } }}>
        <Flex justify="center" align="center">
          <Image style={styles.icon} source={iconImage} />
          <Text style={[styles.label, { color: labelColor }]}>{label}</Text>
        </Flex>
      </BackgroundView>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 140,
    height: 47.6,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 6,
  },
  label: {
    fontSize: 16,
  },
});

export default PaymentButton;
