import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import HTML from 'react-native-render-html';
import { useNavigation } from '@react-navigation/native';
import Toast from '@ant-design/react-native/lib/toast';

import { colors, images } from '../utils';
import { MOrderItem } from '../components';

// const { height } = Dimensions.get('window');

const BalancePayment = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const currentArticle = useSelector(
    (state: any) => state.clientMerchant.currentArticle,
  );
  const { id, amount } = currentArticle;

  const height = useSelector((state: any) => state.screen.height);

  const currentClient = useSelector((state: any) => state.client.currentClient);

  useEffect(() => {
    if (currentClient.balance < amount) {
      navigation.navigate('BalanceRecharge', { id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { wxts: '' };

  const [agreeTerms, setAgreeTerms] = useState(true);

  const confirmPay = useCallback(() => {
    if (agreeTerms) {
      dispatch({
        type: 'client/purchaseOrder',
        payload: { articleId: id },
      });
    } else {
      Toast.info('请先阅读并同意《码上来料使用协议》', 1);
    }
  }, [agreeTerms, dispatch, id]);

  useEffect(() => {
    dispatch({
      type: 'agreement/getByCode',
      payload: { code: 'wxts' },
    });
  }, [dispatch]);

  return (
    <SafeAreaView style={[styles.layout, { height }]}>
      <ScrollView style={styles.scrollLayout}>
        <View style={styles.orderPriceSection}>
          <Text style={styles.orderPriceText}>订单总价</Text>
          <Text style={styles.orderPriceText}>{amount}元</Text>
        </View>
        <MOrderItem
          id={id}
          title={currentArticle.title}
          createTime={currentArticle.createTime}
          updateTime={currentArticle.updateTime}
          limitTime={currentArticle.limitTime}
          published={currentArticle.published}
          price={currentArticle.price}
          refundable={currentArticle.refundable}
          period={currentArticle.period}
          tag={currentArticle.tag}
        />
        <View style={styles.accBalance}>
          <Text style={styles.accBalanceText}>
            账户：{currentClient.nickname}
          </Text>
          <Text style={styles.accBalanceValue}>
            余额：{currentClient.balance}元
          </Text>
        </View>
        <View style={styles.paymentTermsSection}>
          {agreementsByCode.wxts?.content !== '' && (
            <HTML source={{ html: agreementsByCode.wxts?.content ?? '' }} />
          )}
          <TouchableOpacity
            style={styles.agreeTerms}
            onPress={() => setAgreeTerms(!agreeTerms)}>
            <Image
              source={
                agreeTerms
                  ? images.RADIO_CHECK_CHECKED
                  : images.RADIO_CHECK_EMPTY
              }
              style={styles.agreeTermsCheck}
            />
            <Text style={styles.agreeTermsText01}>支付即表示同意</Text>
            <TouchableOpacity onPress={() => navigation.navigate('UseTerms')}>
              <Text style={styles.agreeTermsText02}>《码上来料使用协议》</Text>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <TouchableOpacity onPress={confirmPay} style={styles.confirmPaymentBtn}>
        <Text style={styles.confirmPaymentBtnText}>确认支付</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  layout: {
    // height,
    position: 'relative',
  },
  scrollLayout: {
    marginBottom: 47,
  },
  orderPriceSection: {
    backgroundColor: colors.WHITE,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 22.5,
    paddingRight: 20,
    marginBottom: 8,
  },
  orderPriceText: { color: colors.TUNDORA, fontSize: 14 },
  goBillDetails: {
    marginRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  goBillDetailsText: { fontSize: 15, color: colors.WHITE },
  accBalance: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.WHITE,
    paddingLeft: 22.5,
    paddingRight: 20,
    alignItems: 'center',
    marginBottom: 8,
  },
  accBalanceText: { color: colors.TUNDORA, fontSize: 14 },
  accBalanceValue: { color: colors.GUARDSMAN_RED, fontSize: 14 },
  paymentTermsSection: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 20,
    paddingBottom: 21,
  },
  paymentTermsHeader: { color: colors.GRAY, fontSize: 12 },
  paymentTermsText: {
    color: colors.GRAY,
    fontSize: 12,
    lineHeight: 16.5,
  },
  agreeTerms: { flexDirection: 'row', marginTop: 6 },
  agreeTermsCheck: { width: 12, height: 12 },
  agreeTermsText01: { color: colors.GRAY, fontSize: 11, marginLeft: 4 },
  agreeTermsText02: {
    color: colors.GUARDSMAN_RED,
    fontSize: 11,
    marginLeft: 4,
  },
  confirmPaymentBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
    width: '100%',
    position: 'absolute',
    bottom: 47,
  },
  confirmPaymentBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    fontWeight: '700',
  },
});

export default BalancePayment;
