/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import dayjs from 'dayjs';
import _ from 'lodash';
import { colors, images } from '../utils';
import MAutoImage from './MAutoImage';
import MerchantHeader from '../screens/MerchantHeader';
import Flex from '@ant-design/react-native/lib/flex';
import { useDispatch, useSelector } from 'react-redux';
import MEmptyBox from './MEmptyBox';
import MAllLoadedBox from './MAllLoadedBox';
import Toast from '@ant-design/react-native/lib/toast';
import { useNavigation } from '@react-navigation/native';
import { encode as base64_encode } from 'base-64';
let { width } = Dimensions.get('window');
width -= 32;

const OrderDetails = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {
    title = '',
    mode = 0,
    period = 1,
    refundable = true,
    limitTime = '',
    createTime = '',
    intro = '',
    introImages = [],
    articleChunks = [],
    onHomeClick = () => {}, //作者主页事件
    isShowTitle = true,
    merchantInfo, //作者信息
    onDelete, //删除文章更新，商家才有的功能，用户不显示
    contestRules = [],
    redLabel,
    customLabel,
    business,
    tag,
    isPay,
    publishTime,
    isPresale = 1,
    presaleTime = '',
    articleId,
    articlePlanId,
    merchantId,
    publishFlg,
  } = props;

  const source =
    tag === '0'
      ? null
      : tag === '1'
      ? images.LABEL_RED
      : tag === '2'
      ? images.LABEL_BLACK
      : images.LABEL_GREEN;

  const canSee = articleChunks.length > 0;
  const reversedArticleChunks = _.reverse([...articleChunks]);
  let flag;

  const [fold, setFold] = useState(isPay);

  const feed = useSelector((state: any) => state.client.feed);
  const paramRefundTips = useSelector((state: any) =>
    state.parameter.parametersByCode.refund_tips
      ? state.parameter.parametersByCode.refund_tips.itemValue
      : '',
  );
  const paramNoRefundTips = useSelector((state: any) =>
    state.parameter.parametersByCode.no_refund_tips
      ? state.parameter.parametersByCode.no_refund_tips.itemValue
      : '',
  );

  useEffect(() => {
    if (mode == 2) {
      flag = isPay == 1 ? true : false;
    } else {
      console.log(articleChunks.length > 0, '98888');
      flag = articleChunks.length > 0 ? true : false;
    }
    if (publishFlg == 1) {
      flag = false;
    }
    setFold(flag);
  }, [mode, articleChunks, isPay, publishFlg]);

  useEffect(() => {
    dispatch({
      type: 'client/getPlanDetailPage',
      payload: {
        currentPage: 1,
        articlePlanId: articleId,
        merchantId: merchantId,
        pageSize: 10,
      },
    });
  }, [dispatch, merchantId, articleId]);

  const introInfoView = () => {
    return (
      <>
        <View style={styles.lineBox}>
          <View style={styles.leftLine} />
          <Text style={styles.p1}>
            {mode == 2 ? '以下为套餐简介' : '以下为文章简介'}
          </Text>
          <View style={styles.rightLine} />
        </View>
        <TouchableOpacity onPress={() => setFold(!fold)}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Text>{fold ? '展开' : '折叠'}</Text>
            <Image
              source={fold ? images.DOWN : images.UP}
              style={styles.topLeftGuessStatusLabel1}
            />
          </View>
        </TouchableOpacity>

        {fold ? null : (
          <View style={styles.contentSection3}>
            <Text selectable style={styles.contentText}>
              {intro}
            </Text>
          </View>
        )}
        {fold ? null : (
          <View style={styles.imageStyle}>
            {introImages?.map((ii: { path: string }, imageIndex: any) => (
              <MAutoImage
                key={`no-paied-introImage-${ii}-${imageIndex}`}
                uri={ii.path}
                width={width}
                style={styles.imageStyle}
              />
            ))}
          </View>
        )}
      </>
    );
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.gameView}
      onPress={() =>
        item.unionArticleNum > 1
          ? props.props.navigation.push('EventMaterial', {
              id: item.id,
              business: business,
              ruleStartTime: item.ruleStartTime,
              descriptionPk: item.descriptionPk,
              merchantInfo,
            })
          : null
      }>
      <View>
        <Text style={styles.gameText}>
          {dayjs(item.ruleStartTime).format('MM-DD HH:mm')}
          &nbsp;&nbsp;{item.descriptionPk}
        </Text>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {item.unionArticleNum > 1 && (
          <>
            <Text style={{ color: '#888888', fontSize: 12 }}>
              共{item.unionArticleNum}个料
            </Text>
            <Image
              source={images.PERSONAL_ARROW}
              style={styles.rightArrowImage}
            />
          </>
        )}
      </View>
    </TouchableOpacity>
  );

  const renderList = ({ item }) => (
    <>
      <TouchableOpacity style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View style={styles.btnlogin}>
            <Text style={styles.btntext}>料</Text>
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{item.title}</Text>
          </View>
        </View>
        <View>
          <TouchableOpacity
            onPress={() => {
              if (isPay) {
                navigation.navigate('OrderDetail1', {
                  articleId: base64_encode(item.id),
                });
              } else {
                Toast.info('暂未支付');
              }
            }}>
            <Text>查看</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </>
  );

  return (
    <View>
      {isShowTitle ? (
        <MerchantHeader
          merchantInfo={merchantInfo}
          onHomeClick={onHomeClick}
          isSmallSize={true}
        />
      ) : undefined}

      <View style={styles.headerSection}>
        <Text style={styles.headerTitle}>{title}</Text>

        {period > 0 || refundable ? (
          <View style={styles.labelSection}>
            {mode === 1 && period > 0 ? (
              <View style={styles.topLeftPeriodSection}>
                <Text style={styles.topLeftPeriodText}>包时{period}天</Text>
              </View>
            ) : null}
            {refundable ? (
              <Image
                source={images.ORDER_ITEM_LABEL_NOT_REFUNDED}
                style={styles.labelNotRefundedImage}
              />
            ) : null}
            {tag && (
              <Image source={source} style={styles.topLeftGuessStatusLabel} />
            )}
          </View>
        ) : tag ? (
          <Image source={source} style={styles.topLeftGuessStatusLabel} />
        ) : null}
        {publishTime !== null && publishTime ? (
          <Text style={{ color: colors.RED_00, marginTop: 5 }}>
            公开时间： {dayjs(publishTime).format('YYYY-MM-DD HH:mm')}
          </Text>
        ) : null}
        {limitTime !== '' && limitTime !== null ? (
          <View style={styles.limitSection}>
            <Text style={styles.limitDate}>
              {dayjs(limitTime).format('YYYY-MM-DD')}
            </Text>
            <Text style={styles.limitText}> 到期</Text>
          </View>
        ) : null}

        {refundable == 1 && mode == 0 && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.refundText}>{paramRefundTips}</Text>
          </View>
        )}
        {refundable == 0 && mode == 0 && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.refundText}>{paramNoRefundTips}</Text>
          </View>
        )}

        {articlePlanId && (
          <View
            style={{
              alignContent: 'center',
              alignItems: 'center',
              marginTop: 8,
              justifyContent: 'flex-start',
              flexDirection: 'row',
            }}>
            <Text
              onPress={() => {
                navigation.navigate('Meal', {
                  articleId,
                  type: 2,
                });
                // navigation.navigate('OrderDetail1', {
                //   articleId: base64_encode(articlePlanId),
                // });
              }}
              style={styles.mealText}>
              该料已加入套餐，购买套餐更划算 {'>>'}
            </Text>
          </View>
        )}
        <View style={{ flexDirection: 'row', paddingTop: 12 }}>
          <Text style={styles.articleDate}>
            {isPresale === 1 &&
            presaleTime !== null &&
            presaleTime !== '' &&
            dayjs(presaleTime).isAfter(dayjs())
              ? '预计更新时间：' + dayjs(presaleTime).format('YYYY-MM-DD HH:mm')
              : dayjs(createTime).format('YYYY-MM-DD HH:mm')}
          </Text>
          {redLabel !== null && redLabel !== '' && (
            <>
              <View
                style={{
                  width: 2,
                  backgroundColor: colors.RED_00,
                  marginLeft: 6,
                  marginRight: 2,
                  height: 15,
                }}
              />
              <View style={styles.redLabel}>
                <Text style={styles.redLabelText}>{redLabel}</Text>
                <Text style={styles.lianText}>连红</Text>
              </View>
            </>
          )}
          {customLabel !== null && customLabel !== '' && (
            <View style={{ marginLeft: 3 }}>
              <Text style={styles.customLabel}>{customLabel}</Text>
            </View>
          )}
          {isPresale === 1 &&
            presaleTime !== null &&
            presaleTime !== '' &&
            dayjs(presaleTime).isAfter(dayjs()) && (
              <View style={styles.presaleLabel}>
                <Text style={styles.presaleText}>预售</Text>
              </View>
            )}
        </View>
        {contestRules?.length > 0 && (
          <FlatList
            data={contestRules}
            renderItem={renderItem}
            style={{ marginBottom: 10 }}
          />
        )}
      </View>

      <View>
        {
          // articleChunks>0则属于商家自己查看，=0属于用户查看，by Evan 21/0722
        }
        {reversedArticleChunks.length < 1 ? (
          <View style={styles.chunkContainer}>
            {introInfoView()}
            {mode == 2 && (
              <>
                <View style={styles.lineBox}>
                  <View style={styles.leftLine} />
                  <Text style={styles.p1}>以下为套餐内容</Text>
                  <View style={styles.rightLine} />
                </View>
                <FlatList
                  style={{ marginTop: 15 }}
                  data={feed.records}
                  renderItem={renderList}
                  keyExtractor={(item) => item.id}
                  refreshing={false}
                  onRefresh={() => {}}
                  onEndReached={() => {
                    if (!feed.isAllLoaded) {
                      // fetchData(feed.page);
                    }
                  }}
                  ListEmptyComponent={<MEmptyBox title="暂无数据" />}
                  ListFooterComponent={
                    feed.records.length && feed.isAllLoaded ? (
                      <MAllLoadedBox />
                    ) : null
                  }
                />
              </>
            )}
          </View>
        ) : null}
        {reversedArticleChunks.map((ac, index) => {
          return (
            <View
              key={index}
              style={
                index !== reversedArticleChunks.length - 1 &&
                styles.chunkContainer
              }>
              <Flex justify="between">
                <Text style={styles.articleDate}>
                  {index === reversedArticleChunks.length - 1
                    ? '发布 '
                    : '更新 '}
                  {dayjs(ac.createTime).format('YYYY-MM-DD HH:mm')}
                </Text>
                {index !== reversedArticleChunks.length - 1 && onDelete && (
                  <TouchableOpacity
                    onPress={() => onDelete(ac.id)}
                    style={styles.deleteBtn}>
                    <Text>删除</Text>
                  </TouchableOpacity>
                )}
              </Flex>
              <>
                <View style={styles.lineBox}>
                  <View style={styles.leftLine} />
                  <Text style={[styles.p1, { color: '#FFA940' }]}>
                    以下为付费内容
                  </Text>
                  <View style={styles.rightLine} />
                </View>
              </>
              {ac.content !== '' && (
                <Text style={styles.contentText2}>{ac.content}</Text>
              )}

              {/* 以下为付费内容 */}

              {ac.articleImages.map((aai, imageIndex) => (
                <MAutoImage
                  key={`articleImage-${index}-${imageIndex}`}
                  uri={aai.path}
                  width={width}
                  style={styles.imageStyle}
                />
              ))}
              {index === reversedArticleChunks.length - 1 && introInfoView()}
            </View>
          );
        })}
      </View>
      {!canSee && (
        <View style={styles.noDataSection}>
          <Image source={images.NO_DATA} style={styles.noDataImage} />
          <Text style={styles.noDataText}>支付解锁文章内容</Text>
        </View>
      )}
      {canSee && (
        <View style={styles.bottomSection}>
          <Image source={images.ORDER_LOGO} style={styles.orderLogo} />
          <Text style={styles.agreementText}>
            免责声明：所有文章内容仅代表发布者个人意见，仅供参考！{'\n'}
            与码上来料无关。读者应独立决策并自行承担风险。
          </Text>
        </View>
      )}
    </View>
  );
};

const lineWidth = (width - 128) / 2;
const styles = StyleSheet.create({
  redLabelText: {
    color: colors.WHITE,
    backgroundColor: colors.RED_00,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    fontWeight: 'bold',
  },
  lianText: {
    color: colors.RED_00,
    backgroundColor: colors.WHITE,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    borderColor: colors.RED_00,
    borderWidth: 1,
  },
  topLeftGuessStatusLabel: {
    width: 16,
    height: 16,
    marginLeft: 6,
  },
  contentText2: { color: '#AD6800', fontSize: 14, lineHeight: 20 },
  deleteBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 76,

    height: 28,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: colors.GRAY,
    fontSize: 14,
    zIndex: 99,
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: colors.GRAY,
  },
  customLabel: {
    color: colors.RED_00,
    borderColor: colors.RED_00,
    borderWidth: 1,
    borderRadius: 1,
    fontSize: 10,
    height: 15,
    paddingVertical: 1,
    paddingHorizontal: 2,
    paddingLeft: 2,
    lineHeight: 13,
  },
  redLabel: {
    flexDirection: 'row',
    marginRight: 6,
    borderRadius: 1,
    height: 15,
  },
  gameView: {
    padding: 5,
    backgroundColor: '#F5F5F5',
    borderRadius: 2,
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gameText: {
    color: '#2A2F66',
    lineHeight: 20,
    width: width - 80,
  },
  rightArrowImage: {
    width: 6.5,
    height: 12,
    tintColor: '#888888',
    marginLeft: 8,
  },
  headerSection: {
    marginTop: 18,
  },
  headerTitle: {
    color: colors.GRAY20,
    fontSize: 18,
    lineHeight: 25,
    marginBottom: 4,
    fontWeight: '700',
  },
  labelSection: { flexDirection: 'row', alignItems: 'center' },
  topLeftPeriodSection: {
    backgroundColor: colors.MEDIUM_SLATE_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginRight: 8,
    padding: 2.5,
  },
  topLeftPeriodText: { color: colors.WHITE, fontSize: 11 },
  labelNotRefundedImage: { width: 52, height: 16 },
  articleDate: { color: colors.GRAY, fontSize: 13 },
  watermarkBG: {
    opacity: 0.5,
    resizeMode: 'repeat',
  },
  watermarkImage: {
    marginTop: 8,
  },
  orderLogo: {
    alignSelf: 'center',
    marginTop: 50,
    marginBottom: 70,
    width: 156,
    height: 63,
  },
  contentText: { color: colors.TUNDORA, fontSize: 14, lineHeight: 20 },
  noDataSection: { alignItems: 'center', marginTop: 40, marginBottom: 60 },
  noDataImage: { width: 235, height: 160 },
  noDataText: { color: colors.DUSTY_GRAY, fontSize: 13 },
  purchaseSection: {
    position: 'absolute',
    backgroundColor: colors.WILD_SAND,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    width: '100%',
    bottom: 0,
  },
  purchasePriceSection: { flexDirection: 'row', alignItems: 'center' },
  purchasePriceTitle: { color: colors.TUNDORA, fontSize: 16, marginRight: 4 },
  purchasePriceDiscount: {
    color: colors.RED_00,
    fontSize: 16,
    fontWeight: '700',
    marginRight: 7.5,
  },
  purchasePriceOrigin: {
    color: colors.GRAY,
    fontSize: 12,
    marginRight: 7.5,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  purchasePriceDiscountDesc: { color: colors.GRAY, fontSize: 12 },
  purchaseBtnSection: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 35,
    width: 120,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  purchaseBtnText: { color: colors.WHITE, fontSize: 18 },
  imageStyle: {
    marginVertical: 8,
  },
  chunkContainer: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.MERCURY,
  },
  limitSection: {
    flexDirection: 'row',
  },
  limitDate: {
    color: colors.GUARDSMAN_RED,
    fontSize: 14,
    lineHeight: 20,
    marginTop: 8,
    marginBottom: -6,
  },
  limitText: {
    color: colors.GRAY,
    fontSize: 14,
    lineHeight: 20,
    marginTop: 8,
    marginBottom: -6,
  },
  agreementText: {
    color: colors.GRAY,
    fontSize: 10,
    marginTop: -60,
    textAlign: 'center',
    lineheight: 13,
  },
  bottomSection: {
    marginBottom: 70,
  },
  p1: {
    color: '#888',
    fontSize: 12,
  },
  leftLine: {
    marginRight: 10,
    width: lineWidth,
    height: 1,
    backgroundColor: '#E5E5E5',
  },
  rightLine: {
    marginLeft: 10,
    width: lineWidth,
    height: 1,
    backgroundColor: '#E5E5E5',
  },
  lineBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 5,
  },

  viewShotContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 20,
    paddingTop: 10,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  container: {
    width: 500,
    backgroundColor: 'transparent',
  },
  presaleLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 16,
    width: 40,
    backgroundColor: '#f76a1c',
    marginLeft: 6,
  },
  presaleText: {
    color: colors.WHITE,
    fontSize: 10,
  },
  topLeftGuessStatusLabel1: {
    width: 16,
    height: 16,
  },
  contentSection3: { flex: 1, marginTop: 10 },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemContentSection: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'center',
  },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 13,
    marginBottom: 4,
  },
  btnlogin: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    backgroundColor: colors.GUARDSMAN_RED,
    borderRadius: 6,
  },
  btntext: {
    color: 'white',
    alignItems: 'center',
  },
  refundText: {
    color: '#AD6800',
    padding: 6,
    fontSize: 12,
  },
  mealText: {
    color: '#AD6800',
    fontSize: 12,
    borderColor: '#AD6800',
    borderWidth: 1,
    height: 24,
    paddingRight: 4,
    paddingLeft: 4,
    lineHeight: 24,
  },
});

export default OrderDetails;
