import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '@ant-design/react-native/lib/toast';

import { colors } from '../../../utils';
import { sendCode } from '../../../services/client/client';

const ModContNumber = () => {
  const dispatch = useDispatch();

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [interval, setIntervalState] = useState<NodeJS.Timeout | null>(null);
  const [countdown, setCountdown] = useState<any>('获取验证码');

  const opacity = countdown === '获取验证码' ? 1 : 0.6;

  const onGetVerifyCode = useCallback(async () => {
    //防止重复点击
    if (countdown !== '获取验证码') {
      return;
    }

    if (!phoneNumber.trim()) {
      Toast.info('请输入手机号', 1);
      return;
    }

    const response = await sendCode({ phone: phoneNumber });
    if (response.success) {
      let key = setInterval(() => {
        setCountdown((state: any) => {
          if (parseInt(state, 10) < 1) {
            clearInterval(key);
            setIntervalState(null);
          }
          let currentTime =
            state === '获取验证码'
              ? '60'
              : parseInt(state, 10) < 1
              ? '获取验证码'
              : parseInt(state, 10) - 1;
          return currentTime;
        });
      }, 1000);
      setIntervalState(key);
    } else {
      Toast.info(response.msg, 1);
    }
  }, [countdown, phoneNumber]);

  const onSave = useCallback(() => {
    if (phoneNumber) {
      dispatch({
        type: 'client/updatePhoneNumber',
        payload: {
          oldPhone: currentClient.phoneNumber,
          newPhone: phoneNumber,
          code: verifyCode,
        },
      });
    } else {
      Toast.info('请输入新手机号', 1);
    }
  }, [currentClient.phoneNumber, dispatch, phoneNumber, verifyCode]);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
        clearTimeout(interval);
      }
    };
  }, [interval]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.mainContainer}>
        <View style={styles.inputForm}>
          {currentClient.phoneNumber && (
            <View style={styles.inputItem}>
              <Text style={styles.inputLabel}>原手机号</Text>
              <Text style={styles.inputDefault01}>
                {currentClient.phoneNumber}
              </Text>
            </View>
          )}
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>
              {currentClient.phoneNumber ? '新手机号' : '手机号'}
            </Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入手机号"
              value={phoneNumber}
              keyboardType="phone-pad"
              onChangeText={(value) => {
                setPhoneNumber(value);
              }}
              style={styles.inputDefault01}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>验证码</Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入验证码"
              value={verifyCode}
              keyboardType="decimal-pad"
              onChangeText={(value) => {
                setVerifyCode(value);
              }}
              style={styles.inputDefault01}
            />
            <TouchableOpacity
              disabled={countdown !== '获取验证码'}
              onPress={onGetVerifyCode}
              style={[styles.getVerifyBtn, { opacity }]}>
              <Text style={styles.getVerifyBtnText}>{countdown}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.saveBtn}
          onPress={onSave}>
          <Text style={styles.saveBtnText}>保 存</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 25,
  },
  inputForm: { marginTop: 30 },
  inputItem: {
    backgroundColor: colors.WHITE,
    height: 45,
    marginBottom: 10,
  },
  inputLabel: {
    position: 'absolute',
    color: colors.TUNDORA,
    fontSize: 14,
    left: 17,
    top: 17,
  },
  inputDefault01: {
    color: colors.GRAY,
    fontSize: 14,
    paddingTop: 15.5,
    paddingLeft: 87,
  },
  getVerifyBtn: {
    position: 'absolute',
    right: 0,
    backgroundColor: colors.GUARDSMAN_RED,
    width: 100,
    height: 45,
    paddingVertical: 15.5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  getVerifyBtnText: {
    color: colors.WHITE,
    textAlign: 'center',
    fontSize: 14,
  },
  saveBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    borderRadius: 7.5,
    alignSelf: 'center',
    marginTop: 52.5,
  },
  saveBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
    paddingVertical: 14.5,
  },
});

export default ModContNumber;
