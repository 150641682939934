const TRANSPARENT = 'transparent';
const BLACK = '#000000';
const BLACK_PEARL = '#001D26';
const AZURE_RADIANCE = '#0091FF';
const DODGER_BLUE04 = '#0079FF';
const DODGER_BLUE01 = '#108EE9';
const DODGER_BLUE02 = '#1677FF';
const DODGER_BLUE = '#32C5FF';
const GRAY20 = '#333333';
const EUCALYPTUS = '#3D9464';
const TUNDORA = '#444444';
const MEDIUM_SLATE_BLUE = '#656BF4';
const GREY_SUIT = '#8B8B8D';
const GRAY = '#888888';
const DUSTY_GRAY = '#999';
const MEDIUM_CARMINE = '#A83A3A';
const CARDINAL = '#BA222D';
const SILVER = '#CCCCCC';
const N1500 = '#D8D8D8';
const GUARDSMAN_RED = '#D90000';
const GAINSBORO_LIGHT = '#ddd';
const MERCURY = '#E5E5E5';
const RED_00 = '#E60012';
const RED_ALIZARIN = '#EE2322';
const DEFAULT_BG_COLOR = '#f2f2f2';
const WILD_SAND = '#F5F5F5';
const ALABASTER = '#F9F9F9';
const GHOST_WHITE = '#F9F9FB';
const SELECTIVE_YELLOW = '#FCB602';
const RED_ORANGE = '#FF3B30';
const CHABLIS = '#FFF3F3';
const CORN_SILK = '#FFF7DF';
const SNOW = '#FFF9F9';
const WHITE = '#FFFFFF';

export default {
  TRANSPARENT,
  BLACK,
  BLACK_PEARL,
  AZURE_RADIANCE,
  DODGER_BLUE,
  TUNDORA,
  GRAY,
  EUCALYPTUS,
  SILVER,
  GUARDSMAN_RED,
  MERCURY,
  WILD_SAND,
  ALABASTER,
  CHABLIS,
  WHITE,
  DODGER_BLUE01,
  DODGER_BLUE02,
  DODGER_BLUE04,
  DEFAULT_BG_COLOR,
  GRAY20,
  RED_ORANGE,
  N1500,
  GAINSBORO_LIGHT,
  RED_ALIZARIN,
  SNOW,
  MEDIUM_CARMINE,
  DUSTY_GRAY,
  RED_00,
  MEDIUM_SLATE_BLUE,
  GREY_SUIT,
  CORN_SILK,
  GHOST_WHITE,
  SELECTIVE_YELLOW,
  CARDINAL,
};
