/*
 * @Author: lizhengkai
 * @Date: 2022-04-14 15:12:14
 * @FilePath: /msll-client/src/screens/Client/MyPage/Attention.tsx
 */
import React, { useCallback, useEffect } from 'react';
import {
  SafeAreaView,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  View,
  Image,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { colors, images } from '../../../utils';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import dayjs from 'dayjs';

const Attention = (props: any) => {
  const dispatch = useDispatch();
  const invitationList = useSelector(
    (state: any) => state.client.invitationList,
  );
  const attentionList = useSelector((state: any) => state.client.attentionList);
  const id = props.route.params?.id;

  const currentData = invitationList?.records.filter((item) => item.id === id);

  const fetchData = useCallback(
    (page) => {
      dispatch({
        type: 'client/myInviteUserRelations',
        payload: {
          currentPage: page,
          pageSize: 10,
          userId: id,
        },
      });
    },
    [dispatch, id],
  );

  useEffect(() => {
    dispatch({
      type: 'client/myInviteUserRelations',
      payload: {
        currentPage: 1,
        pageSize: 10,
        userId: id,
      },
    });
  }, [dispatch, id]);

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View>
            <Image
              source={
                item.avatar ? { uri: item.avatar } : images.DEFAULT_AVATAR
              }
              style={styles.merchantItemProfileImage}
            />
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{item.name}</Text>
            <Text style={styles.merchantItemDesc}>{item.intro}</Text>
          </View>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View
        style={[styles.merchantItemMain, { backgroundColor: colors.RED_00 }]}>
        <View style={styles.merchantItemContentSection}>
          <View>
            <Image
              source={
                currentData[0].avatar
                  ? { uri: currentData[0].avatar }
                  : images.DEFAULT_AVATAR
              }
              style={styles.merchantItemProfileImage}
            />
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={[styles.merchantItemName, { color: colors.WHITE }]}>
              {currentData[0].nickname}
            </Text>
            <Text style={[styles.merchantItemDesc, { color: colors.WHITE }]}>
              注册：{dayjs(currentData[0].createTime).format('YYYY-MM-DD')}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.attentionCount}>
        <Text>关注商家({attentionList.total})</Text>
      </View>
      <FlatList
        data={attentionList.records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        refreshing={false}
        onRefresh={() => fetchData(1)}
        onEndReached={() => {
          if (!attentionList.isAllLoaded) {
            fetchData(attentionList.page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无数据" />}
        ListFooterComponent={
          attentionList.records.length && attentionList.isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: '#F5F5F5',
  },
  menuRight: {
    marginRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuRightText: { color: colors.WHITE, fontSize: 15 },
  header: {
    backgroundColor: '#ffffff',
  },
  searchBarContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  searchBar: {
    backgroundColor: colors.WILD_SAND,
    paddingLeft: 34,
    height: 35,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 4,
  },
  searchInput: {
    color: colors.DUSTY_GRAY,
    fontSize: 14,
    minWidth: 200,
    padding: 0,
    width: '100%',
  },
  searchIcon: { position: 'absolute', width: 14, height: 14, left: 12 },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
    backgroundColor: colors.WHITE,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemProfileImage: { width: 60, height: 60, borderRadius: 60 },
  merchantItemNoticeNumberSection: {
    position: 'absolute',
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    backgroundColor: colors.RED_ORANGE,
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    top: -3.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 4,
  },
  merchantItemDesc: {
    flex: 1,
    color: colors.GRAY,
    fontSize: 13,
    lineHeight: 16,
    textAlign: 'justify',
    marginTop: 5,
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
  attentionCount: {
    height: 40,
    paddingLeft: 16,
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
    marginBottom: 16,
    color: '#444444',
  },
});

export default Attention;
