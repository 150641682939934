import Toast from '@ant-design/react-native/lib/toast';
import Wechat from 'weixin-js-sdk';
import { goBack, navigate, reset } from '../../navigators/RootNavigation';
import {
  getCurrentClient,
  changeNickname,
  changeGender,
  changeAvatar,
  applyMerchant,
  updatePhoneNumber,
  getNotificationHistory,
  markNotificationAsRead,
  getArticleIdByTradeNo,
  isApplyMerchant,
  getPayConfigs,
  getClientMessage,
  myInviteUserStat,
  articleType,
  checkSubscribe,
  getPromotionMerchants,
  getUserBindBank,
  getMerchantsQRCode,
  userWalletRefund,
  getMyCoupons,
  getAvailableCoupons,
  getDiscountCardList,
  getDiscountCardOrder,
  buyDiscountCard,
  getAdapayVosByMerchantId,
  getPlanDetailPage,
  earningPageList,
  withdrawPageList,
  getPlanByArticleId,
} from '../../services/client/client';
import {
  adaAliPay,
  adaWxPay,
  getPayStatus,
  walletPayment,
  adaAliRecharge,
  adapayWxLiteRecharge,
  userWalletBalance,
  submitSuggest,
} from '../../services/client/order';

const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
const SET_APPLY_STATUS = 'SET_APPLY_STATUS';
const SET_ALL_TYPE = 'SET_ALL_TYPE';
const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
const SET_CURRENT_NOTIFICATION_ID = 'SET_CURRENT_NOTIFICATION_ID';
const USER_BALANCE = 'USER_BALANCE';
const PROMOTION_MERCHANT = 'PROMOTION_MERCHANT';
const USERBINDBANk = 'USERBINDBANk';
const GETQRCODE = 'GETQRCODE';
const GET_PLAN = 'GET_PLAN';

const EARNING = 'EARNING';
const WITHDRAW = 'WITHDRAW';

/** 获取支付对象 */
const SET_PAY_CONFIGS = 'SET_PAY_CONFIGS';
const SET_ALL_LIST = 'SET_ALL_LIST';
const TONGJI = 'TONGJI';
const SET_GZ = 'SET_GZ';

const SET_ALL_FEED = 'SET_ALL_FEED';

const SET_PAY_LIST = 'SET_PAY_LIST';

export default {
  namespace: 'client',
  state: {
    currentClient: {},
    purchaseSuccess: 0,
    applySuccess: 0,
    userBalance: 0,
    /** 支付配置信息 */
    payConfigs: [],
    getAdapays: undefined,
    getClientMessage: {},
    tongJi: {
      total: '',
      today: '',
    },
    articleType: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    feed: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    promotionMerchant: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    gzFlag: false,
    bankCard: '',
    myCoupons: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    availableCoupons: [],
    /** 优惠卡列表 */
    discountCardList: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    /** 优惠卡订单 */
    discountCardOrder: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    //套餐列表
    getPlanPage: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
  },
  effects: {
    *getCurrentClient({ payload }, { put, call }) {
      const { success, data } = yield call(getCurrentClient, payload);
      if (success) {
        //用户被平台封禁
        if (data.status === 0) {
          reset('DisableUser');
          yield put({
            type: SET_CURRENT_CLIENT,
            payload: { status: 0 },
          });
        } else {
          yield put({
            type: SET_CURRENT_CLIENT,
            payload: data,
          });
          if (payload.merchantId) {
            //检测是关注公众号
            yield put({
              type: 'isGzFlag',
              payload: {
                code: data.openid,
                merchantId: payload.merchantId,
              },
            });
          }
        }
      }
    },
    *getPlanPage({ payload }, { put, call }) {
      try {
        const res = yield call(getPlanByArticleId, payload);
        if (res.success) {
          yield put({
            type: GET_PLAN,
            payload: {
              page: payload.currentPage || 1,
              result: res.data,
            },
          });
        }
      } catch (error) {
        console.log('获取套餐列表失败', error);
      }
    },
    //获取用户绑定银行卡
    *getUserBindBank({ payload }, { put, call }) {
      const res = yield call(getUserBindBank, payload);
      if (res.success) {
        yield put({
          type: USERBINDBANk,
          payload: res.data,
        });
      }
    },
    //获取二维码
    *getMerchantsQRCode({ payload }, { put, call }) {
      const { success, data, msg } = yield call(getMerchantsQRCode, payload);
      if (success) {
        yield put({
          type: GETQRCODE,
          payload: {
            qrCodeUrl: data.qrCodeUrl,
          },
        });
      } else {
        Toast.fail(msg);
        yield put({
          type: GETQRCODE,
          payload: {
            qrCodeUrl: false,
          },
        });
      }
    },
    //邀请统计
    *myInviteUserStat({ payload }, { put, call }) {
      const res = yield call(myInviteUserStat, payload);
      if (res.success) {
        yield put({
          type: TONGJI,
          payload: {
            page: payload.currentPage,
            tongji: res.data,
          },
        });
      }
    },
    //是否关注
    *isGzFlag({ payload }, { put, call }) {
      const res = yield call(checkSubscribe, payload);
      if (res.success) {
        yield put({
          type: SET_GZ,
        });
      }
    },
    //查询参与推广的商家
    *getPromotionMerchants({ payload }, { put, call }) {
      const res = yield call(getPromotionMerchants, payload);
      if (res.success) {
        yield put({
          type: PROMOTION_MERCHANT,
          payload: {
            page: payload.currentPage,
            records: res.data.records,
          },
        });
      }
    },
    *purchaseOrder({ payload }) {
      navigate('PaymentSuccess', {
        id: payload.articleId,
      }); //购买完
    },
    *changeAvatar({ payload }, { put, call }) {
      const { success, data } = yield call(changeAvatar, payload);
      if (success) {
        yield put({
          type: SET_CURRENT_CLIENT,
          payload: data,
        });
      }
    },
    //获取好友列表
    *getClientMessage({ payload }, { put, call }) {
      const res = yield call(getClientMessage, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_LIST,
          payload: {
            page: payload.currentPage,
            result: res.data,
          },
        });
      }
    },
    //客户端获取类型
    *articleType({ payload }, { put, call }) {
      const res = yield call(articleType, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_TYPE,
          payload: {
            page: payload.currentPage,
            records: res.data.records,
          },
        });
      }
    },
    *changeNickname({ payload }, { put, call }) {
      const { success, data } = yield call(changeNickname, payload);
      if (success) {
        yield put({
          type: SET_CURRENT_CLIENT,
          payload: data,
        });
      }
    },
    *changeGender({ payload }, { put, call }) {
      const { success, data } = yield call(changeGender, {
        gender: payload.gender,
      });
      if (success) {
        yield put({
          type: SET_CURRENT_CLIENT,
          payload: data,
        });
      }
    },
    *updatePhoneNumber({ payload }, { put, call }) {
      const { success } = yield call(updatePhoneNumber, payload);
      if (success) {
        goBack();
        yield put({
          type: SET_PHONE_NUMBER,
          payload: payload.newPhone,
        });
      }
    },
    *bindPhoneNumber({ payload }, { put, call }) {
      const { success } = yield call(updatePhoneNumber, payload);
      if (success) {
        yield put({
          type: SET_PHONE_NUMBER,
          payload: payload.newPhone,
        });
      }
    },
    *applyMerchant({ payload }, { put, call }) {
      const { success, msg } = yield call(applyMerchant, payload);
      if (success) {
        yield put({
          type: SET_APPLY_STATUS,
          payload: 1,
        });
        navigate('ApplySubmitSuccess');
      } else {
        Toast.info(msg, 1);
        yield put({
          type: SET_APPLY_STATUS,
          payload: 2,
        });
      }
    },
    //退款
    *userWalletRefund({ payload }, { put, call }) {
      const loadingKey = Toast.loading('退款中...');
      const res = yield call(userWalletRefund, payload);
      Toast.remove(loadingKey);
      Toast.info(res.msg);
      if (res.success) {
        const { success, data } = yield call(userWalletBalance);
        if (success) {
          yield put({
            type: 'USER_BALANCE',
            payload: data,
          });
        }
        navigate('TopUp');
      }
    },
    *getNotificationHistory({ payload }, { put, call }) {
      const { success, data } = yield call(
        getNotificationHistory,
        payload,
        false,
      );

      if (success) {
        yield put({
          type: SET_NOTIFICATION_LIST,
          payload: {
            page: payload.page,
            records: data,
          },
        });
      }
    },
    *setCurrentNotificationId({ payload }, { put }) {
      yield put({
        type: SET_CURRENT_NOTIFICATION_ID,
        payload: payload.notificationId,
      });
    },
    *markNotificationAsRead({ payload }, { call }) {
      yield call(markNotificationAsRead, payload);
    },
    *getArticleIdByTradeNo({ payload }, { call, put }) {
      const { success, data } = yield call(getArticleIdByTradeNo, payload);
      if (success) {
        yield put({
          type: 'clientMerchant/getOrderDetail',
          payload: { articleId: data },
        });
      } else {
        navigate('Home', { screen: 'my' });
      }
    },
    *getPlanDetailPage({ payload }, { put, call }) {
      const res = yield call(getPlanDetailPage, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_FEED,
          payload: {
            page: payload.currentPage,
            records: res.data.records,
          },
        });
      }
    },
    // 支付免费文章
    *payFreeArticle({ payload, merchantId, payCallBack }, { call, put }) {
      //0元免费文章无Data数据返回
      const { success, msg } = yield call(adaAliPay, payload);
      if (success) {
        try {
          Toast.info('支付成功！', 1);
          payCallBack(1);
          const { articleId } = payload;
          yield put({
            type: 'clientMerchant/getOrderDetail',
            payload: {
              articleId,
              merchantId,
            },
          });
        } catch (e) {
          console.error(e);
          Toast.info('支付失败，请重试！', 1);
          payCallBack(0);
        }
      } else {
        Toast.info(msg, 1);
        payCallBack(0);
      }
    },
    // 微信支付
    *doWechatPay({ payload, payCallBack }, { call }) {
      const { from } = payload || {};
      delete payload.from;
      const { success, data, msg } = yield call(
        from === 'discountCard' ? buyDiscountCard : adaWxPay,
        payload,
      );
      if (success) {
        try {
          const {
            expend: { pay_info },
          } = data;
          const wxSign = JSON.parse(pay_info); // pay_info 为 JSON 字符串
          wxSign.timestamp = wxSign.timeStamp;
          delete wxSign.timeStamp;
          yield call(Wechat.chooseWXPay, {
            ...wxSign,
            success: function () {
              setTimeout(async () => {
                payCallBack(1);
              }, 1000);
            },
            // 支付取消回调函数
            cancel: function () {
              Toast.info('取消购买', 1);
              payCallBack(0);
            },
            // 支付失败回调函数
            fail: function () {
              Toast.info('购买失败，请联系客服！', 1);
              payCallBack(0);
            },
          });
        } catch (e) {
          Toast.info('异常:' + e, 1);
          payCallBack(0);
        }
      } else {
        Toast.info(msg, 1);
        payCallBack(0);
      }
    },
    // 支付宝支付
    *doAlipayPay({ payload, payCallBack }, { call }) {
      console.log('doAlipayPay参数:', payload);
      const { from } = payload || {};
      delete payload.from;
      const { success, data, msg } = yield call(
        from === 'discountCard' ? buyDiscountCard : adaAliPay,
        payload,
      );
      console.log('doAlipayPay返回值:', success, data, msg);
      if (success) {
        try {
          const {
            order_no,
            expend: { pay_info }, // pay_info 为http链接
          } = data;
          // 存储支付订单
          window.sessionStorage.setItem('alipayOrderId', order_no);
          // 存与后台验证的orderId
          document.location.href =
            '/paysuccess/alipay.html?payUrl=' + encodeURIComponent(pay_info);
        } catch (e) {
          Toast.info('购买失败，请重试！', 1);
          payCallBack(0);
        }
      } else {
        Toast.info(msg, 1);
        payCallBack(0);
      }
    },

    // 余额支付
    *doBalancePay({ payload, merchantId, payCallBack }, { call, put }) {
      console.log('doBalancePay参数:', payload, merchantId);
      const { from } = payload || {};
      delete payload.from;
      const { success, msg } = yield call(
        from === 'discountCard' ? buyDiscountCard : walletPayment,
        payload,
      );
      console.log('doBalancePay返回值:', success, msg);
      if (success) {
        try {
          payCallBack(1);
          Toast.info(msg);
          const { articleId } = payload;
          yield put({
            type: 'clientMerchant/getOrderDetail',
            payload: {
              articleId,
              merchantId,
            },
          });
        } catch (e) {
          console.error(e);
          Toast.info('支付失败，请重试！', 1);
          payCallBack(0);
        }
      } else {
        Toast.info(msg, 1);
        payCallBack(0);
      }
    },
    *isApplyMerchant({ payload }, { call }) {
      const response = yield call(isApplyMerchant, payload);
      console.log(response, 999);
      if (response.data?.status === 0) {
        navigate('ApplySubmitSuccess');
      } else if (response.data?.status === 1) {
        navigate('ApplySubmitWin');
      } else if (response.code === 67) {
        Toast.info(response.msg, 3);
      } else {
        navigate('MerchantApply');
      }
    },
    // 微信充值
    *doWechatRecharge({ payload, payCallBack }, { call }) {
      const { success, data, msg } = yield call(adapayWxLiteRecharge, payload);
      if (success) {
        try {
          const {
            expend: { pay_info },
          } = data;
          const wxSign = JSON.parse(pay_info); // pay_info 为 JSON 字符串
          wxSign.timestamp = wxSign.timeStamp;
          delete wxSign.timeStamp;
          yield call(Wechat.chooseWXPay, {
            ...wxSign,
            success: function () {
              setTimeout(async () => {
                payCallBack();
              }, 1000);
            },
            // 支付取消回调函数
            cancel: function () {
              Toast.info('取消支付', 1);
              payCallBack();
            },
            // 支付失败回调函数
            fail: function () {
              Toast.info('支付失败，请联系客服！', 1);
              payCallBack();
            },
          });
        } catch (e) {
          Toast.info('异常:' + e, 1);
          payCallBack();
        }
      } else {
        Toast.info(msg, 1);
        payCallBack();
      }
    },
    // 支付宝充值
    *doAlipayRecharge({ payload, payCallBack }, { call }) {
      const { success, data, msg } = yield call(adaAliRecharge, payload);
      if (success) {
        try {
          const {
            order_no,
            expend: { pay_info }, // pay_info 为http链接
          } = data;
          // 存储支付订单
          window.sessionStorage.setItem('alipayOrderId', order_no);
          // 存与后台验证的orderId
          document.location.href =
            '/paysuccess/alipay.html?payUrl=' + encodeURIComponent(pay_info);
        } catch (e) {
          Toast.info('充值失败，请重试！', 1);
          payCallBack(0);
        }
      } else {
        Toast.info(msg, 1);
        payCallBack(0);
      }
    },

    //查询余额
    *userWalletBalance(_: any, { put, call }: any) {
      const res = yield call(userWalletBalance);
      if (res.success) {
        yield put({
          type: USER_BALANCE,
          payload: res.data,
        });
      }
    },
    *getPayConfigs(_, { put, call }) {
      const { success, data } = yield call(getPayConfigs);
      console.log('getPayConfigs:' + JSON.stringify(data));
      if (success) {
        yield put({
          type: SET_PAY_CONFIGS,
          payload: data,
        });
      }
    },
    *getAdapayVosByMerchantId({ payload }, { put, call }) {
      const { success, data } = yield call(getAdapayVosByMerchantId, payload);
      if (success) {
        yield put({
          type: SET_PAY_LIST,
          payload: data,
        });
      }
    },
    // 后端验证票据并完成操作
    *getPayStatus({ payload }, { call }) {
      const { uuid, payCallBack } = payload;
      const response = yield call(getPayStatus, { uuid });
      if (response.data.status === 1) {
        payCallBack(1);
      } else {
        payCallBack(0);
        Toast.info('购买失败，请重试！', 1);
      }
    },
    *submitSuggest({ payload }, { call }) {
      const { success } = yield call(submitSuggest, payload);
      if (success) {
        Toast.success(
          '商家投诉提交成功,平台后续会进行处理核实,请注意接听电话',
          3,
          () => {
            Toast.removeAll();
            window.history.back();
          },
        );
      } else {
        Toast.fail('提交失败，请重试');
        setTimeout(Toast.removeAll, 1500);
      }
    },
    //获取优惠券列表
    *getMyCoupons({ payload }, { put, call }) {
      const { success, data } = yield call(getMyCoupons, payload);
      if (success) {
        yield put({
          type: 'SET_PAGE_LIST',
          payload: { ...data, key: 'myCoupons' },
        });
      }
    },
    //获取优惠券列表
    *getAvailableCoupons({ payload }, { put, call }) {
      const { success, data, msg } = yield call(getAvailableCoupons, payload);
      if (success) {
        yield put({
          type: 'SET_STATE',
          payload: { availableCoupons: data },
        });
      } else {
        Toast.fail(msg, 1);
      }
    },
    /** 获取优惠卡列表 */
    *getDiscountCardList({ payload }, { put, call }) {
      const { success, data, msg } = yield call(getDiscountCardList, payload);
      console.log('优惠卡列表', data, msg);
      if (success) {
        yield put({
          type: 'SET_PAGE_LIST',
          payload: { ...data, key: 'discountCardList' },
        });
      } else {
        Toast.fail(msg, 1);
      }
    },
    /** 获取优惠卡订单*/
    *getDiscountCardOrder({ payload }, { put, call }) {
      const { success, data, msg } = yield call(getDiscountCardOrder, payload);
      console.log('优惠卡订单', data, msg);
      if (success) {
        yield put({
          type: 'SET_PAGE_LIST',
          payload: { ...data, key: 'discountCardOrder' },
        });
      } else {
        Toast.fail(msg, 1);
      }
    },
    // 优惠卡购买文章
    *doDiscountCardPay({ payload, merchantId, payCallBack }, { call, put }) {
      console.log('优惠卡购买参数', payload, merchantId);
      const { success, data, msg } = yield call(adaWxPay, payload);
      console.log('优惠卡购买返回值', success, data, msg);
      if (success) {
        try {
          payCallBack(1);
          Toast.info(msg);
          const { articleId } = payload;
          yield put({
            type: 'clientMerchant/getOrderDetail',
            payload: {
              articleId,
              merchantId,
            },
          });
        } catch (e) {
          console.error(e);
          Toast.fail('支付失败，请重试！', 1);
          payCallBack(0);
        }
      } else {
        Toast.fail(msg, 1);
        payCallBack(0);
      }
    },
    //收益明细列表
    *earningPageList({ payload }, { put, call }) {
      const res = yield call(earningPageList, payload);
      if (res.success) {
        yield put({
          type: EARNING,
          payload: {
            page: payload.currentPage,
            records: res.data.records,
            total: res.data.total,
          },
        });
      }
    },
    //提现明细列表
    *withdrawPageList({ payload }, { put, call }) {
      const res = yield call(withdrawPageList, payload);
      if (res.success) {
        yield put({
          type: WITHDRAW,
          payload: {
            page: payload.currentPage,
            records: res.data.records,
            total: res.data.total,
          },
        });
      }
    },
  },
  reducers: {
    USER_BALANCE(state, { payload }) {
      state.userBalance = payload;
    },
    GET_PLAN(state, { payload }) {
      if (payload.page === 0) {
        state.getPlanPage.page = 1;
        state.getPlanPage.records = [];
        state.getPlanPage.isAllLoaded = false;
      }
      if (
        state.getPlanPage.page === payload.page ||
        payload.page === 1 ||
        payload.page === 1
      ) {
        state.getPlanPage.page = payload.page + 1;
        if (payload.page > 1) {
          state.getPlanPage.records = state.getPlanPage.records.concat(
            payload.result.records || [],
          );
        } else {
          state.getPlanPage.records = payload.result.records || [];
        }
        state.getPlanPage.isAllLoaded =
          (payload.result.records || []).length < 10;
        if ((payload.result.records || []).length < 10) {
          state.getPlanPage.page = 1;
        }
      }
    },
    SET_STATE(state, { payload }) {
      return { ...state, ...payload };
    },
    SET_PAY_LIST(state, { payload }) {
      let list: any[] = [];
      for (const key in payload) {
        payload[key].forEach((i) => {
          let obj = { ...i };
          obj.type = key;
          list.push(obj);
        });
      }
      state.getAdapays = list;
    },
    SET_CURRENT_CLIENT(state, { payload }) {
      state.currentClient = payload;
    },
    SET_CURRENT_BALANCE(state, { payload }) {
      state.currentClient.balance = state.currentClient.balance - payload;
    },
    //储存用户统计列表数据
    TONGJI(state, { payload }) {
      state.tongJi = payload.tongji;
    },
    SET_GZ(state, {}) {
      state.gzFlag = true;
    },
    SET_APPLY_STATUS(state, { payload }) {
      state.applySuccess = payload;
    },
    USERBINDBANk(state, { payload }) {
      state.bankCard = payload;
    },
    SET_ALL_TYPE(state, { payload }) {
      if (state.articleType.page === payload.page || payload.page === 1) {
        state.articleType.page = payload.page + 1;
        if (payload.page > 1) {
          state.articleType.records = state.articleType.records.concat(
            payload.records || [],
          );
        } else {
          state.articleType.records =
            [{ id: 0, typeName: '全部料' }, ...payload.records] || [];
        }
        state.articleType.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.articleType.page = 1;
        }
      }
    },
    SET_PAY_CONFIGS(state, { payload }) {
      state.payConfigs = payload;
    },
    GETQRCODE(state, { payload }) {
      state.qrCodeUrl = payload.qrCodeUrl;
    },
    SET_ALL_LIST(state, { payload }) {
      if (payload.result) {
        state.getClientMessage = payload.result || {};
      } else {
        if (!state.getClientMessage) {
          return null;
        }
        const { friends } = state.getClientMessage;

        if (payload.res.onlineFriendsIds instanceof Array) {
          friends.forEach((v, index) => {
            if (v.userId == payload.res.onlineFriendsIds[index]) {
              state.getClientMessage.friends[index].isOnline = 1;
            }
            if (v.userId == payload.res.OfflineFriendsId) {
              state.getClientMessage.friends[index].isOnline = 0;
            }
          });
        } else {
          friends.forEach((v, index) => {
            if (v.userId == payload.res.onlineFriendsIds) {
              state.getClientMessage.friends[index].isOnline = 1;
            }
            if (v.userId == payload.res.OfflineFriendsId) {
              state.getClientMessage.friends[index].isOnline = 0;
            }
          });
        }
      }
    },
    PROMOTION_MERCHANT(state, { payload }) {
      if (state.promotionMerchant.page === payload.page || payload.page === 1) {
        state.promotionMerchant.page = payload.page + 1;
        if (payload.page > 1) {
          state.promotionMerchant.records =
            state.promotionMerchant.records.concat(payload.records || []);
        } else {
          state.promotionMerchant.records = payload.records || [];
        }
        state.promotionMerchant.isAllLoaded =
          (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.promotionMerchant.page = 1;
        }
      }
    },
    /** Page分页公共Reducer更新方法 */
    SET_PAGE_LIST(state: any, { payload }) {
      const { key, current, total, records } = payload;
      const oldDataList = state[key];
      if (oldDataList.current === current || current === 1) {
        oldDataList.current = current + 1;
        oldDataList.total = total;
        if (current > 1) {
          oldDataList.records = oldDataList.records.concat(records || []);
        } else {
          oldDataList.records = records || [];
        }
        oldDataList.isAllLoaded = (records || []).length < 10;
        if ((records || []).length < 10) {
          oldDataList.current = 1;
        }
      }
      state[key] = oldDataList;
    },
    SET_ALL_FEED(state, { payload }) {
      if (state.feed.page === payload.page || payload.page === 1) {
        state.feed.page = payload.page + 1;
        if (payload.page > 1) {
          state.feed.records = state.feed.records.concat(payload.records || []);
        } else {
          state.feed.records = payload.records || [];
        }
        state.feed.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.feed.page = 1;
        }
      }
    },
    EARNING(state, { payload }) {
      if (state.earning.page === payload.page || payload.page === 1) {
        state.earning.page = payload.page + 1;
        state.earning.total = payload.total;
        if (payload.page > 1) {
          state.earning.records = state.earning.records.concat(
            payload.records || [],
          );
        } else {
          state.earning.records = payload.records || [];
        }
        state.earning.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.earning.page = 1;
        }
      }
    },
    WITHDRAW(state, { payload }) {
      if (state.withdraw.page === payload.page || payload.page === 1) {
        state.withdraw.page = payload.page + 1;
        state.withdraw.total = payload.total;
        if (payload.page > 1) {
          state.withdraw.records = state.withdraw.records.concat(
            payload.records || [],
          );
        } else {
          state.withdraw.records = payload.records || [];
        }
        state.withdraw.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.withdraw.page = 1;
        }
      }
    },
  },
};
