import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import AntdProvider from '@ant-design/react-native/lib/provider';

import Navigator from './navigators';
import { store } from './utils';

const App = () => {
  return (
    <ReduxProvider store={store}>
      <AntdProvider>
        <Navigator />
      </AntdProvider>
    </ReduxProvider>
  );
};

export default App;
