import React, { memo } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import imageUtil from '../../../utils/image';
import Toast from '@ant-design/react-native/lib/toast';

type NavIconProps = {
  image?: keyof typeof imageUtil;
  title?: string;
  onPress?: () => void;
  routeName?: string;
  routeParams?: any;
};

const NavIcon = ({
  image,
  title = '',
  onPress = undefined,
  routeName = undefined,
  routeParams = undefined,
}: NavIconProps) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const handlePress = () => {
    if (onPress) {
      return onPress();
    }
    if (routeName == 'Information') {
      Toast.info('下载APP提供私信功能');
    }
    if (routeName && routeName == 'ApplySubmitSuccess') {
      dispatch({
        type: 'client/isApplyMerchant',
        payload: {},
      });
      return;
    }
    if (routeName && routeName != 'Information') {
      navigation.navigate(routeName, routeParams);
    }
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          {!!image && <Image style={styles.image} source={imageUtil[image]} />}
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: 80,
  },
  iconContainer: {
    marginBottom: 8,
  },
  title: {
    fontSize: 13,
  },
  image: {
    width: 44,
    height: 44,
  },
});

export default memo(NavIcon);
