import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from '@ant-design/react-native/lib/toast';
import Portal from '@ant-design/react-native/lib/portal';
import { stringify } from 'qs';

async function get(url, params = {}, isLoading = false) {
  const reqUrl = params ? `${url}?${stringify(params)}` : url;
  return await request(reqUrl, {}, isLoading);
}

async function post(url, params = {}, isLoading = false) {
  return await request(
    url,
    { method: 'POST', body: JSON.stringify(params) },
    isLoading,
  );
}

async function put(url, params = {}, isLoading = false) {
  const reqUrl = params ? `${url}?${stringify(params)}` : url;
  return await request(reqUrl, { method: 'PUT' }, isLoading);
}

// async function put(url, params = {}, isLoading = false) {
//   return await request(
//     url,
//     { method: 'PUT', body: JSON.stringify(params) },
//     isLoading,
//   );
// }

async function patch(url, params = {}, isLoading = false) {
  return await request(
    url,
    { method: 'PATCH', body: JSON.stringify(params) },
    isLoading,
  );
}

async function remove(url, params = {}, isLoading = false) {
  return await request(
    `${url}?${stringify(params)}`,
    { method: 'DELETE' },
    isLoading,
  );
}

async function postForm(url, params = {}, isLoading) {
  return await request(
    url,
    {
      method: 'POST',
      body: params,
      headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
    },
    isLoading,
  );
}
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
async function request(url, options, isLoadding = true) {
  let token;
  try {
    token = await AsyncStorage.getItem('token');
  } catch (e) {
    console.log('async storage error ===>', e);
    token = '';
  }

  //获取本地储存的currentuser
  const defaultOptions = {
    method: 'GET',
  };

  const defaultHeaders: any = {
    'Content-Type': 'application/json;charset=UTF-8',
  };

  //输入header
  const { headers = {} } = options;
  const optionHeader = headers;

  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`;
  }

  const newOptions = {
    ...defaultOptions,
    ...options,
    headers: { ...defaultHeaders, ...optionHeader },
  };

  let key;
  if (isLoadding) {
    key = Toast.loading('加载中', 0, undefined, true);
  }
  return fetch(url, newOptions)
    .then(
      (response) => {
        if (response.status !== 200) {
          return { code: response.status, success: false };
        }
        const json = response.json();
        return json;
      },
      (reason) => {
        Toast.fail(reason);
      },
    )
    .then((response) => {
      if (isLoadding) {
        Portal.remove(key);
      }
      const { code } = response;
      if (code === 401) {
        Toast.info('账号未登录', 1);
        // AsyncStorage.removeItem('token');
      } else if (code === 503) {
        Toast.info('服务器异常', 1);
      } else if (code === 500 || code === 400) {
        Toast.info('错误请求,请检查参数', 1);
      }
      return response;
    })
    .catch((e) => {
      if (isLoadding) {
        Portal.remove(key);
      }
      console.log(e);
      Toast.info('网路信号不稳定，请稍后', 1);
      return { success: false };

      //异常处理，根据需要完善
    });
}

export default { get, post, put, patch, remove, postForm };
