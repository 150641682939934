/*
 * @Author: your name
 * @Date: 2021-08-12 11:48:00
 * @LastEditTime: 2021-08-18 10:09:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /msll-client/src/screens/Client/MyPage/PushGuide.tsx
 */
import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import HTML from 'react-native-render-html';

import { colors } from '../../../utils';

const PushGuide = () => {
  const dispatch = useDispatch();
  const route = useRoute();

  const params = route.params as any;
  const { names } = params;

  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { names: '' };

  useEffect(() => {
    dispatch({
      type: 'agreement/getByCode',
      payload: { code: `${names}` },
    });
  }, [dispatch, names]);

  const source = agreementsByCode[names];

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contentContainer}>
        {source?.content !== '' && (
          <HTML source={{ html: source?.content ?? '' }} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});

export default PushGuide;
