import { getAll, getByCode } from '../services/open/parameter';

const SET_PARAMETERS = 'SET_PARAMETERS';
const SET_PARAMETERS_BY_CODE = 'SET_PARAMETERS_BY_CODE';
const SET_KF = 'SET_KF';
const SET_WX = 'SET_WX';
const SET_URL = 'SET_URL';
const MZSM = 'MZSM';
export default {
  namespace: 'parameter',
  state: {
    parameters: [],
    parametersByCode: {},
    kf: {},
    wx: {},
    h5url: {},
    mzsm: {},
  },
  effects: {
    *getAll({ payload }, { put, call }) {
      const response = yield call(getAll, payload);
      if (response.success) {
        yield put({
          type: SET_PARAMETERS,
          payload: response.data,
        });
      }
    },
    *getByCode({ payload }, { put, call }) {
      const response = yield call(getByCode, payload);
      if (payload.code == 'platform_support_qrcode') {
        yield put({
          type: SET_KF,
          payload: response.data,
        });
      } else if (payload.code == 'platform_support_wechat') {
        yield put({
          type: SET_WX,
          payload: response.data,
        });
      } else if (payload.code == 'jump_h5_url') {
        yield put({
          type: SET_URL,
          payload: response.data,
        });
      } else if (payload.code == 'mzsm') {
        yield put({
          type: MZSM,
          payload: response.data,
        });
      } else {
        yield put({
          type: SET_PARAMETERS_BY_CODE,
          payload: { code: payload.code, ...response.data?.[0] },
        });
      }
    },
  },
  reducers: {
    SET_PARAMETERS(state, { payload }) {
      state.parameters = payload;
    },
    SET_PARAMETERS_BY_CODE(state, { payload }) {
      if (payload) {
        state.parametersByCode[payload.code] = payload;
      }
    },
    SET_KF(state, { payload }) {
      if (payload) {
        state.kf = payload[0];
      }
    },
    SET_WX(state, { payload }) {
      if (payload) {
        state.wx = payload[0];
      }
    },
    SET_URL(state, { payload }) {
      if (payload) {
        state.h5url = payload[0];
      }
    },
    MZSM(state, { payload }) {
      if (payload) {
        state.mzsm = payload[0];
      }
    },
  },
};
