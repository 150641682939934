/*
 * @Author: Evan huang
 * @Date: 2021-07-19 14:34:06
 * @LastEditTime: 2021-08-03 20:59:22
 * @LastEditors: Evan huang
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/models/index.ts
 */
import agreement from './agreement';
import dictionary from './dictionary';
import parameter from './parameter';
import client from './client/client';
import clientArticle from './client/order';
import clientMerchant from './client/merchant';
import wechat from './wechat';
import screen from './screen';
import message from './message';

export default [
  agreement,
  dictionary,
  parameter,
  wechat,
  screen,
  client,
  clientArticle,
  clientMerchant,
  message
];
