import Toast from '@ant-design/react-native/lib/toast';

import {
  getOrders,
  getProducts,
  pinOrder,
  removeOrder,
  getTransactions,
} from '../../services/client/order';
import { getOrderArticle } from '../../services/client/merchant';
import { navigate } from '../../navigators/RootNavigation';

const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_ORDERS_TA = 'SET_ORDERS_TA';
const SET_ORDERS_PERIOD = 'SET_ORDERS_PERIOD';
const SET_TRANSACTION_HISTORY_LIST = 'SET_TRANSACTION_HISTORY_LIST';
const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
const SET_ALL_COMBO = 'SET_ALL_COMBO';

export default {
  namespace: 'clientOrder',
  state: {
    productList: [],
    orderListTA: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    orderListPeriod: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    orderCombo: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    transactionHistoryList: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    currentOrderDetail: {
      data: {},
      headerTitle: '',
    },
  },
  effects: {
    *getProducts({ payload }, { put, call }) {
      const response = yield call(getProducts, payload);
      if (response.success) {
        yield put({
          type: SET_PRODUCTS,
          payload: response.data,
        });
      } else {
        Toast.info(response.msg, 1);
      }
    },
    *getOrders({ payload }, { put, call }) {
      const response = yield call(getOrders, payload);

      if (response.success) {
        if (payload.mode === 0) {
          yield put({
            type: SET_ORDERS_TA,
            payload: {
              page: payload.page,
              records: response.data,
            },
          });
        } else if (payload.mode === 1) {
          yield put({
            type: SET_ORDERS_PERIOD,
            payload: {
              page: payload.page,
              records: response.data,
            },
          });
        }else if (payload.mode === 2) {
          yield put({
            type: SET_ALL_COMBO,
            payload: {
              page: payload.page,
              records: response.data,
            },
          });
        }
      }
    },
    *pinOrder({ payload }, { call }) {
      const response = yield call(pinOrder, payload);
      if (!response.success) {
        Toast.info(response.msg, 1);
      }
    },
    *removeOrder({ payload }, { call }) {
      const response = yield call(removeOrder, payload);
      if (!response.success) {
        Toast.info(response.msg, 1);
      }
    },
    *getTransactions({ payload }, { put, call }) {
      const response = yield call(getTransactions, payload);
      if (response.success) {
        yield put({
          type: SET_TRANSACTION_HISTORY_LIST,
          payload: {
            page: payload.currentPage,
            records: response.data.records,
          },
        });
      } else {
        Toast.info(response.msg, 1);
      }
    },
    *checkOrderDetail({ payload }, { put, call }) {
      const response = yield call(getOrderArticle, {
        articleId: payload.articleId,
      });

      if (response.success) {
        yield put({
          type: SET_CURRENT_ORDER,
          payload: {
            headerTitle: payload.merchantName,
            data: response.data,
          },
        });
        navigate('ClientOrderDetail', { id: payload.articleId });
      } else {
        Toast.info(response.msg, 1);
      }
    },
  },
  reducers: {
    SET_PRODUCTS(state, { payload }) {
      state.productList = payload || [];
    },
    SET_ORDERS_TA(state, { payload }) {
      if (state.orderListTA.page === payload.page || payload.page === 1) {
        state.orderListTA.page = payload.page + 1;
        if (payload.page > 1) {
          state.orderListTA.records = state.orderListTA.records.concat(
            payload.records || [],
          );
        } else {
          state.orderListTA.records = payload.records || [];
        }
        state.orderListTA.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.orderListTA.page = 1;
        }
      }
    },
    SET_ORDERS_PERIOD(state, { payload }) {
      if (state.orderListPeriod.page === payload.page || payload.page === 1) {
        state.orderListPeriod.page = payload.page + 1;
        if (payload.page > 1) {
          state.orderListPeriod.records = state.orderListPeriod.records.concat(
            payload.records || [],
          );
        } else {
          state.orderListPeriod.records = payload.records || [];
        }
        state.orderListPeriod.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.orderListPeriod.page = 1;
        }
      }
    },
    SET_ALL_COMBO(state, { payload }) {
      if (state.orderCombo.page === payload.page || payload.page === 1) {
        state.orderCombo.page = payload.page + 1;
        if (payload.page > 1) {
          state.orderCombo.records = state.orderCombo.records.concat(
            payload.records || [],
          );
        } else {
          state.orderCombo.records = payload.records || [];
        }
        state.orderCombo.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.orderCombo.page = 1;
        }
      }
    },
    SET_TRANSACTION_HISTORY_LIST(state, { payload }) {
      if (
        state.transactionHistoryList.page === payload.page ||
        payload.page === 1
      ) {
        state.transactionHistoryList.page = payload.page + 1;
        if (payload.page > 1) {
          state.transactionHistoryList.records =
            state.transactionHistoryList.records.concat(payload.records || []);
        } else {
          state.transactionHistoryList.records = payload.records || [];
        }
        state.transactionHistoryList.isAllLoaded =
          (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.transactionHistoryList.page = 1;
        }
      } else {
        console.log(state.transactionHistoryList.page, payload.page);
      }
    },
    SET_CURRENT_ORDER(state, { payload }) {
      state.currentOrderDetail.headerTitle = payload.headerTitle;
      state.currentOrderDetail.data = payload.data;
    },
  },
};
