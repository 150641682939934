import React, { useCallback, useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import dayjs from 'dayjs';

import { colors } from '../../../utils';
import { useFocusEffect } from '@react-navigation/native';

const Item = ({ title, date, amount, status, price }) => (
  <View>
    <TouchableOpacity style={styles.balanceHistoryItem}>
      <View style={styles.balanceHistoryHeader}>
        <Text style={styles.balanceHistoryTitle}>
          {title}
          {status === '0'
            ? '(申请中)'
            : status === '1'
            ? '(通过)'
            : status === '2'
            ? '(驳回)'
            : ''}
        </Text>
        <Text style={styles.balanceHistoryAmount}>
          {`${amount > 0 ? '+' + amount : amount}元`}
        </Text>
      </View>
      <View style={styles.detailBottom}>
        <Text style={styles.balanceHistoryDate}>{date}</Text>
        {title === '提现' ? (
          <Text style={styles.detailBottomRight}>
            含手续费（{(amount * -1 - price).toFixed(2)}）元
          </Text>
        ) : (
          <></>
        )}
      </View>
    </TouchableOpacity>
    <View style={styles.balanceHistoryItemBottomBorder} />
  </View>
);

const WalletDetails = () => {
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'WalletDetails' },
      });
    }, [dispatch]),
  );

  useEffect(() => {
    dispatch({
      type: 'clientOrder/getTransactions',
      payload: { currentPage: 1, pageSize: 10 },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'default_user_withdraw_ratio',
      },
    });
  }, [dispatch]);
  const fetchData = useCallback(
    (page) => {
      dispatch({
        type: 'clientOrder/getTransactions',
        payload: { currentPage: page, pageSize: 10 },
      });
    },
    [dispatch],
  );
  const transactionHistoryList = useSelector(
    (state: any) => state.clientOrder.transactionHistoryList,
  );

  const renderItem = ({ item }) => (
    <Item
      status={item.status}
      title={item.remark}
      date={dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss')}
      amount={item.fee}
      price={item.price}
    />
  );

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.container}>
        <FlatList
          data={transactionHistoryList.records}
          renderItem={renderItem}
          keyExtractor={(item, id) => item.userId + item.updateTime + id}
          refreshing={false}
          onRefresh={() => fetchData(1)}
          onEndReachedThreshold={0.1}
          onEndReached={() => {
            console.log('Refreshing', transactionHistoryList.page);
            if (!transactionHistoryList.isAllLoaded) {
              fetchData(transactionHistoryList.page);
            }
          }}
          ListEmptyComponent={<MEmptyBox title="暂无数据" />}
          ListFooterComponent={
            transactionHistoryList.isAllLoaded ? <MAllLoadedBox /> : null
          }
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  container: {
    flex: 1,
    backgroundColor: colors.DEFAULT_BG_COLOR,
  },
  balanceHistoryItem: {
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 16,
    paddingVertical: 12,
    height: 65,
    backgroundColor: colors.WHITE,
  },
  balanceHistoryHeader: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  balanceHistoryTitle: {
    color: colors.TUNDORA,
    fontSize: 14,
    fontWeight: '700',
    width: 280,
  },
  balanceHistoryAmount: {
    color: colors.GUARDSMAN_RED,
    fontSize: 14,
    fontWeight: '700',
  },
  balanceHistoryDate: { color: colors.GRAY, fontSize: 13 },
  detailBottomRight: {
    color: colors.GRAY,
    fontSize: 11,
  },
  balanceHistoryItemBottomBorder: {
    borderColor: colors.MERCURY,
    borderWidth: StyleSheet.hairlineWidth,
    height: 1,
    width: '100%',
    flex: 0,
    marginLeft: 20,
  },
  detailBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default WalletDetails;
