/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';

interface MAutoImageProps {
  uri: string;
  width: number;
  style: any;
}

const MAutoImage = ({ uri, width, style }: MAutoImageProps) => {
  const [height, setHeight] = useState(width);
  useEffect(() => {
    Image.getSize(uri, (w, h) => {
      const imgHeightRatio = w / h;
      setHeight(width / imgHeightRatio);
    });
  }, [uri, width]);
  return (
    <Image
      resizeMode="contain"
      source={{ uri }}
      style={[style, { width, height }]}
    />
  );
};

export default MAutoImage;
