import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Keyboard,
  Image,
  ScrollView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '@ant-design/react-native/lib/toast';
import { decode as base64_decode } from 'base-64';
import { sendSms } from '../../../services/open/sms';
import { colors, images } from '../../../utils';

const MerchantApply = (props) => {
  const inviterMerchantId = props.route.params?.inviterMerchantId;

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'MerchantApply' },
      });
    }, [dispatch]),
  );

  const wx = useSelector((state: any) => state.parameter.wx);

  const kf = useSelector((state: any) => state.parameter.kf);

  const applySuccess = useSelector((state: any) => state.client.applySuccess);

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const [name, setName] = useState('');
  const [wechat, setWechat] = useState('');
  const [fanNumber, setFanNumber] = useState('');
  const [publicNumber, setPublicNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [countdown, setCountdown] = useState<any>('获取验证码');
  const [interval, setIntervalState] = useState<NodeJS.Timeout | null>(null);

  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleSubmit = useCallback(() => {
    if (name.trim() === '') {
      Toast.info('请输入姓名', 1);
    } else if (phone.trim() === '') {
      Toast.info('请输入手机号', 1);
    } else if (verificationCode.trim() === '') {
      Toast.info('请输入验证码', 1);
    } else if (wechat.trim() === '') {
      Toast.info('请输入微信号', 1);
    } else if (fanNumber.trim() === '') {
      Toast.info('请输入粉丝数量', 1);
    } else if (!agreeTerms) {
      console.log(!agreeTerms);
      Toast.info('请先阅读商家入驻协议', 1);
    } else {
      dispatch({
        type: 'client/applyMerchant',
        payload: {
          name,
          phone,
          code: verificationCode,
          wechatNumber: wechat,
          followings: fanNumber,
          publicNumber: publicNumber,
          inviterMerchantId: inviterMerchantId
            ? base64_decode(inviterMerchantId)
            : undefined,
        },
      });
    }
  }, [
    name,
    phone,
    agreeTerms,
    verificationCode,
    wechat,
    fanNumber,
    dispatch,
    publicNumber,
    inviterMerchantId,
  ]);

  useEffect(() => {
    if (currentClient) {
      const clientPhone = currentClient.phoneNumber;
      setPhone(clientPhone);
    }
  }, [currentClient]);

  useEffect(() => {
    if (applySuccess === 1) {
      navigation.navigate('ApplySubmitSuccess');
    } else {
    }
  }, [applySuccess, navigation]);

  const onGetVerifyCode = useCallback(async () => {
    if (!phone.trim()) {
      Toast.info('请输入手机号', 1);
      return;
    }

    //防止重复点击
    if (countdown !== '获取验证码') {
      return;
    } else {
      setCountdown('60');
    }

    const response = await sendSms({ phone: phone });
    if (response.success) {
      let key = setInterval(() => {
        setCountdown((state: any) => {
          if (parseInt(state, 10) < 1) {
            clearInterval(key);
            setIntervalState(null);
          }
          let currentTime =
            state === '获取验证码'
              ? '60'
              : parseInt(state, 10) < 1
              ? '获取验证码'
              : parseInt(state, 10) - 1;
          return currentTime;
        });
      }, 1000);
      setIntervalState(key);
    } else {
      setCountdown('获取验证码');
      Toast.info(response.msg, 1);
    }
  }, [countdown, phone]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView>
        <View style={styles.inputForm}>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>姓名</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入申请人姓名"
              value={name}
              onChangeText={(value) => {
                setName(value);
              }}
              style={styles.inputBox}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>手机号</Text>
            <TextInput
              editable={currentClient?.phoneNumber ? false : true}
              returnKeyType="done"
              placeholder="输入手机号"
              value={phone}
              onChangeText={setPhone}
              onSubmitEditing={() => Keyboard.dismiss()}
              style={styles.inputBox}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>验证码</Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入验证码"
              value={verificationCode}
              onChangeText={setVerificationCode}
              onSubmitEditing={() => Keyboard.dismiss()}
              style={styles.inputBox}
            />
            <TouchableOpacity
              onPress={onGetVerifyCode}
              disabled={countdown !== '获取验证码'}
              style={[styles.sendBtn]}>
              <Text style={styles.sendText}>{countdown}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>微信号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入微信号"
              value={wechat}
              onChangeText={setWechat}
              onSubmitEditing={() => Keyboard.dismiss()}
              style={styles.inputBox}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>粉丝数量</Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入粉丝数"
              value={fanNumber}
              onChangeText={setFanNumber}
              onBlur={() =>
                isNaN(parseInt(fanNumber, 10))
                  ? setFanNumber('')
                  : setFanNumber(parseInt(fanNumber, 10).toString())
              }
              style={styles.inputBox}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>公众号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="选填"
              value={publicNumber}
              onChangeText={setPublicNumber}
              onSubmitEditing={() => Keyboard.dismiss()}
              style={styles.inputBox}
            />
          </View>
        </View>
        <View
          style={{
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <TouchableOpacity
            style={styles.agreeTerms}
            onPress={() => {
              setAgreeTerms(!agreeTerms);
            }}>
            <Image
              source={
                agreeTerms
                  ? images.RADIO_CHECK_CHECKED
                  : images.RADIO_CHECK_EMPTY
              }
              style={styles.agreeTermsCheck}
            />
          </TouchableOpacity>
          <Text style={styles.policyText}>请阅读并同意</Text>
          <TouchableOpacity onPress={() => navigation.navigate('enter')}>
            <Text style={styles.policyLink}>《商家入驻协议》</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity style={styles.submitBtn} onPress={handleSubmit}>
          <Text style={styles.submitBtnText}>提 交</Text>
        </TouchableOpacity>
        <View style={styles.bottomSection}>
          <Image
            source={{ uri: kf.itemValue }}
            style={[
              styles.qrcodeImage,
              {
                width: 100,
                height: 100,
              },
            ]}
          />
          <View style={{ marginLeft: 20 }}>
            <View style={styles.wechatDescSection}>
              <Text style={styles.wechatDesc}>有任何问题可咨询客服哦~~</Text>
            </View>
            <View style={styles.wechatBusinessNumberSection}>
              <Text style={styles.businessNumberDesc}>平台客服微信：</Text>
              <Text style={styles.businessNumberVal}>{wx.name}</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  sendText: {
    height: 45,
    color: colors.WHITE,
    fontSize: 14,
    marginTop: 14,
  },
  agreeTerms: {
    flexDirection: 'row',
    marginRight: 6,
  },
  sendBtn: {
    height: 45,
    width: 100,
    backgroundColor: colors.RED_00,
    position: 'absolute',
    right: 0,
    alignItems: 'center',
  },
  mainContainer: {
    flex: 1,
  },
  inputForm: { marginTop: 30, marginHorizontal: 25 },
  inputItem: {
    flexDirection: 'row',
    backgroundColor: colors.WHITE,
    marginBottom: 10,
    height: 45,
    paddingLeft: 15,
    alignItems: 'center',
  },
  inputLabel: { color: colors.TUNDORA, fontSize: 14 },
  inputBox: {
    position: 'absolute',
    left: 87,
    fontSize: 14,
    color: colors.GRAY,
    width: 200,
  },
  submitBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 7.5,
    marginTop: 97.5,
  },
  submitBtnText: { color: colors.WHITE, fontSize: 18 },
  disabledModal: {
    height: 200,
  },
  confirmModalMainBody: {
    flex: 0,
    backgroundColor: colors.WHITE,
    height: 240,
    width: 290,
    alignSelf: 'center',
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  agreeTermsCheck: {
    width: 18,
    height: 18,
  },
  isDisabledModalTitle: {
    color: colors.TUNDORA,
    fontSize: 20,
    alignSelf: 'center',
    fontWeight: 'bold',
  },

  isDisabledModalText: {
    color: colors.TUNDORA,
    fontSize: 16,
    alignSelf: 'center',
  },
  isDisabledModalBtn: {
    width: 160,
    height: 47,
    alignSelf: 'center',
    borderRadius: 7.5,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
    marginBottom: 70,
  },
  isDisabledModalBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
  },
  policyText: {
    color: colors.GRAY,
  },
  policyLink: {
    color: '#06A8FF',
  },
  bottomSection: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 40,
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
  qrcodeImage: {
    marginTop: 30,
  },
});

export default MerchantApply;
