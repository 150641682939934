import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Modal from '@ant-design/react-native/lib/modal';

import { colors, images } from '../utils';

const MCustomerSupportModal = (props) => {
  const {
    visible = true,
    onClose = () => {},
    customerQr = null,
    desc = '',
    wechatNumber = '',
  } = props;

  const [width, setWidth] = useState(0);

  return (
    <Modal
      visible={visible}
      maskClosable
      transparent
      onClose={onClose}
      style={styles.container}>
      <TouchableOpacity onPress={onClose} style={styles.modalCloseBtn}>
        <Image source={images.CLOSE_ICON} style={styles.modalCloseBtnImage} />
      </TouchableOpacity>
      {/* <ViewShot ref={viewShotRef} options={{ format: 'jpg', quality: 1 }}> */}
      <View
        onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
        style={styles.viewShotContainer}>
        <Image
          source={{ uri: customerQr }}
          style={{
            width: width - 20,
            height: width - 20,
          }}
        />
        <View style={styles.wechatDescSection}>
          <Text style={styles.wechatDesc}>请用微信扫描二维码</Text>
        </View>
        <View style={styles.wechatBusinessNumberSection}>
          <Text style={styles.businessNumberDesc}>{desc}：</Text>
          <Text style={styles.businessNumberVal}>{wechatNumber}</Text>
        </View>
      </View>
      {/* </ViewShot> */}
      {/* <TouchableOpacity onPress={downloadQRCode}>
        <Image source={images.DOWNLOAD_ICON} style={styles.downloadIconImage} />
      </TouchableOpacity> */}
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  modalContainer: {
    backgroundColor: '#888888',
    zIndex: 1999999,
    borderWidth: 1,
    borderColor: 'red',
  },
  modalCloseBtn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 24,
  },
  modalCloseBtnImage: { width: 30, height: 30 },
  wechatImage: { width: 240, height: 240 },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
  downloadIcon: { position: 'absolute', bottom: -75 },
  downloadIconImage: {
    width: 45,
    height: 45,
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 24,
  },
  viewShotContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 20,
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default MCustomerSupportModal;
