/*
 * @Author: lizhengkai
 * @Date: 2022-04-14 14:25:48
 * @FilePath: /msll-client/src/screens/Client/MyPage/CardNumber.tsx
 */
/*
 * @Author: Eave Luo
 * @Date: 2021-08-02 10:37:05
 * @LastEditors: Evan huang
 * @LastEditTime: 2023-01-17 15:41:49
 * @Description: file content
 */
import React from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';

import { colors } from '../../../utils';
import Steps from '../../../components/Steps';

const CardNumber = ({ route }) => {
  const { cardId } = route.params;
  return (
    <SafeAreaView style={styles.container}>
      <Steps currentIndex={1} />
      <View style={styles.mainContainer}>
        <View style={styles.box}>
          <Text style={styles.text}>
            {cardId.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')}
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.DEFAULT_BG_COLOR,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  box: {
    backgroundColor: '#FF4D4F',
    height: 80,
    marginTop: 10,
    borderRadius: 5,
    margin: 10,
  },
  text: {
    margin: 20,
    fontSize: 18,
    color: colors.WHITE,
  },
});

export default CardNumber;
