/*
 * @Author: lizhengkai
 * @Date: 2022-04-13 17:38:52
 * @FilePath: /msll-client/src/screens/Client/MyPage/Rule.tsx
 */
import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, Text } from 'react-native';
import HTML from 'react-native-render-html';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../utils';

export default function Rule() {
  const dispatch = useDispatch();
  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { yhtghdgz: '' };
  useEffect(() => {
    dispatch({
      type: 'agreement/getByCode',
      payload: { code: 'yhtghdgz' },
    });
  }, [dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contentContainer}>
        {agreementsByCode.yhtghdgz?.content !== '' && (
          <HTML source={{ html: agreementsByCode.yhtghdgz?.content ?? '' }} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
