import React, { useCallback, useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TextInput,
  Image,
  SafeAreaView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Flex from '@ant-design/react-native/lib/flex';
import Modal from '@ant-design/react-native/lib/modal';
import Toast from '@ant-design/react-native/lib/toast';

import { colors, images } from '../../../../utils';
import BackgroundView from '../../../../components/MBackgroundView';

import PaymentButton from './PaymentButton';

const Withdrawal = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const withdrawalData = useSelector(
    (state: any) =>
      state.client.withdrawalData ?? {
        processingBean: 0,
        todayWithdrawAmount: 0,
      },
  );

  const defaultWithdrawRatio = useSelector((state: any) =>
    state.parameter.parametersByCode.default_user_withdraw_ratio &&
    state.parameter.parametersByCode.default_user_withdraw_ratio
      ? parseFloat(
          state.parameter.parametersByCode.default_user_withdraw_ratio
            .itemValue,
        )
      : 0.95,
  );

  const withdrawRuleDesc = useSelector((state: any) =>
    state.parameter.parametersByCode.default_user_withdraw_rule &&
    state.parameter.parametersByCode.default_user_withdraw_rule
      ? state.parameter.parametersByCode.default_user_withdraw_rule.itemValue
      : '提现统一处理时间为每日09:00~11:00，除此之外的时间也会零星处理一些提现申请。',
  );

  const withdrawThreshold = useSelector((state: any) =>
    state.parameter.parametersByCode.withdraw_threshold &&
    state.parameter.parametersByCode.withdraw_threshold
      ? parseFloat(
          state.parameter.parametersByCode.withdraw_threshold.itemValue,
        )
      : 5000,
  );
  // 微信提现通道是否开启
  const isOpenClientWechatCash = useSelector((state: any) =>
    state.parameter.parametersByCode.is_open_client_wechat_cash &&
    state.parameter.parametersByCode.is_open_client_wechat_cash
      ? state.parameter.parametersByCode.is_open_client_wechat_cash.itemValue
      : '0',
  );
  // 支付宝提现通道是否开启
  const isOpenClientAlipayCash = useSelector((state: any) =>
    state.parameter.parametersByCode.is_open_client_alipay_cash &&
    state.parameter.parametersByCode.is_open_client_alipay_cash
      ? state.parameter.parametersByCode.is_open_client_alipay_cash.itemValue
      : '0',
  );

  // 支付方式选择
  const [payment, setPayment] = useState(
    isOpenClientAlipayCash === '0'
      ? 'alipay'
      : isOpenClientWechatCash === '0'
      ? 'wechat'
      : '',
  );
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isOverallModal, setIsOverallModal] = useState(false);
  const [isPayErrorAlert, setIsPayErrorAlert] = useState(false);
  const [withdrawMoney, setWithdrawMoney] = useState('0');
  const applyWithdrawStatus = useSelector(
    (state: any) => state.client.applyWithdrawStatus,
  );

  const handleModal = useCallback(() => {
    if (withdrawMoney.trim() === '') {
      Toast.info('请输入提现金额', 1);
    } else if (parseInt(withdrawMoney, 10) < 1) {
      Toast.info('提现金额需大于0', 1);
    } else if (parseInt(withdrawMoney, 10) > (currentClient.balance ?? 0)) {
      Toast.info('已超出可提现数量', 1);
    } else if (payment === 'wechat' && !currentClient.openid) {
      Toast.info('请先绑定微信', 1);
      navigation.navigate('RealnameVerify');
    } else if (payment === 'alipay' && isOpenClientAlipayCash === '1') {
      setIsPayErrorAlert(true);
      // Toast.info('请先绑定支付宝账号', 1);
      // navigation.navigate('RealnameVerify');
    } else if (payment === 'wechat' && isOpenClientWechatCash === '1') {
      setIsPayErrorAlert(true);
    } else if (payment === '') {
      setIsPayErrorAlert(true);
    } else {
      setIsConfirmModal(true);
    }
  }, [
    currentClient.balance,
    currentClient.openid,
    navigation,
    payment,
    withdrawMoney,
    isOpenClientWechatCash,
    isOpenClientAlipayCash,
  ]);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getWithdrawalData',
        payload: {},
      });
      dispatch({
        type: 'parameter/getByCode',
        payload: {
          code: 'default_user_withdraw_ratio',
        },
      });
      dispatch({
        type: 'parameter/getByCode',
        payload: {
          code: 'default_user_withdraw_rule',
        },
      });
      dispatch({
        type: 'parameter/getByCode',
        payload: {
          code: 'withdraw_threshold',
        },
      });
      dispatch({
        type: 'parameter/getByCode',
        payload: {
          code: 'is_open_client_wechat_cash',
        },
      });
      dispatch({
        type: 'parameter/getByCode',
        payload: {
          code: 'is_open_client_alipay_cash',
        },
      });
    }, [dispatch]),
  );

  const handleConfirm = useCallback(() => {
    console.log(withdrawalData.todayWithdrawAmount);
    const withdrawPrice =
      (parseInt(withdrawMoney, 10) ?? 0) * defaultWithdrawRatio;
    const todayWithdrawAmount = withdrawalData.todayWithdrawAmount ?? 0;
    if (
      payment === 'wechat' &&
      withdrawPrice + todayWithdrawAmount > withdrawThreshold
    ) {
      setIsOverallModal(true);
      return;
    }
    dispatch({
      type: 'client/userApplyWithdrawal',
      payload: {
        bean: withdrawMoney,
        method: payment === 'alipay' ? 1 : 0,
        // 数据来源于APP还是公众号：0:APP，1:公众号
        dataSource: 0,
        withdrawRatio: defaultWithdrawRatio.toString(),
      },
    });
  }, [
    defaultWithdrawRatio,
    dispatch,
    withdrawalData.todayWithdrawAmount,
    payment,
    withdrawMoney,
    withdrawThreshold,
  ]);

  useEffect(() => {
    if (applyWithdrawStatus === 1) {
      setIsConfirmModal(false);
      navigation.navigate('Success', { from: 'user_withdraw' });
      dispatch({
        type: 'client/userApplyWithdrawal',
        payload: {
          bean: 0,
        },
      });
    }
  }, [applyWithdrawStatus, navigation, dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.mainContainer}>
        <BackgroundView style={styles.header} image="PERSONAL_CENTER_WALLET_BG">
          <View style={styles.headerWrapper}>
            <Text style={styles.headerText}>可提现金额</Text>
            <View style={styles.headerTextWrapper}>
              <Text style={styles.headerFigure}>
                {currentClient.balance.toFixed(2)}
              </Text>
              <Text style={styles.headerText}>
                {withdrawalData.processingBean !== null &&
                  withdrawalData.processingBean > 0 &&
                  `（申请中 ${withdrawalData.processingBean.toFixed(2)}）`}
              </Text>
            </View>
          </View>
        </BackgroundView>
        <View style={styles.body}>
          <Text style={styles.bodyTitle}>提现方式</Text>
          <Flex justify="between" style={styles.paymentContainer}>
            <PaymentButton
              type="alipay"
              selected={payment === 'alipay'}
              onPress={() => setPayment('alipay')}
            />
            <PaymentButton
              type="wechat"
              selected={payment === 'wechat'}
              onPress={() => setPayment('wechat')}
            />
          </Flex>
          <Text style={styles.bodyTitle}>提现金额</Text>
          <Flex justify="between">
            <TextInput
              returnKeyType="done"
              keyboardType="decimal-pad"
              value={withdrawMoney}
              onChangeText={setWithdrawMoney}
              onBlur={() =>
                isNaN(parseInt(withdrawMoney, 10))
                  ? setWithdrawMoney('')
                  : setWithdrawMoney(parseInt(withdrawMoney, 10).toString())
              }
              style={styles.bodyFigure}
            />
            <TouchableOpacity
              onPress={() =>
                setWithdrawMoney(Math.floor(currentClient.balance).toString())
              }>
              <Text style={styles.bodyText}>全部提现</Text>
            </TouchableOpacity>
          </Flex>
          <View style={styles.bodyFooter}>
            <TouchableOpacity onPress={handleModal}>
              <View style={styles.button}>
                <Text style={styles.buttonText}>提{'   '}现</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerTitle}>提现规则</Text>
          {/* <Text style={styles.footerText}>1、1元金额可兑换1元；</Text> */}
          <Text style={styles.footerText}>
            1、提现费率
            {((1 - defaultWithdrawRatio) * 100).toFixed(0)}
            %；
          </Text>
          <Text style={styles.footerText}>2、{withdrawRuleDesc}</Text>
          <Text style={styles.footerText}>
            3、微信提现暂时只支持单日{withdrawThreshold.toFixed(2)}
            元的提现限额，单笔提现金额最低不少于1元。
          </Text>
        </View>
      </ScrollView>
      <ConfirmModal
        visible={isConfirmModal}
        withdraw={withdrawMoney}
        withdrawRatio={defaultWithdrawRatio}
        onClose={() => setIsConfirmModal(false)}
        onConfirm={handleConfirm}
      />
      <PayErrorAlert
        visible={isPayErrorAlert}
        onClose={() => setIsPayErrorAlert(false)}
        onConfirm={() => setIsPayErrorAlert(false)}
        payment={payment}
      />
      <OverallModal
        visible={isOverallModal}
        withdrawThreshold={withdrawThreshold}
        todayWithdrawAmount={withdrawalData.todayWithdrawAmount ?? 0}
        onClose={() => setIsOverallModal(false)}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    padding: 16,
  },
  header: {
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 35,
  },
  headerWrapper: {
    marginVertical: 30,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: 12,
  },
  headerTextWrapper: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  headerFigure: {
    fontSize: 24,
    color: '#ffffff',
    marginBottom: 10,
  },
  body: {
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    paddingHorizontal: 25,
    paddingVertical: 16,
  },
  bodyTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#444444',
    marginBottom: 16,
  },
  bodyFigure: {
    fontSize: 32,
    fontWeight: 'bold',
    minWidth: 150,
  },
  bodyText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  paymentContainer: {
    marginBottom: 25,
  },
  bodyFooter: {
    borderTopWidth: 1,
    borderTopColor: '#e5e5e5',
    marginTop: 11,
    paddingTop: 27,
  },
  button: {
    backgroundColor: '#D90000',
    alignSelf: 'center',
    width: 160,
    height: 47,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 30,
  },
  footerTitle: {
    color: '#444444',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  footerText: {
    color: '#444444',
    fontSize: 13,
  },
  // confirmModal: {
  //   flex: 1,
  //   width: '100%',
  //   top: (DEVICE_HEIGHT - 277) / 2,
  // },
  confirmModalMainBody: {
    flex: 0,
    backgroundColor: colors.WHITE,
    height: 240,
    width: 290,
    alignSelf: 'center',
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  confirmModalTitle: {
    color: colors.TUNDORA,
    fontSize: 17,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  confirmBtn: {
    width: 160,
    height: 47,
    alignSelf: 'center',
    borderRadius: 7.5,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
  },
  confirmBtnText: { color: colors.WHITE, fontSize: 18, alignSelf: 'center' },
  confirmConvertRatioDescText: {
    color: colors.GRAY,
    fontSize: 12,
    alignSelf: 'center',
  },
  confirmCNYText: { color: colors.TUNDORA, fontSize: 40, alignSelf: 'center' },
  confirmBeansText: {
    color: colors.TUNDORA,
    fontSize: 30,
    alignSelf: 'center',
  },
  confirmCloseBtn: { position: 'absolute', top: 0, left: 16 },
  closeIconImage: { width: 15, height: 15 },
  aliPayAlertText: {
    color: colors.TUNDORA,
    fontSize: 16,
    alignSelf: 'center',
    lineHeight: 25,
  },
  aliPayAlertTitle: {
    color: colors.TUNDORA,
    fontSize: 20,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  aliPayAlertBtn: {
    width: 160,
    height: 47,
    alignSelf: 'center',
    borderRadius: 7.5,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
    marginBottom: 70,
  },
  alypayAlertModal: {
    height: 200,
  },
});

const ConfirmModal = (props) => {
  const {
    visible = false,
    withdraw = '0',
    withdrawRatio = 1,
    onConfirm = () => {},
    onClose = () => {},
  } = props;

  return !visible ? null : (
    <Modal visible={visible} onClose={onClose} transparent maskClosable>
      <View style={styles.confirmModalMainBody}>
        <TouchableOpacity style={styles.confirmCloseBtn} onPress={onClose}>
          <Image source={images.X_CLOSE_ICON} style={styles.closeIconImage} />
        </TouchableOpacity>
        <Text style={styles.confirmModalTitle}>提现确认</Text>
        <Text style={styles.confirmBeansText}>{withdraw}金额</Text>
        <Text style={styles.confirmCNYText}>
          ¥{(parseInt(withdraw, 10) * withdrawRatio)?.toFixed(2) ?? '0.00'}
        </Text>
        <Text style={styles.confirmConvertRatioDescText}>
          1元金额可兑换1元，提现费率{((1 - withdrawRatio) * 100).toFixed(0)}%。
        </Text>
        <TouchableOpacity style={styles.confirmBtn} onPress={onConfirm}>
          <Text style={styles.confirmBtnText}>确{'  '}认</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const PayErrorAlert = (props) => {
  const {
    visible = false,
    payment,
    onConfirm = () => {},
    onClose = () => {},
  } = props;

  return !visible ? null : (
    <Modal
      style={styles.alypayAlertModal}
      visible={visible}
      onClose={onClose}
      transparent
      maskClosable>
      <View style={styles.confirmModalMainBody}>
        <Text style={styles.aliPayAlertTitle}>提示</Text>
        <Text style={styles.aliPayAlertText}>
          {payment === ''
            ? '请选择提现方式后再重试'
            : '该提现入口维护中，请用其他方式提现，任何问题请联系客服'}
        </Text>
        <TouchableOpacity style={styles.aliPayAlertBtn} onPress={onConfirm}>
          <Text style={styles.confirmBtnText}>确 定</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const OverallModal = (props) => {
  const {
    visible = false,
    withdrawThreshold = 0,
    todayWithdrawAmount = 0,
    onClose = () => {},
  } = props;

  return (
    <Modal visible={visible} transparent maskClosable onClose={onClose}>
      <View style={stylesAgreeModal.container}>
        <View>
          <Text style={stylesModal.title}>提示</Text>
          <Text style={stylesModal.description}>
            抱歉，微信提现暂时只支持单日{withdrawThreshold.toFixed(2)}
            元的提现限额，后期会提高至单日20000.00元提现限额，请修改金额后重新提交
          </Text>
          <Text
            style={[stylesModal.description, { color: colors.GUARDSMAN_RED }]}>
            今日已提现{todayWithdrawAmount.toFixed(2)}元
          </Text>
          <Text
            style={[stylesModal.description, { color: colors.GUARDSMAN_RED }]}>
            今日剩余额度{(withdrawThreshold - todayWithdrawAmount).toFixed(2)}元
          </Text>
        </View>
        <TouchableOpacity onPress={onClose} style={stylesModal.btn}>
          <Text style={stylesModal.btnText}>知道了</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const stylesAgreeModal = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    flex: 1,
    width: '100%',
  },
  container: {
    alignSelf: 'center',
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  title: {
    color: colors.BLACK,
    fontSize: 17,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 16,
  },
  description: { color: colors.TUNDORA, fontSize: 12, lineHeight: 20 },
  btn: {
    width: 160,
    height: 47,
    borderRadius: 7.5,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  btnText: { color: colors.WHITE, fontSize: 18 },
});

const stylesModal = StyleSheet.create({
  title: {
    color: colors.BLACK,
    fontSize: 17,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 16,
  },
  description: {
    color: colors.TUNDORA,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'justify',
  },
  btn: {
    width: 160,
    height: 47,
    marginTop: 20,
    borderRadius: 7.5,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  btnText: { color: colors.WHITE, fontSize: 18 },
});

export default Withdrawal;
