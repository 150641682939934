/*
 * @Description:
 * @Author: Evan huang
 * @Date: 2024-04-27 21:55:05
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-10 04:27:38
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/models/agreement.ts
 */
import { getAll, getByCode } from '../services/open/agreement';

const SET_AGREEMENTS = 'SET_AGREEMENTS';
const SET_AGREEMENTS_BY_CODE = 'SET_AGREEMENTS_BY_CODE';
const SET_KF = 'SET_KF';
const SET_WX = 'SET_WX';
const MZSM = 'MZSM';

export default {
  namespace: 'agreement',
  state: {
    agreements: [],
    agreementsByCode: {},
    kf: {},
    wx: {},
    mzsm: {},
  },
  effects: {
    *getAll({ payload }, { put, call }) {
      const response = yield call(getAll, payload);
      if (response.success) {
        yield put({
          type: SET_AGREEMENTS,
          payload: response.data,
        });
      }
    },
    *getByCode({ payload }, { put, call }) {
      const response = yield call(getByCode, payload);
      if (payload.code === 'platform_support_qrcode') {
        yield put({
          type: SET_KF,
          payload: response.data,
        });
      } else if (payload.code === 'platform_support_wechat') {
        yield put({
          type: SET_WX,
          payload: response.data,
        });
      } else if (payload.code === 'mzsm') {
        yield put({
          type: MZSM,
          payload: response.data,
        });
      } else {
        yield put({
          type: SET_AGREEMENTS_BY_CODE,
          payload: response.data,
        });
      }
    },
  },
  reducers: {
    SET_AGREEMENTS(state, { payload }) {
      state.agreements = payload;
    },
    SET_AGREEMENTS_BY_CODE(state, { payload }) {
      state.agreementsByCode[payload.code] = payload;
    },
    MZSM(state, { payload }) {
      state.mzsm = payload;
    },
  },
};
