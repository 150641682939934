import React, { useCallback, useState, useEffect } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '@ant-design/react-native/lib/toast';
import { sendCode } from '../../../services/client/client';
import { colors } from '../../../utils';

const AlipayAccountClientBind = () => {
  const dispatch = useDispatch();

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const [alipayName, setAlipayName] = useState('');
  const [alipayAccount, setAlipayAccount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(currentClient.phoneNumber);
  const [verifyCode, setVerifyCode] = useState('');
  const [interval, setIntervalState] = useState<NodeJS.Timeout | null>(null);
  const [countdown, setCountdown] = useState<any>('获取验证码');

  const opacity = countdown === '获取验证码' ? 1 : 0.6;

  const onGetVerifyCode = useCallback(async () => {
    //防止重复点击
    if (countdown !== '获取验证码') {
      return;
    }

    if (!phoneNumber.trim()) {
      Toast.info('请输入手机号', 1);
      return;
    }

    const response = await sendCode({ phone: phoneNumber });
    if (response.success) {
      let key = setInterval(() => {
        setCountdown((state: any) => {
          if (parseInt(state, 10) < 1) {
            clearInterval(key);
            setIntervalState(null);
          }
          let currentTime =
            state === '获取验证码'
              ? '60'
              : parseInt(state, 10) < 1
              ? '获取验证码'
              : parseInt(state, 10) - 1;
          return currentTime;
        });
      }, 1000);
      setIntervalState(key);
    } else {
      Toast.info(response.msg, 1);
    }
  }, [countdown, phoneNumber]);

  const handleAlipayAccount = useCallback(() => {
    if (alipayName.trim() === '') {
      Toast.info('请输入姓名', 1);
    } else if (alipayAccount.trim() === '') {
      Toast.info('请输入支付宝账号', 1);
    } else if (phoneNumber.trim() === '') {
      Toast.info('请输入手机号', 1);
    } else if (verifyCode.trim() === '') {
      Toast.info('请输入验证码', 1);
    } else {
      dispatch({
        type: 'client/verifyAlipay',
        payload: {
          name: alipayName,
          account: alipayAccount,
          phone: phoneNumber,
          code: verifyCode,
        },
      });
    }
  }, [alipayAccount, alipayName, phoneNumber, verifyCode, dispatch]);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
        clearTimeout(interval);
      }
    };
  }, [interval]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.mainContainer}>
        <View style={styles.inputForm}>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>姓名</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入姓名"
              value={alipayName}
              onChangeText={setAlipayName}
              style={[styles.inputDefault01, styles.textInput]}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>支付宝账号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入支付宝账号"
              value={alipayAccount}
              onChangeText={setAlipayAccount}
              style={[styles.inputDefault01, styles.textInput]}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>手机号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入手机号"
              value={phoneNumber}
              onChangeText={(value) => {
                setPhoneNumber(value);
              }}
              style={[styles.inputDefault01, styles.textInput]}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>验证码</Text>
            <TextInput
              returnKeyType="done"
              placeholder="输入验证码"
              value={verifyCode}
              keyboardType="decimal-pad"
              onChangeText={(value) => {
                setVerifyCode(value);
              }}
              style={styles.inputDefault01}
            />
            <TouchableOpacity
              disabled={countdown !== '获取验证码'}
              onPress={onGetVerifyCode}
              style={[styles.getVerifyBtn, { opacity }]}>
              <Text style={styles.getVerifyBtnText}>{countdown}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.saveBtn}
          onPress={handleAlipayAccount}>
          <Text style={styles.saveBtnText}>保 存</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 25,
    backgroundColor: colors.DEFAULT_BG_COLOR,
  },
  inputForm: { marginTop: 30 },
  inputItem: {
    backgroundColor: colors.WHITE,
    height: 45,
    marginBottom: 10,
  },
  inputLabel: {
    color: colors.TUNDORA,
    fontSize: 14,
    paddingTop: 15.5,
    marginLeft: 15,
  },
  inputDefault01: {
    color: colors.GRAY,
    fontSize: 14,
    position: 'absolute',
    paddingTop: 14,
    left: 95,
    padding: 0,
    width: 200,
  },
  textInput: {},
  getVerifyBtn: {
    position: 'absolute',
    right: 0,
    backgroundColor: '#D90000',
    height: '100%',
    width: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
  getVerifyBtnText: {
    color: colors.WHITE,
    fontSize: 14,
  },
  saveBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    borderRadius: 7.5,
    alignSelf: 'center',
    marginTop: 27,
  },
  saveBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
    paddingVertical: 14.5,
  },
});

export default AlipayAccountClientBind;
