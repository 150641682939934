/*
 * @Author: lizhengkai
 * @Date: 2022-04-13 18:11:08
 * @FilePath: /msll-client/src/screens/Client/MyPage/BankCard.tsx
 */
import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { useDispatch } from 'react-redux';
import Toast from '@ant-design/react-native/lib/toast';
import { colors } from '../../../utils';
import Steps from '../../../components/Steps';

const BankCard = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [alipayName, setAlipayName] = useState('');
  const [alipayAccount, setAlipayAccount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleAlipayAccount = useCallback(() => {
    if (alipayName.trim() === '') {
      Toast.info('请输入姓名', 1);
    } else if (alipayAccount.trim() === '') {
      Toast.info('请输入卡号', 1);
    } else if (phoneNumber.trim() === '') {
      Toast.info('请输入手机号', 1);
    } else {
      dispatch({
        type: 'client/userBindBank',
        payload: {
          cardNo: alipayAccount,
          name: alipayName,
          phone: phoneNumber,
        },
      });
    }
  }, [alipayName, alipayAccount, phoneNumber, dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <Steps currentIndex={1} />
      <View style={styles.mainContainer}>
        <View style={styles.inputForm}>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>持卡人</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入姓名"
              value={alipayName}
              onChangeText={setAlipayName}
              style={styles.inputDefault01}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>卡号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入持卡人本人银行卡号"
              value={alipayAccount}
              onChangeText={setAlipayAccount}
              style={styles.inputDefault01}
            />
          </View>
          <View style={styles.inputItem}>
            <Text style={styles.inputLabel}>手机号</Text>
            <TextInput
              returnKeyType="done"
              placeholder="请输入手机号"
              value={phoneNumber}
              onChangeText={(value) => {
                setPhoneNumber(value);
              }}
              style={styles.inputDefault01}
            />
          </View>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Agreement')}>
          <View style={styles.service}>
            <Text style={styles.serviceText}>查看</Text>
            <Text style={styles.serviceTextRight}>《服务协议》</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.saveBtn}
          onPress={handleAlipayAccount}>
          <Text style={styles.saveBtnText}>提交卡号</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 25,
    backgroundColor: colors.DEFAULT_BG_COLOR,
  },
  inputForm: { marginTop: 30 },
  inputItem: {
    backgroundColor: colors.WHITE,
    height: 45,
    marginBottom: 10,
  },
  inputLabel: {
    position: 'absolute',
    color: colors.TUNDORA,
    fontSize: 14,
    left: 17,
    top: 17,
  },
  inputDefault01: {
    color: colors.GRAY,
    fontSize: 14,
    paddingTop: 15.5,
    paddingLeft: 87,
  },
  // getVerifyBtn: {
  //   position: 'absolute',
  //   right: 0,
  //   backgroundColor: colors.GUARDSMAN_RED,
  //   width: 100,
  //   height: 45,
  //   paddingVertical: 15.5,
  //   paddingLeft: 10,
  //   paddingRight: 10,
  // },
  // getVerifyBtnText: {
  //   color: colors.WHITE,
  //   textAlign: 'center',
  //   fontSize: 14,
  // },
  saveBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 27,
  },
  saveBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    paddingVertical: 0,
  },
  service: {
    flexDirection: 'row',
  },
  serviceText: {
    fontSize: 12,
    color: '#888888',
  },
  serviceTextRight: {
    fontSize: 12,
    color: colors.GUARDSMAN_RED,
  },
});

export default BankCard;
