/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import React, { useCallback } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import { colors, images } from '../utils';
import { useDispatch, useSelector } from 'react-redux';

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const { type = 0 } = route.params as any;

  const { id } = useSelector(
    (state: any) => state.clientMerchant.currentArticle,
  );
  dispatch({
    type: 'client/getCurrentClient',
  });

  const onComplete = useCallback(() => {
    //支付完成
    navigation.navigate('OrderDetail', { articleId: id, from: 'successPay' });
  }, [id, navigation]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.contentSection}>
        <Image
          source={images.MERCHANT_APPLY_SUCCESS}
          style={styles.successImage}
        />
        <Text style={styles.contentTitle}>
          {type === 1 ? '充值成功' : '支付成功'}
        </Text>
      </View>
      <TouchableOpacity style={styles.submitBtn} onPress={onComplete}>
        <Text style={styles.submitBtnText}>完 成</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  contentSection: { marginTop: 62, alignItems: 'center' },
  contentTitle: {
    color: colors.TUNDORA,
    fontSize: 18,
    marginTop: 2,
    fontWeight: '700',
  },
  successImage: { width: 150, height: 145 },
  submitBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 7.5,
    marginTop: 61.5,
  },
  submitBtnText: { color: colors.WHITE, fontSize: 18 },
});

export default PaymentSuccess;
