import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { colors } from '../utils';

const MAllLoaded = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <Text style={styles.text}>数据已全部加载</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 40,
    justifyContent: 'center',
  },
  text: {
    color: colors.DUSTY_GRAY,
    fontSize: 13,
  },
});

export default MAllLoaded;
