/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */

import Flex from '@ant-design/react-native/lib/flex';
import React, { useState } from 'react';
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { colors, images } from '../utils';
import SuggestButton from '../components/SuggestButton';

const MerchantHeader = (props) => {
  const {
    merchantInfo,
    onHomeClick,
    isSmallSize = false,
    isHome = false,
  } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <View style={isSmallSize ? undefined : styles.headingSection}>
      <View
        style={isSmallSize ? styles.noticeSectionSmall : styles.noticeSection}>
        <Image
          source={
            merchantInfo?.avatar ? { uri: merchantInfo?.avatar } : images.LOGO
          }
          style={isSmallSize ? styles.noticeProfileSmall : styles.noticeProfile}
        />
        <View style={styles.textSection}>
          <View style={styles.merchantNameSection}>
            <Text style={styles.textSectionHeader}>{merchantInfo.name}</Text>
            {merchantInfo.privilege && merchantInfo.privilege?.id && (
              <View
                style={[
                  styles.merchantTagContainer,
                  !merchantInfo.privilege &&
                    !merchantInfo.privilege?.id && {
                      borderColor: '#7D828E',
                    },
                ]}>
                <Image
                  source={
                    merchantInfo.privilege && merchantInfo.privilege?.id
                      ? { uri: merchantInfo.privilege?.tagImage }
                      : images.PRIVILEGE_DEFAULT_TAG
                  }
                  style={{ width: 20, height: 20 }}
                />
                <Text style={[styles.merchantTagText, { color: '#7D828E' }]}>
                  {merchantInfo.privilege && merchantInfo.privilege?.id
                    ? merchantInfo.privilege?.privilegeName
                    : '未认证'}
                </Text>
              </View>
            )}
          </View>
          {isSmallSize ? null : (
            <TouchableOpacity onPress={() => setExpanded(!expanded)}>
              <View>
                <Text
                  style={styles.textSectionDesc}
                  numberOfLines={expanded ? undefined : 4}>
                  {merchantInfo?.intro}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        </View>
        {!isHome && (
          <Flex justify="around" direction="row">
            <Pressable style={styles.button} onPress={onHomeClick}>
              <Text style={styles.textSuggest}>作者主页</Text>
            </Pressable>
            <SuggestButton isDetail={isSmallSize} />
          </Flex>
        )}
      </View>
    </View>
  );
};

export default MerchantHeader;

const styles = StyleSheet.create({
  headingSection: { backgroundColor: colors.GUARDSMAN_RED },
  noticeSection: {
    backgroundColor: colors.WHITE,
    borderRadius: 8,
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 32,
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingTop: 10,
    paddingBottom: 20,
  },
  noticeSectionSmall: {
    backgroundColor: colors.WHITE,
    borderRadius: 4,
    marginHorizontal: 8,
    marginTop: 6,
    marginBottom: 6,
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  noticeProfile: {
    width: 60,
    height: 60,
    marginRight: 12,
    borderRadius: 100,
  },
  noticeProfileSmall: {
    width: 30,
    height: 30,
    marginRight: 6,
    borderRadius: 50,
  },
  textSection: {
    flex: 1,
    justifyContent: 'center',
  },
  merchantNameSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  merchantTagContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'rgba(217,0,0,0.2)',
    borderRadius: 20,
    alignSelf: 'flex-start',
    marginLeft: 8,
  },
  merchantTagText: {
    fontSize: 12,
    color: 'rgba(217,0,0,0.4)',
    marginLeft: 4,
    marginRight: 8,
  },
  textSectionHeader: { color: colors.GRAY20, fontSize: 16, marginBottom: 5 },
  textSectionDesc: {
    color: '#0091FF',
    fontSize: 13,
    lineHeight: 18.5,
    textAlign: 'justify',
  },
  textSuggest: {
    color: '#D90000',
    fontSize: 12,
    lineHeight: 18.5,
    textAlign: 'justify',
  },
  button: {
    marginHorizontal: 5,
    borderRadius: 15,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: '#D90000',
    backgroundColor: 'transparent',
  },
  buttonText: {
    color: colors.DUSTY_GRAY,
    fontSize: 13,
  },
});
