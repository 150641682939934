/* eslint-disable react-native/no-inline-styles */
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  Image,
  SafeAreaView,
  StyleSheet,
  View,
  TextInput,
  FlatList,
  TouchableOpacity,
  Text,
} from 'react-native';
import { colors, images } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { MAllLoadedBox, MEmptyBox } from '../components';
import { encode as base64_encode } from 'base-64';
const Meal = ({ route: { params } }) => {
  const dispatch = useDispatch();

  const navigation = useNavigation();
  const [searchInput, setSearchInput] = useState('');
  const [SearchWord, setSearchWord] = useState('');
  const [keyword, setKeyword] = useState('');

  // params.type === 2 '用户端' , 1 '商户端'、3 '商家端预览文章'
  const { id: merchantId, name: merchantName } = useSelector((state: any) =>
    params.type === 2
      ? state.clientMerchant.currentMerchant
      : state.merchantMyInfo.myInfo,
  );
  const getPlanPage = useSelector((state: any) => state.client.getPlanPage);

  const articleId = params.articleId || '';

  useEffect(() => {
    const payload =
      params.type === 1
        ? {
            merchantId,
            currentPage: 1,
            pageSize: 10,
          }
        : {
            articleId,
            type: params.type,
          };
    dispatch({
      type: 'client/getPlanPage',
      payload,
    });
  }, [articleId, dispatch, merchantId, params.type]);

  const fetchData = useCallback(
    async (page) => {
      const payload =
        params.type === 1
          ? { merchantId, currentPage: page, pageSize: 10 }
          : { articleId, type: params.type };

      await dispatch({ type: 'client/getPlanPage', payload });
    },
    [articleId, dispatch, merchantId, params.type],
  );

  const selectByID = async (item) => {
    const { id, title } = item;
    navigation.goBack();
    params.callBack({ id, name: title });
  };

  const gotoPlanDetail = (id) => {
    navigation.navigate(
      params.type === 2 ? 'OrderDetail1' : 'ArticlePreview1',
      {
        articleId: base64_encode(id),
      },
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <TouchableOpacity
          {...props}
          onPress={() => {
            navigation.goBack();
          }}>
          <Image source={images.BUCK} style={{ marginLeft: 16 }} />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View style={styles.btnlogin}>
            <Text style={styles.btntext}>料</Text>
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{item.title}</Text>
          </View>
        </View>
        <View>
          <TouchableOpacity
            onPress={() => {
              params.type === 1 ? selectByID(item) : gotoPlanDetail(item.id);
            }}>
            <View
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundColor: colors.GUARDSMAN_RED,
                borderRadius: 4,
              }}>
              <Text style={{ color: 'white' }}>
                {params.type === 1 ? '选择' : '查看套餐'}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );

  return (
    <SafeAreaView style={{ flex: 1, padding: 16 }}>
      {params.type === 1 && (
        <View style={styles.search}>
          <TextInput
            returnKeyType="search"
            placeholder="请输入搜索套餐"
            value={searchInput}
            onChangeText={(val) => {
              setSearchInput(val);
              if (val === '') {
                setSearchWord('');
                fetchData(1);
              }
            }}
            onSubmitEditing={() => {
              dispatch({
                type: 'client/getPlanPage',
                payload: {
                  merchantId,
                  currentPage: 1,
                  pageSize: 10,
                  name: searchInput,
                },
              });
            }}
            style={styles.inputStyle}
          />
          <TouchableOpacity
            style={styles.btnStyle}
            onPress={() => {
              dispatch({
                type: 'client/getPlanPage',
                payload: {
                  merchantId,
                  currentPage: 1,
                  pageSize: 10,
                  name: searchInput,
                },
              });
            }}>
            <Text style={styles.searchText}>搜索</Text>
          </TouchableOpacity>
        </View>
      )}
      <FlatList
        data={getPlanPage.records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        refreshing={false}
        onRefresh={() => fetchData(1)}
        onEndReached={() => {
          if (!getPlanPage.isAllLoaded) {
            fetchData(getPlanPage.page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无数据" />}
        ListFooterComponent={
          getPlanPage.records.length && getPlanPage.isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  search: {
    width: '100%',
    height: 32,
    marginTop: 8,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: 0,
    padding: 0,
  },
  inputStyle: {
    flex: 8,
    height: 32,
    fontSize: 16,
    borderColor: 'rgba(0,0,0,.2)',
    borderWidth: 1,
    paddingVertical: 0,
    padding: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginLeft: 10,
    paddingLeft: 10,
  },
  searchText: {
    fontSize: 16,
    color: colors.WHITE,
  },
  btnStyle: {
    flex: 2,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    backgroundColor: colors.RED_00,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  text: {
    color: colors.DUSTY_GRAY,
    fontSize: 13,
  },
  radioImage: {
    fontSize: 13,
  },
  searchInput: {
    color: colors.DUSTY_GRAY,
    height: 32,
    flexDirection: 'row', // 水平排布
    justifyContent: 'center',
    flex: 1,
    borderWidth: 1,
    borderRadius: 3, // 设置圆角边
    // backgroundColor: 'white',
    alignItems: 'center',
  },

  btnlogin: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    backgroundColor: colors.GUARDSMAN_RED,
    borderRadius: 6,
  },
  btntext: {
    color: 'white',
    alignItems: 'center',
  },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 13,
    marginBottom: 4,
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
  searchImage: {
    width: 14,
    height: 14,
    zIndex: 1,
    alignSelf: 'center',
    marginLeft: 11.5,
  },
});

export default Meal;
