/* eslint-disable @typescript-eslint/no-unused-vars */
import Flex from '@ant-design/react-native/lib/flex';
import { FlexProps } from '@ant-design/react-native/lib/flex/Flex';
import React, {
  PropsWithChildren,
  PropsWithoutRef,
  useEffect,
  useState,
} from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

type ItemProps = PropsWithoutRef<{
  title?: string;
  selected?: boolean;
  onPress?: () => void;
}>;

const Item = ({ title, selected = false, onPress = () => {} }: ItemProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.itemContainer}>
        <Text style={styles.segmentTitle}>{title}</Text>
        {selected && <View style={styles.segmentBar} />}
      </View>
    </TouchableOpacity>
  );
};

type SegmentProps = PropsWithChildren<{
  onChange?: (index: number) => void;
}> &
  FlexProps;

const Segment = ({ children, onChange = () => {}, ...rest }: SegmentProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    onChange(selectedIndex);
  }, [onChange, selectedIndex]);

  return (
    <Flex justify="between" {...rest}>
      {React.Children.map(
        children as any,
        (child: React.ReactComponentElement<typeof Item>, index) => {
          if (!child || !(child as any).props) {
            return;
          }
          const selected = index === selectedIndex;
          const onPress = () => {
            setSelectedIndex(index);
            if (child.props.onPress) {
              child.props.onPress();
            }
          };
          return (
            <Flex.Item>
              {React.cloneElement(child, {
                ...child.props,
                selected: selected,
                onPress: onPress,
              })}
            </Flex.Item>
          );
        },
      )}
    </Flex>
  );
};

export default Segment;

Segment.Item = Item;

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  segmentBar: {
    position: 'absolute',
    bottom: 0,
    borderTopWidth: 3,
    borderTopColor: '#D90000',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 16,
  },
  segmentTitle: {
    fontSize: 13,
    paddingVertical: 14,
    color: '#444444',
  },
});
