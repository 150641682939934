/*
 * @Author: lizhengkai
 * @Date: 2022-04-06 17:41:30
 * @FilePath: /msll-client/src/screens/Client/MyPage/onRefund.tsx
 */
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback } from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../utils';
const { width: DEVICE_WIDTH } = Dimensions.get('window');

const OnRefund = () => {
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'OnRefund' },
      });
    }, [dispatch]),
  );
  const userBalance = useSelector(
    (state: any) => state.client.userBalance,
  );
  const launchRefund = () => {
    dispatch({
      type: 'client/userWalletRefund',
    });
  };
  /** 收益提现 */
  const currentClient = useSelector((state: any) => state.client.currentClient);
  return (
    <>
      <View style={styles.buyCreditSection}>
        <View style={styles.customLayout}>
          <Text style={styles.customLabel}>退款金额：</Text>
          <View style={styles.iconFlex}>
            <Text style={styles.walletCredit}>¥ {userBalance}</Text>
          </View>
        </View>
      </View>
      <View style={styles.explain}>
        <Text style={{ marginBottom: 5 }}>退款规则</Text>
        <Text style={styles.explainText}>1、退款仅支持全额退款；</Text>
        <Text style={styles.explainText}>
          2、退款需扣除{((1 - currentClient.refundRatio) * 100).toFixed(0)}
          %手续；
        </Text>
        <Text style={styles.explainText}>3、退款方式为原路退还。</Text>
      </View>
      <TouchableOpacity onPress={launchRefund} style={styles.refundBtn}>
        <Text style={styles.refundText}>退款</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  buyCreditSection: {
    backgroundColor: colors.WHITE,
    marginBottom: 8,
    height: 90,
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    padding: 16,
    borderRadius: 8,
    marginTop: 16,
  },
  customLayout: {
    flex: 1,
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 12,
  },
  customLabel: { color: '#444', fontSize: 16, fontWeight: 'bold' },
  iconFlex: {
    flexDirection: 'row',
  },
  walletCredit: { color: colors.BLACK, fontSize: 26, marginTop: 8 },
  explain: {
    padding: 16,
    width: '100%',
  },
  explainText: {
    color: '#444444',
    fontSize: 13,
    marginTop: 8,
  },
  refundBtn: {
    height: 50,
    backgroundColor: colors.RED_00,
    width: '80%',
    alignSelf: 'center',
    borderRadius: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  refundText: {
    color: colors.WHITE,
    fontSize: 18,
  },
});

export default OnRefund;
