/*
 * @Author: Qinghua Zeng
 * @Date: 2022-04-02 11:45:33
 * @Description:
 */
import React, {
  useLayoutEffect,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ImageBackground,
  Dimensions,
  TextInput,
  ScrollView,
  AppState,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import Toast from '@ant-design/react-native/lib/toast';
import { getPayDesc } from '../../../services/client/client';
import HTML from 'react-native-render-html';

import { colors, images } from '../../../utils';
import { PayConfig, PayMethodItem, getPayItemViews } from '../../PayModal';
const { width: DEVICE_WIDTH } = Dimensions.get('window');

export type getAdapay = {
  appid: string;
  appCode: string;
  openWechatpay: boolean;
  openAlipay: boolean;
  openYspay: boolean;
  type: string;
};

const TopUp = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  /** 支付通道信息 - 在Home中获取过 */
  const { userBalance }: { payConfigs: PayConfig[]; userBalance: number } =
    useSelector((state: any) => state.client);
  const getAdapays: getAdapay[] = useSelector(
    (state: any) => state.client.getAdapays,
  );

  const [payDesc, setPayDesc] = useState('');

  const getPayDescs = async () => {
    let res = await getPayDesc();
    setPayDesc(res.data.content);
  };

  useEffect(() => {
    getPayDescs();
  }, []);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'TopUp' },
      });
    }, [dispatch]),
  );

  //是否支付中，用于更新支付结果回调查询
  const { isRecharge, orderNo, appIsActive } = useSelector(
    (state: any) => state.client,
  );
  useFocusEffect(
    useCallback(() => {
      //支付中进行校验结果
      if (isRecharge && appIsActive) {
        dispatch({
          type: 'client/getTopUpState',
          payload: { orderNo },
        });
      }
    }, [appIsActive, dispatch, isRecharge, orderNo]),
  );

  // 利用App状态切换检测
  const _handleAppStateChange = useCallback(
    (state) => {
      dispatch({
        type: 'client/SET_APP_STATE',
        payload: { appIsActive: state === 'active' },
      });
      if (state === 'active') {
        dispatch({
          type: 'client/userWalletBalance',
          payload: {},
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const subscription: any = AppState.addEventListener(
      'change',
      _handleAppStateChange,
    );
    return () => {
      subscription?.remove();
    };
  }, [_handleAppStateChange]);

  //自定义金额
  const [customAmount, setCustomAmount] = useState('');
  //自定义金额的改变赋值事件
  const handleCustomAmount = useCallback((val) => {
    //清除套餐选择值
    // setSelectedProductId(undefined);
    setCustomAmount(val.replace(/^(0+)|[^\d]+/g, ''));
  }, []);

  const [buyMethod, setBuyMethod] = useState<PayMethodItem>(
    null as unknown as PayMethodItem,
  );
  const payCallBack = useCallback(() => {
    dispatch({
      type: 'client/userWalletBalance',
      payload: {},
    });
  }, [dispatch]);

  const handlePay = useCallback(async () => {
    if (!buyMethod) {
      Toast.info('请选择支付方式');
      return;
    }
    console.log(buyMethod, 999);
    if (Number(customAmount) >= 1) {
      switch (buyMethod.id) {
        // 微信支付
        case 1:
          dispatch({
            type: 'client/doWechatRecharge',
            payload: {
              payChannel: 'wx_pub',
              money: customAmount,
              appId: buyMethod.appid,
            },
          });
          return;
        // 支付宝支付
        case 2:
          dispatch({
            type: 'client/doAlipayRecharge',
            payload: {
              payChannel: 'alipay_wap',
              money: customAmount,
              appId: buyMethod.appid,
            },
            payCallBack,
          });
          return;
      }
    } else {
      Toast.info('请输入充值金额', 1);
    }
  }, [buyMethod, customAmount, dispatch, payCallBack]);

  useLayoutEffect(() => {
    const shareOrder = () => {
      navigation.navigate('WalletDetails');
    };
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={styles.menuRight} onPress={shareOrder}>
          <Text style={styles.menuRightText}>明细</Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.container}>
        <ScrollView style={styles.contentContainer}>
          <View>
            <TouchableOpacity
              activeOpacity={1}
              // onPress={goTopup}
            >
              <ImageBackground
                source={images.PERSONAL_CENTER_WALLET_BG}
                imageStyle={styles.walletBgImageStyle}
                style={styles.walletBg}>
                <View style={styles.walletInfo}>
                  <Image
                    source={images.PERSONAL_CENTER_WALLET_ICON}
                    style={styles.walletImage}
                  />
                  <Text style={styles.walletCredit}>¥{userBalance}</Text>
                </View>
                <TouchableOpacity
                  style={styles.walletTopupSection}
                  onPress={() => {
                    navigation.navigate('onRefund');
                  }}>
                  {/* <Image
                source={images.PERSONAL_ARROW}
                style={[styles.arrowImage, { tintColor: colors.WHITE }]}
              /> */}
                  <Text style={styles.refund}>退款</Text>
                </TouchableOpacity>
              </ImageBackground>
            </TouchableOpacity>
          </View>
          <View style={styles.buyCreditSection}>
            <View style={styles.customLayout}>
              <Text style={styles.customLabel}>充值金额：</Text>
              <View style={styles.iconFlex}>
                <Text style={styles.iconStyle}>￥</Text>
                <TextInput
                  placeholder="点击输入充值金额"
                  style={styles.customInput}
                  maxLength={5}
                  keyboardType="numeric"
                  value={customAmount}
                  onChangeText={handleCustomAmount}
                  onFocus={() => {
                    handleCustomAmount('');
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.paymentMethodSection}>
            <Text style={styles.paymentMethodSectionHeader}>支付方式</Text>
            {getPayItemViews(getAdapays, setBuyMethod, buyMethod)}
          </View>
          <View style={styles.explain}>
            <HTML source={{ html: payDesc }} />
          </View>
          <View style={styles.policyContainer}>
            <Text>
              支付即表示您已阅读并同意
              <Text
                style={{ color: colors.DODGER_BLUE01 }}
                onPress={() => {
                  navigation.navigate('Agreement');
                }}>
                《充值和购买服务协议》
              </Text>
            </Text>
          </View>
        </ScrollView>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={handlePay} style={styles.confirmPayment}>
          <Text style={styles.confirmPaymentText}>确认支付</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

let { height } = Dimensions.get('window');
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: '#F5F5F5',
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  container: {
    width: '100%',
    height: height - 140,
  },
  walletBgImageStyle: {
    borderRadius: 8,
    height: 100,
  },
  walletBg: {
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  walletInfo: {
    marginVertical: 8,
    alignItems: 'center',
    marginTop: 20,
  },
  walletImage: { width: 32, height: 32 },
  walletCredit: { color: colors.WHITE, fontSize: 24 },
  walletTopupSection: {
    color: colors.WHITE,
    position: 'absolute',
    right: 0,
    paddingLeft: 10.5,
    paddingRight: 15,
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
  },
  arrowImage: { width: 8, height: 13 },
  refund: {
    color: colors.WHITE,
    fontSize: 16,
  },
  accBalance: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.WHITE,
    paddingLeft: 22.5,
    paddingRight: 20,
    alignItems: 'center',
    marginBottom: 8,
  },
  accBalanceText: { color: colors.TUNDORA, fontSize: 14 },
  accBalanceValue: { color: colors.GUARDSMAN_RED, fontSize: 14 },
  creditItem: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 4,
    paddingVertical: 7,
    borderRadius: 7,
    borderWidth: 1,
  },
  creditItemYuan: { fontSize: 16, marginBottom: 4, fontWeight: '700' },
  creditItemKe: { fontSize: 12 },
  buyCreditSection: {
    backgroundColor: colors.WHITE,
    marginBottom: 8,
    height: 100,
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    padding: 16,
    borderRadius: 8,
  },
  buyCreditHeader: { paddingLeft: 12, marginBottom: 12 },
  buyCreditTitle: {
    fontSize: 16,
    color: colors.TUNDORA,
    fontWeight: '700',
  },
  buyCreditDesc: {
    fontSize: 12,
    color: colors.GUARDSMAN_RED,
  },
  buyCreditItemRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginHorizontal: 12,
  },
  marginBottom8: { marginBottom: 8 },
  marginBottom405: { marginBottom: 4.5 },
  borderBottom: {
    position: 'absolute',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.MERCURY,
    height: 1,
    width: '100%',
    left: 0,
    bottom: 0,
  },
  paymentMethodContainer: {
    paddingRight: 15,
    borderColor: colors.MERCURY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  paymentMethodSection: {
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingTop: 12,
    backgroundColor: colors.WHITE,
  },
  paymentMethodSectionHeader: {
    color: colors.TUNDORA,
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 3,
  },
  paymentMethodItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  paymentMethodHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentName: {
    fontSize: 14,
    color: colors.TUNDORA,
    fontWeight: '700',
    marginLeft: 12,
  },
  paymentTermsSection: {
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  paymentTermsHeader: { color: colors.GRAY, fontSize: 12 },
  paymentTermsText: {
    color: colors.GRAY,
    fontSize: 12,
    lineHeight: 16.5,
  },
  agreeTerms: { flexDirection: 'row', marginTop: 6 },
  policyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 40,
  },
  agreeTermsCheck: {
    width: 18,
    height: 18,
  },
  agreeTermsText01: { color: colors.GRAY, fontSize: 11, marginLeft: 4 },
  agreeTermsText02: {
    color: colors.GUARDSMAN_RED,
    fontSize: 11,
    marginLeft: 4,
  },
  confirmPaymentBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
  },
  confirmPaymentBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    fontWeight: '700',
  },
  customLayout: {
    flex: 1,
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 12,
  },
  customLabel: { color: '#444', fontSize: 16, fontWeight: 'bold' },
  customInput: {
    borderColor: '#EEE',
    borderWidth: 0,
    borderRadius: 5,
    fontSize: 18,
    marginTop: 10,
    height: 30,
    padding: 5,
    flex: 1,
  },
  iconFlex: {
    flexDirection: 'row',
  },
  iconStyle: {
    fontSize: 28,
    marginTop: 8,
  },
  confirmPayment: {
    height: 50,
    flexDirection: 'row',
    backgroundColor: colors.RED_00,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.MERCURY,
  },
  confirmPaymentText: {
    color: colors.WHITE,
    fontSize: 18,
  },
  explain: {
    paddingHorizontal: 16,
    paddingTop: 16,
    width: '100%',
  },
  explainText: {
    color: '#444444',
    fontSize: 13,
    marginTop: 8,
  },
  menuRight: {
    marginRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuRightText: { color: colors.WHITE, fontSize: 15 },
  buttonContainer: {
    flex: 0,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});

export default TopUp;
