/* eslint-disable react-native/no-inline-styles */
/*
 * @Description: 代替RN的Radio.RadioItem组件
 * @Author: Evan huang
 * @Date: 2023-07-07 21:20:12
 * @LastEditors: Evan huang
 * @LastEditTime: 2023-07-07 22:08:04
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/componenets/RadioItem.tsx
 */

import React from 'react';
import { Image, Pressable } from 'react-native';

const RadioItem = ({ children, checked, onChange, disabled = false }) => {
  return (
    <Pressable
      disabled={disabled}
      onPress={() => onChange({ target: { checked: true } })}
      style={{
        height: 60,
        width: '100%',
        flexDirection: 'row',
        alignContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 15,
      }}>
      <>
        {children}
        <Image
          style={{ height: 18, width: 18, position: 'absolute', right: 15 }}
          source={
            checked
              ? require('../assets/selected.png').default
              : require('../assets/select.png').default
          }
        />
      </>
    </Pressable>
  );
};
export default RadioItem;
