import { getAll, getByCode } from '../services/open/dictionary';

const SET_DICTIONARIES = 'SET_DICTIONARIES';
const SET_DICTIONARIES_BY_CODE = 'SET_DICTIONARIES_BY_CODE';

export default {
  namespace: 'dictionary',
  state: {
    dictionaries: [],
    dictionariesByCode: {},
  },
  effects: {
    *getAll({ payload }, { put, call }) {
      const response = yield call(getAll, payload);
      if (response.success) {
        yield put({
          type: SET_DICTIONARIES,
          payload: response.data,
        });
      }
    },
    *getByCode({ payload }, { put, call }) {
      const response = yield call(getByCode, payload);
      if (response.success) {
        yield put({
          type: SET_DICTIONARIES_BY_CODE,
          payload: response.data,
        });
      }
    },
  },
  reducers: {
    SET_DICTIONARIES(state, { payload }) {
      state.dictionaries = payload;
    },
    SET_DICTIONARIES_BY_CODE(state, { payload }) {
      if (payload && payload.length > 0) {
        state.parametersByCode[payload[0].code] = payload;
      }
    },
  },
};
