import React, { useCallback, useEffect } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  Image,
  Dimensions,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  StatusBar,
  Platform,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import NavIcon from './NavIcon';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';

import { colors, images } from '../../../utils';
import Toast from '@ant-design/react-native/lib/toast';

const { width: DEVICE_WIDTH } = Dimensions.get('window');

const RowWithArrowStyle = StyleSheet.create({
  mainContainer: {
    paddingLeft: 16,
    backgroundColor: colors.WHITE,
  },
  mainContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.MERCURY,
  },
  titleText: { color: colors.GRAY20, fontSize: 14 },
  arrowIcon: {
    padding: 16,
    height: 45,
  },
});

const MyPage = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const currentClient = useSelector((state: any) => state.client.currentClient);
  const userBalance = useSelector((state: any) => state.client.userBalance);

  const marginTop = Platform.OS === 'android' ? 20 : 0;
  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );

  //获取支付通道
  useEffect(() => {
    if (currentMerchant.id) {
      dispatch({
        type: 'client/getAdapayVosByMerchantId',
        payload: { merchantId: currentMerchant.id },
      });
    }
  }, [currentMerchant.id]);

  const goTopup = () => {
    navigation.navigate('TopUp');
    // Toast.info('请下载APP充值', 1);
  };

  const goSMS = () => {
    navigation.navigate('Notice');
  };

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentClient',
        payload: {},
      });
      dispatch({
        type: 'clientOrder/getProducts',
        payload: {},
      });
      dispatch({
        type: 'client/userWalletBalance',
        payload: {},
      });
    }, [dispatch]),
  );

  // 获取是否开启支付宝
  useEffect(() => {
    dispatch({
      type: 'parameter/getByCode',
      payload: { code: 'is_open_alipay' },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: { code: 'is_open_wechat_pay' },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: { code: 'is_open_yspay' },
    });
  }, [dispatch]);
  useEffect(() => {}, [dispatch]);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'MyPage' },
      });
    }, [dispatch]),
  );

  return (
    <>
      <SafeAreaView style={styles.topContainer}>
        {useIsFocused() && <StatusBar barStyle="dark-content" />}
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigation.navigate('AccInfo')}
          style={{ marginTop }}>
          <View style={styles.profileSection}>
            <View style={styles.profileInfoSection}>
              <Image
                source={
                  currentClient.avatar
                    ? { uri: currentClient.avatar }
                    : images.DEFAULT_CLIENT_AVATAR
                }
                style={styles.profileImage}
              />
              <View style={styles.profileTextSection}>
                <Text style={[styles.profileText, styles.bold]}>
                  {currentClient.nickname}
                </Text>
                <Text style={styles.profileText}>
                  {currentClient.phoneNumber
                    ? currentClient.phoneNumber
                    : '未绑定手机号'}
                </Text>
              </View>
            </View>
            <View style={styles.profileSMSSection}>
              <TouchableOpacity
                style={styles.smsIcon}
                onPress={() => {
                  goSMS();
                }}
              />
              <TouchableOpacity
                style={styles.editProfileIcon}
                onPress={() => navigation.navigate('AccInfo')}>
                <Image
                  source={images.PERSONAL_ARROW}
                  style={styles.arrowImage}
                />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>
      </SafeAreaView>
      <SafeAreaView style={styles.mainContainer}>
        <View style={styles.mainView}>
          <TouchableOpacity activeOpacity={1} onPress={goTopup}>
            <ImageBackground
              source={images.PERSONAL_CENTER_WALLET_BG}
              imageStyle={styles.walletBgImageStyle}
              style={styles.walletBg}>
              <View style={styles.walletInfo}>
                <Image
                  source={images.PERSONAL_CENTER_WALLET_ICON}
                  style={styles.walletImage}
                />
                <Text style={styles.walletInfoTitle}>金额(元)</Text>
                <Text style={styles.walletCredit}>¥{userBalance}</Text>
              </View>
              <View style={styles.walletTopupSection}>
                <Image
                  source={images.PERSONAL_ARROW}
                  style={[styles.arrowImage, { tintColor: colors.WHITE }]}
                />
              </View>
            </ImageBackground>
          </TouchableOpacity>
          <View style={styles.myFunction}>
            <TouchableOpacity
              style={styles.everyFunction}
              onPress={() => navigation.navigate('Extension')}>
              <Image source={images.EXTENSION} style={styles.funImg} />
              <Text style={{ fontSize: 13 }}>我要推广</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.everyFunction}
              onPress={() => navigation.navigate('invitation')}>
              <Image source={images.INVITATION} style={styles.funImg} />
              <Text style={{ fontSize: 13 }}>我的邀请</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.everyFunction}
              onPress={() => navigation.navigate('promotionWallet')}>
              <Image source={images.PROMOTION_WALLET} style={styles.funImg} />
              <Text style={{ fontSize: 13 }}>推广钱包</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.iconContainer}>
            <View style={styles.iconContainerWrapper}>
              <View style={[styles.iconRow]}>
                <NavIcon
                  image="ACCOUNT_BINDING"
                  title="已购订单"
                  routeName="OrderScreen"
                />
                <NavIcon
                  image="CUSTOMERS"
                  title="商家申请"
                  routeName="MerchantApply"
                />
                <NavIcon
                  image="CUSTOMER_SERVICE_MERCHANT"
                  title="纠纷解决"
                  routeName="DisputeResolve"
                />
              </View>
              <View style={[styles.iconRow]}>
                <NavIcon
                  image="CHAT_ICON"
                  title="私信消息"
                  routeName="Information"
                />
                <NavIcon
                  image="PRIVATE_ICON"
                  title="免责说明"
                  routeName="Disclaim"
                />
                <NavIcon
                  image="COUPON_MGR_ICON"
                  title="我的优惠券"
                  routeName="Coupon"
                />
                {/* <View style={{ opacity: 0 }}>
                  <NavIcon />
                </View> */}
              </View>
              <View style={[styles.iconRow]}>
                <NavIcon
                  image="COUPON_MGR_ICON"
                  title="我的优惠卡"
                  routeName="DiscountCard"
                  routeParams={{ from: 'myPage' }}
                />
                <View style={{ opacity: 0 }}>
                  <NavIcon />
                </View>
                <View style={{ opacity: 0 }}>
                  <NavIcon />
                </View>
              </View>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    backgroundColor: colors.WHITE,
  },
  mainContainer: {
    flex: 1,
  },
  iconContainer: {
    borderRadius: 8,
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    width: DEVICE_WIDTH - 32,
    justifyContent: 'space-between',
    padding: 12,
  },
  iconContainerWrapper: {
    // width: 280,
    justifyContent: 'space-between',
  },
  iconRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 24,
  },
  mainView: { flex: 1 },
  profileText: {
    flex: 0,
    fontSize: 16,
    color: colors.TUNDORA,
    marginVertical: 2,
  },
  bold: { fontWeight: '700' },
  semiBold: { fontWeight: '600' },
  profileSection: {
    flexDirection: 'row',
    backgroundColor: colors.WHITE,
    borderColor: colors.BLACK,
    paddingRight: 10,
    justifyContent: 'space-between',
  },
  profileImage: { width: 50, height: 50, borderRadius: 50 },
  profileInfoSection: {
    flex: 1,
    flexDirection: 'row',
    height: 50,
    marginTop: 18,
    paddingLeft: 20,
  },
  profileTextSection: {
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 8,
    marginTop: 3,
    marginBottom: 3,
  },
  profileSMSSection: {
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  smsIcon: {
    paddingRight: 6,
  },
  smsImage: { width: 23, height: 18 },
  arrowImage: { width: 8, height: 13 },
  editProfileIcon: {
    paddingLeft: 10,
  },
  walletBg: {
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  walletBgImageStyle: {
    borderRadius: 8,
  },
  walletImage: { width: 32, height: 32 },
  walletInfo: {
    marginVertical: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  walletInfoTitle: { color: colors.WHITE, fontSize: 14, fontWeight: '600' },
  walletCredit: { color: colors.WHITE, fontSize: 24 },
  walletTopupSection: {
    position: 'absolute',
    right: 0,
    paddingLeft: 10.5,
    paddingRight: 10.5,
    flexDirection: 'row',
    marginTop: 43,
    alignItems: 'center',
  },
  walletTopupText: {
    color: colors.WHITE,
    fontSize: 14,
    fontWeight: '600',
    marginRight: 5.5,
  },
  walletTopupIcon: { color: colors.WHITE, fontSize: 12 },
  flex1: { flex: 1, marginTop: 12 },
  myFunction: {
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 12,
    padding: 12,
    paddingBottom: 18,
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  myFunction1: {
    width: DEVICE_WIDTH - 32,
    alignSelf: 'center',
    marginTop: 12,
    borderRadius: 12,
    marginBottom: 12,
    padding: 12,
    paddingBottom: 18,
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  everyFunction: {
    width: 60,
    height: 60,
  },
  funImg: {
    width: '80%',
    height: '80%',
    marginBottom: 5,
  },
});

export default MyPage;
