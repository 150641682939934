/* eslint-disable react-native/no-inline-styles */
/*
 * @Description: 支付公共弹窗
 * @Author: Evan huang
 * @Date: 2023-07-07 15:59:26
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-10 19:36:51
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/PayModal.tsx
 */

import React, { useEffect, useState } from 'react';
import Modal from '@ant-design/react-native/lib/modal';
import List from '@ant-design/react-native/lib/list';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  ActivityIndicator,
} from 'react-native';
import { colors, images } from '../utils';
import { useSelector } from 'react-redux';
import RadioItem from '../components/RadioItem';
import DiscountCard from './Client/MyPage/DiscountCard';
import Toast from '@ant-design/react-native/lib/toast';
import Icon from '@ant-design/react-native/lib/icon';
import { useNavigation } from '@react-navigation/native';

const deviceHeight = Dimensions.get('window').height;

export type PayMethodItem = {
  id: number;
  appid?: string;
  selectId: string;
};
/** 支付配置对象 */
export type PayConfig = {
  appid: string;
  appCode: string;
  openWechatpay: boolean;
  openAlipay: boolean;
  openYspay: boolean;
};

export type getAdapay = {
  appid: string;
  appCode: string;
  openWechatpay: boolean;
  openAlipay: boolean;
  openYspay: boolean;
  type: string;
};

//支付方式信息
const PAY_METHODS = [
  {
    id: 1,
    icon: images.WEIXIN_ICON,
    title: '微信支付',
    /** 通道是否开启Key */
    isOpenKey: 'openWechatpay',
  },
  {
    id: 2,
    icon: images.ZHIFUBAO_ICON,
    title: '支付宝',
    isOpenKey: 'openAlipay',
  },
  //H5 未接入云闪付
  // { id: 4, icon: images.CLOUD, title: '云闪付', isOpenKey: 'openYspay' },
];
//支付方式：1: 微信, 2: 支付宝, 3:余额支付, 4:云闪付

export default function PayModal({
  visible,
  setPayModal,
  onTopup,
  articleAmount,
  isBuyDoing,
  confirmPurchase,
  setBuyMethod,
  buyMethod,
  from = 'article',
}) {
  /** 支付通道信息 - 在Home中获取过 */
  const payConfigs: PayConfig[] = useSelector(
    (state: any) => state.client.payConfigs,
  );

  const opacity = isBuyDoing ? 0.6 : 1;

  const { currentMerchant } = useSelector((state: any) => state.clientMerchant);
  const { userBalance } = useSelector((state: any) => state.client);
  const [couponVisible, setCouponVisible] = useState(false);
  const [coupon, setCoupon] = useState<any>();
  const [discountCardVisible, setDiscountCardVisible] = useState(false);
  const [discountCard, setDiscountCard] = useState<any>();
  const [orderPrice, setOrderPrice] = useState(articleAmount);

  const getAdapays: getAdapay[] = useSelector(
    (state: any) => state.client.getAdapays,
  );

  console.log(getAdapays, 99);
  const currentArticle = useSelector(
    (state: any) => state.clientMerchant.currentArticle,
  );

  // 商户设置的折扣
  const merchantDiscount = (
    parseFloat((currentArticle.amount / currentArticle.price).toPrecision(12)) *
    10
  ).toFixed(1);

  useEffect(() => {
    if (userBalance >= articleAmount) {
      setBuyMethod({ id: 3, selectId: '3' });
    } else {
      payConfigs.map((payCfg, index) => {
        //循环支付方式
        return PAY_METHODS.map((method) => {
          if (index === 0) {
            setBuyMethod({
              id: method.id,
              appid: payCfg.appid,
              selectId: method.id + payCfg.appCode,
            });
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    setOrderPrice(articleAmount);
  }, [articleAmount]);

  /** 更新显示价格 */
  const getOrderPrice = (nowCoupon?, nowDiscountCard?, nowOrderPrice?) => {
    console.log(
      '==========计算前的价格==========',
      nowCoupon?.couponAmount,
      nowDiscountCard?.cardType || nowDiscountCard?.maxDeductionPrice,
      nowOrderPrice,
    );
    let price = nowOrderPrice;
    if (nowCoupon && nowDiscountCard) {
      // 先券后折
      price = Math.ceil(
        (nowOrderPrice - nowCoupon.couponAmount) *
          (nowDiscountCard.cardValue / 10),
      );
    } else if (nowCoupon) {
      price = Math.ceil(nowOrderPrice - nowCoupon.couponAmount);
    } else if (nowDiscountCard && nowDiscountCard.cardType === 2) {
      // 优惠卡为折扣卡，计算折扣，其中discountCard.cardValue为整数代表几折
      price = Math.ceil(nowOrderPrice * (nowDiscountCard.cardValue / 10));
    } else if (
      nowDiscountCard &&
      (nowDiscountCard.cardType === 3 || nowDiscountCard.cardType === 1)
    ) {
      // 优惠卡为会员卡，直接免费
      price = 0;
    }
    console.log('==========计算后的结果==========', price);
    setOrderPrice(price < 0 ? 0 : price);
  };

  /** 优惠卡的选择 */
  const chooseDiscountCard = (item) => {
    if (coupon && item.cardType !== 2) {
      Toast.fail(
        (item.cardType === 1 ? '次卡' : '会员卡') + '不能与优惠券同时使用',
        1,
      );
      return;
    }
    if (
      item.cardType === 1 &&
      item.maxDeductionPrice !== 0 &&
      orderPrice > item.maxDeductionPrice
    ) {
      Toast.fail('价格超过次卡的最高抵扣金额', 1);
      return;
    }
    if (item.cardType === 2 && item.cardValue > merchantDiscount) {
      Toast.fail('折扣卡折扣比例不能大于商户设置的折扣', 1);
      return;
    }
    item.cardType !== 2 && setBuyMethod({});
    setDiscountCard(item);
    setDiscountCardVisible(false);
    getOrderPrice(coupon, item, currentArticle.price);
  };

  /** 优惠卡的取消 */
  const cansleDiscountCard = () => {
    setDiscountCard(undefined);
    getOrderPrice(
      coupon,
      undefined,
      coupon ? currentArticle.price : articleAmount,
    );
  };

  return (
    <>
      <Modal
        popup
        visible={visible}
        style={buyStyles.buyMethodContainer}
        onClose={() => setPayModal(false)}
        animationType="slide-up"
        maskClosable>
        <List>
          <TouchableOpacity
            style={buyStyles.confirmCloseBtn}
            onPress={() => setPayModal(false)}>
            <Image
              source={images.X_CLOSE_ICON}
              style={buyStyles.closeIconImage}
            />
          </TouchableOpacity>
          <Text style={buyStyles.priceText}>{orderPrice}元</Text>

          {
            //优惠卡选择区域
            from === 'article' && (
              <List.Item
                onPress={() => {
                  setDiscountCardVisible(true);
                }}
                extra={
                  discountCard ? (
                    <Icon name="close-circle" onPress={cansleDiscountCard} />
                  ) : (
                    <Icon name="arrow-right" />
                  )
                }>
                <View style={buyStyles.buyMethod}>
                  <Image
                    source={images.COUPON_ICON}
                    style={buyStyles.buyMethodsIcon}
                  />
                  {discountCard ? (
                    <Text style={buyStyles.couponItem}>
                      {discountCard?.cardName}
                    </Text>
                  ) : (
                    <Text style={buyStyles.buyMethodsText}>请选择优惠卡</Text>
                  )}
                </View>
              </List.Item>
            )
          }

          {
            //当discountCard.cardType ===1或3时，不显示支付方式
            from === 'article' &&
            (discountCard?.cardType === 1 ||
              discountCard?.cardType === 3) ? null : (
              <>
                <RadioItem
                  disabled={userBalance < articleAmount}
                  checked={buyMethod?.id === 3}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setBuyMethod({ id: 3, selectId: '3' });
                    }
                  }}>
                  <View style={buyStyles.buyMethod}>
                    <Image
                      source={images.BUY_METHOD_BALANCE}
                      style={
                        userBalance < articleAmount
                          ? buyStyles.disableBuyMethodsIcon
                          : buyStyles.buyMethodsIcon
                      }
                    />
                    <View>
                      <View style={buyStyles.balanceView}>
                        <Text
                          style={
                            userBalance < articleAmount
                              ? buyStyles.disableBuyMethodsText
                              : buyStyles.buyMethodsText
                          }>
                          余额支付
                        </Text>
                        <View style={{ marginLeft: 30 }}>
                          <Text style={buyStyles.balanceText} onPress={onTopup}>
                            充值
                          </Text>
                        </View>
                      </View>
                      {userBalance < articleAmount ? (
                        <Text style={buyStyles.disableBuyText}>
                          余额不足，剩余:{userBalance}元
                        </Text>
                      ) : (
                        <Text style={buyStyles.disableBuyText}>
                          余额:{userBalance}元
                        </Text>
                      )}
                    </View>
                  </View>
                </RadioItem>
                <ScrollView style={{ maxHeight: deviceHeight / 2 }}>
                  {getPayItemViews(getAdapays, setBuyMethod, buyMethod)}
                </ScrollView>
              </>
            )
          }
        </List>
        <TouchableOpacity
          disabled={isBuyDoing}
          onPress={() =>
            confirmPurchase && confirmPurchase(orderPrice, discountCard)
          }
          style={[buyStyles.buyBottomButton, { opacity }]}>
          {isBuyDoing ? (
            <ActivityIndicator color={colors.WHITE} />
          ) : (
            <Text style={buyStyles.buyBottomButtonText}>立即付款</Text>
          )}
        </TouchableOpacity>
      </Modal>
      <Modal
        popup
        visible={discountCardVisible}
        style={buyStyles.buyMethodContainer}
        onClose={() => {
          setDiscountCardVisible(false);
        }}
        animationType="slide-up"
        maskClosable>
        <View style={{ maxHeight: deviceHeight / 2 }}>
          <DiscountCard
            from="article"
            navigation={useNavigation()}
            onChoose={(item) => {
              chooseDiscountCard(item);
            }}
          />
        </View>
      </Modal>
    </>
  );
}

export const getPayItemViews = (
  getAdapays: getAdapay[] = [],
  setBuyMethod = (_: PayMethodItem) => {},
  buyArticleMethod: PayMethodItem,
) => {
  //循环支付通道
  const payItemView = getAdapays.map((payCfg) => {
    //循环支付方式
    // return PAY_METHODS.map((method) => {
    //   if (payCfg[method.isOpenKey] && currentMerchant[method.isOpenKey] !== 1) {
    //     return (
    //       <RadioItem
    //         key={payCfg.appCode + method.id}
    //         checked={buyArticleMethod?.selectId === method.id + payCfg.appCode}
    //         onChange={(event) => {
    //           if (event.target.checked) {
    //             setBuyMethod({
    //               id: method.id,
    //               appid: payCfg.appid,
    //               selectId: method.id + payCfg.appCode,
    //             });
    //           }
    //         }}>
    //         <View style={buyStyles.buyMethod}>
    //           <Image source={method.icon} style={buyStyles.buyMethodsIcon} />
    //           <Text style={buyStyles.buyMethodsText}>
    //             {method.title} - 通道{payCfg.appCode}
    //           </Text>
    //         </View>
    //       </RadioItem>
    //     );
    //   }
    // });
    return PAY_METHODS.map((method) => {
      if (payCfg.type == method.id) {
        return (
          <RadioItem
            key={payCfg.appCode + method.id}
            checked={buyArticleMethod?.selectId === method.id + payCfg.appCode}
            onChange={(event) => {
              if (event.target.checked) {
                setBuyMethod({
                  id: method.id,
                  appid: payCfg.appid,
                  selectId: method.id + payCfg.appCode,
                });
              }
            }}>
            <View style={buyStyles.buyMethod}>
              <Image source={method.icon} style={buyStyles.buyMethodsIcon} />
              <Text style={buyStyles.buyMethodsText}>
                {method.title}
                {payCfg.appCode}
              </Text>
            </View>
          </RadioItem>
        );
      }
    });
  });

  return payItemView;
};

// 点击支付时弹出层样式
const buyStyles = StyleSheet.create({
  buyMethodContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  confirmCloseBtn: { position: 'absolute', top: 20, right: 20 },
  closeIconImage: { width: 12, height: 12 },
  priceText: {
    marginTop: 50,
    marginBottom: 20,
    color: '#D90000',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  buyMethod: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
  },
  buyMethodsIcon: {
    width: 40,
    height: 40,
  },
  buyMethodsText: {
    marginLeft: 12,
    fontWeight: 'bold',
    color: '#444444',
    fontSize: 14,
  },
  couponItem: {
    fontSize: 16,
    marginLeft: 12,
    color: 'red',
  },
  disableBuyMethodsIcon: {
    opacity: 0.4,
    width: 40,
    height: 40,
  },
  disableBuyMethodsText: {
    opacity: 0.4,
    marginLeft: 12,
    fontWeight: 'bold',
    color: '#444444',
    fontSize: 14,
  },
  disableBuyText: {
    opacity: 0.4,
    marginLeft: 12,
    color: '#444444',
    fontSize: 13,
  },
  buyBottomButton: {
    width: 285,
    height: 44,
    alignSelf: 'center',
    borderRadius: 6,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
    // marginBottom: 50,
    marginTop: 12,
  },
  buyBottomButtonText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
  },
  balanceView: {
    flexDirection: 'row',
  },
  balanceText: {
    color: '#02A9F1',
    textDecorationLine: 'underline',
  },
});
