/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: lizhengkai
 * @Date: 2022-04-14 09:24:41
 * @FilePath: /msll_h5/src/components/ExtensionModal.tsx
 */
import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Modal from '@ant-design/react-native/lib/modal';

import { colors, images } from '../utils';

const ExtensionModal = (props) => {
  const {
    visible = true,
    onClose = () => {},
    customerQr = null,
    avatar,
    name = '',
  } = props;

  const [width, setWidth] = useState(0);
  return (
    <Modal
      visible={visible}
      maskClosable
      transparent
      onClose={onClose}
      style={styles.container}>
      <TouchableOpacity onPress={onClose} style={styles.modalCloseBtn}>
        <Image source={images.CLOSE_ICON} style={styles.modalCloseBtnImage} />
      </TouchableOpacity>
      <View
        onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
        style={styles.viewShotContainer}>
        <View style={styles.message}>
          <View style={{ alignItems: 'center' }}>
            <View style={{ width: 60, height: 60 }}>
              {avatar && (
                <Image
                  source={avatar}
                  style={{ width: '90%', height: '90%' }}
                />
              )}
            </View>
            <Text style={{ fontSize: 16, color: colors.WHITE }}>{name}</Text>
          </View>
        </View>
        {customerQr && customerQr.length > 0 && (
          <Image
            source={{ uri: customerQr }}
            style={{
              width: width - 20,
              height: width - 20,
            }}
          />
        )}
        <View style={styles.wechatDescSection}>
          <Text style={styles.wechatDesc}>扫描商家二维码关注</Text>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  modalContainer: {
    backgroundColor: '#888888',
    zIndex: 1999999,
    borderWidth: 1,
    borderColor: 'red',
  },
  modalCloseBtn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 24,
  },
  modalCloseBtnImage: { width: 30, height: 30 },
  wechatImage: { width: 240, height: 240 },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
  downloadIcon: { position: 'absolute', bottom: -75 },
  downloadIconImage: {
    width: 45,
    height: 45,
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 24,
  },
  viewShotContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 20,
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  message: {
    backgroundColor: '#FF9C6E',
    width: '100%',
    padding: 10,
  },
});

export default ExtensionModal;
