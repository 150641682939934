/*
 * @Author: lizhengkai
 * @Date: 2022-04-14 14:32:38
 * @FilePath: /msll-client/src/screens/Client/MyPage/Invitation.tsx
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  View,
  Image,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { colors, images } from '../../../utils';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import dayjs from 'dayjs';

const Invitation = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const invitationList = useSelector(
    (state: any) => state.client.invitationList,
  );
  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const tongJi = useSelector((state: any) => state.client.tongJi);

  const fetchData = useCallback(
    (page) => {
      dispatch({
        type: 'client/myInviteUserList',
        payload: {
          currentPage: page,
          pageSize: 10,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch({
      type: 'client/myInviteUserList',
      payload: {
        currentPage: 1,
        pageSize: 10,
      },
    });
  }, [dispatch]);

  const userClick = (id) => {
    navigation.navigate('attention', { id });
  };

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity
        style={styles.merchantItemMain}
        onPress={() => userClick(item.id)}>
        <View style={styles.merchantItemContentSection}>
          <View>
            <Image
              source={
                item.avatar ? { uri: item.avatar } : images.DEFAULT_AVATAR
              }
              style={styles.merchantItemProfileImage}
            />
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{item.nickname}</Text>
            <Text style={styles.merchantItemDesc}>
              注册：{dayjs(item.createTime).format('YYYY-MM-DD')}
            </Text>
          </View>
        </View>
        <View>
          <Text>关注：{item.relationsNum}</Text>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.search}>
        <TextInput
          returnKeyType="search"
          placeholder="请输入姓名搜索"
          value={keyword}
          onChangeText={(val) => {
            setKeyword(val);
            if (val === '') {
              setSearchKeyword('');
              dispatch({
                type: 'client/myInviteUserList',
                payload: {
                  currentPage: 1,
                  pageSize: 10,
                  realName: keyword,
                },
              });
            }
          }}
          onSubmitEditing={() => {
            setSearchKeyword(keyword);
            dispatch({
              type: 'client/myInviteUserList',
              payload: {
                currentPage: 1,
                pageSize: 10,
                realName: keyword,
              },
            });
          }}
          style={styles.inputStyle}
        />
        <View style={styles.btnStyle}>
          <Text
            style={styles.searchText}
            onPress={() => {
              dispatch({
                type: 'client/myInviteUserList',
                payload: {
                  currentPage: 1,
                  pageSize: 10,
                  realName: keyword,
                },
              });
            }}>
            搜索
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 40, marginBottom: 10 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
          <View>
            <Text style={styles.word1}>{tongJi.today}</Text>
          </View>
          <View>
            <Text style={styles.word1}>{tongJi.total}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
          <View>
            <Text style={styles.word}>今日邀请</Text>
          </View>
          <View>
            <Text style={styles.word}>总共邀请</Text>
          </View>
        </View>
      </View>
      <FlatList
        data={invitationList?.records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        refreshing={false}
        onRefresh={() => fetchData(1)}
        onEndReached={() => {
          if (!invitationList?.isAllLoaded) {
            fetchData(invitationList?.page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无数据" />}
        ListFooterComponent={
          invitationList?.records.length && invitationList?.isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  menuRight: {
    marginRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuRightText: { color: colors.WHITE, fontSize: 15 },
  header: {
    backgroundColor: '#ffffff',
  },
  search: {
    // width: 400,
    height: 32,
    marginTop: 8,
    flexDirection: 'row',
    marginLeft: 16,
    flexWrap: 'nowrap',
    margin: 0,
    padding: 0,
  },
  inputStyle: {
    flex: 8,
    height: 32,
    fontSize: 16,
    borderColor: 'rgba(0,0,0,.2)',
    borderWidth: 1,
    // borderRightWidth: 0,
    paddingVertical: 0,
    padding: 0,
    borderRadius: 5,
    // borderBottomLeftRadius: 5,
    marginLeft: 20,
    paddingLeft: 5,
  },
  searchText: {
    fontSize: 16,
    color: '#2fb6f1',
  },
  btnStyle: {
    flex: 2,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    // backgroundColor: '#FFD700',
    // borderColor: 'rgba(0,0,0,.2)',
    // borderWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  searchBarContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  searchBar: {
    backgroundColor: colors.WILD_SAND,
    paddingLeft: 34,
    height: 35,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 4,
  },
  word: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  word1: {
    fontSize: 18,
  },
  searchInput: {
    color: colors.DUSTY_GRAY,
    fontSize: 14,
    minWidth: 200,
    padding: 0,
    width: '100%',
  },
  searchIcon: { position: 'absolute', width: 14, height: 14, left: 12 },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemProfileImage: { width: 60, height: 60, borderRadius: 60 },
  merchantItemNoticeNumberSection: {
    position: 'absolute',
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    backgroundColor: colors.RED_ORANGE,
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    top: -3.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 4,
  },
  merchantItemDesc: {
    flex: 1,
    color: colors.GRAY,
    fontSize: 13,
    lineHeight: 16,
    textAlign: 'justify',
    marginTop: 5,
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
});
const stylesModal = StyleSheet.create({
  title: {
    color: colors.BLACK,
    fontSize: 17,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 16,
  },
  description: {
    color: colors.TUNDORA,
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'justify',
  },
  btn: {
    width: 160,
    height: 47,
    marginTop: 20,
    borderRadius: 7.5,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  btnText: { color: colors.WHITE, fontSize: 18 },
});

export default Invitation;
