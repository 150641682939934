/*
 * @Author: your name
 * @Date: 2021-07-19 19:09:31
 * @LastEditTime: 2021-07-20 19:26:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/componenets/MBackButton.tsx
 */
import React from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/stack';

import { colors, images } from '../utils';

const MBackButton = () => {
  const navigation = useNavigation();

  return Platform.OS === 'web' ? (
    <TouchableOpacity
      onPress={() => navigation.goBack()}
      style={styles.backImageContainer}>
      <Image source={images.BACK_ICON} style={styles.backImage} />
    </TouchableOpacity>
  ) : (
    <HeaderBackButton
      labelVisible={false}
      tintColor={colors.WHITE}
      onPress={() => navigation.goBack()}
    />
  );
};

const styles = StyleSheet.create({
  backImageContainer: {
    marginLeft: 15,
  },
  backImage: {
    width: 25,
    height: 25,
    tintColor: colors.WHITE,
  },
});

export default MBackButton;
