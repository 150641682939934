/*
 * @Author: Eave Luo
 * @Date: 2021-08-02 10:37:05
 * @LastEditors: Eave Luo
 * @LastEditTime: 2021-08-05 18:09:58
 * @Description: file content
 */
import React, { useLayoutEffect } from 'react';
import {
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { colors, images } from '../../../utils';

const SuccessScreen = (props) => {
  const navigation = useNavigation();
  const { route } = props;

  useLayoutEffect(() => {
    navigation.setOptions({
      title:
        route.params.from === 'withdraw' || 'user_withdraw'
          ? '提现'
          : '实名认证',
    });
  }, [navigation, route]);

  return (
    route.params.from && (
      <SafeAreaView style={styles.container}>
        <View style={styles.successImageContainer}>
          <Image source={images.SUCCESS} style={styles.successImage} />
          <Text style={styles.successImageText}>
            {route.params.from === 'withdraw' || 'user_withdraw'
              ? '提现成功'
              : '提交成功'}
          </Text>
        </View>
        <View style={styles.submitBtnContainer}>
          <TouchableOpacity
            onPress={() =>
              route.params.from === 'withdraw'
                ? navigation.navigate('WithdrawalHistory')
                : route.params.from === 'user_withdraw'
                ? navigation.navigate('Wallet')
                : navigation.navigate('Withdrawal')
            }
            style={styles.submitBtn}>
            <Text style={styles.submitBtnText}>{'完  成'}</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  successImageContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 45,
  },
  successImage: {
    width: 179,
    height: 150,
  },
  successImageText: {
    color: colors.TUNDORA,
    fontSize: 18,
    fontWeight: '700',
    marginTop: 12,
  },
  submitBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    flexDirection: 'row',
    backgroundColor: colors.GUARDSMAN_RED,
    width: 160,
    height: 50,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    fontWeight: '700',
  },
});

export default SuccessScreen;
