/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import React, { useEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Image, StyleSheet } from 'react-native';
import { colors, images } from '../utils';
import { HomeScreen, MyPageScreen } from '../screens';
import MerchantListSearchScreen from '../screens/MerchantListSearch';

const Tab = createBottomTabNavigator();
const HomeNavigator = () => {
  const currentClient = useSelector((state: any) => state.client.currentClient);
  let dispatch = useDispatch();

  useEffect(() => {
    if (!currentClient.id) {
      return;
    }
    dispatch({
      type: 'client/getClientMessage',
      payload: {
        userId: currentClient.id,
      },
    });
  }, [currentClient]);

  return (
    <>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused }) => {
            let tabImgSource;
            if (route.name === 'main') {
              tabImgSource = focused
                ? images.HOME_FOCUSED
                : images.HOME_BLURRED;
            } else if (route.name === 'my') {
              tabImgSource = focused
                ? images.MY_PAGE_FOCUSED
                : images.MY_PAGE_BLURRED;
            } else if (route.name === 'merchant') {
              tabImgSource = focused
                ? images.ORDER_FOCUSED
                : images.ORDER_BLURRED;
            }
            return <Image source={tabImgSource} style={styles.tabImg} />;
          },
        })}
        tabBarOptions={{
          activeTintColor: colors.GUARDSMAN_RED,
        }}>
        <Tab.Screen
          name="main"
          component={HomeScreen}
          options={{ title: '首页' }}
        />
        <Tab.Screen
          name="merchant"
          component={MerchantListSearchScreen}
          options={{ title: '关注' }}
        />
        <Tab.Screen
          name="my"
          component={MyPageScreen}
          options={{ title: '我的' }}
        />
      </Tab.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  tabImg: {
    width: 22,
    height: 22,
  },
  confirmModalMainBody: {
    backgroundColor: colors.WHITE,
    height: 60,
    width: 350,
    flexDirection: 'row',
    alignSelf: 'center',
    borderRadius: 40,
    paddingHorizontal: 20,
    paddingBottom: 12,
    justifyContent: 'flex-start',
    position: 'absolute',
    shadowColor: '#000',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.8,
    shadowRadius: 6,
    elevation: 10,
  },
  isDisabledModalTitle: {
    color: colors.TUNDORA,
    fontSize: 16,
    fontWeight: 'bold',
  },
  isDisabledModalText: {
    color: colors.TUNDORA,
    fontSize: 13,
  },
  outer: {
    marginTop: 10,
    flex: 1,
  },
  outerItem: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default HomeNavigator;
