import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  SafeAreaView,
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';

import { colors, images } from '../../../utils';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import Modal from '@ant-design/react-native/lib/modal';

const Order = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'clientOrder/getCurrentPage',
        payload: { name: 'clientOrder' },
      });
    }, [dispatch]),
  );

  const orderListTA = useSelector(
    (state: any) => state.clientOrder.orderListTA,
  );
  const orderListPeriod = useSelector(
    (state: any) => state.clientOrder.orderListPeriod,
  );

  const orderCombo = useSelector((state: any) => state.clientOrder.orderCombo);

  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const fetchData = useCallback(
    (page) => {
      dispatch({
        type: 'clientOrder/getOrders',
        payload: {
          page,
          mode: currentTab,
          keyword: searchKeyword,
        },
      });
    },
    [currentTab, dispatch, searchKeyword],
  );

  const pinArticle = useCallback(
    async (transactionId, pinned) => {
      await dispatch({
        type: 'clientOrder/pinOrder',
        payload: {
          transactionId,
          pinned,
        },
      });
      fetchData(1);
    },
    [dispatch, fetchData],
  );

  const deleteArticle = useCallback(
    (transactionId) => {
      Modal.alert('提示', '确认删除该订单？', [
        {
          text: '取消',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: '确认',
          onPress: async () => {
            await dispatch({
              type: 'clientOrder/removeOrder',
              payload: {
                transactionId,
              },
            });
            fetchData(1);
          },
        },
      ]);
    },
    [dispatch, fetchData],
  );

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentClient',
        payload: {},
      });
    }, [dispatch]),
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <View style={styles.menuSection}>
          <TabItem
            id={0}
            isActive={currentTab === 0}
            setKeyword={setKeyword}
            onPress={setCurrentTab}
          />
          <TabItem
            id={1}
            isActive={currentTab === 1}
            setKeyword={setKeyword}
            onPress={setCurrentTab}
          />
          <TabItem
            id={2}
            isActive={currentTab === 2}
            setKeyword={setKeyword}
            onPress={setCurrentTab}
          />
        </View>
      ),
    });
  }, [currentTab, navigation]);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const renderItem = ({ item }) => {
    const props = {
      id: item.id,
      avatar: item.merchantAvatar,
      mode: item.mode,
      period: item.period,
      tag: item.tag,
      articleId: item.articleId,
      title: item.merchantName,
      desc: item.date,
      content: item.title,
      orderPrice: item.amount,
      updateTime: item.updateTime,
      limitTime: item.limitTime,
      labelNotRefunded: item.refundable === 1,
      labelCongrat: item.result === 2,
      labelRefunded: item.result === 1,
      pinTopArticle: item.pinned,
      redLabel: item.redLabel,
      customLabel: item.customLabel,
      actionFunc: () =>
        dispatch({
          type: 'clientOrder/checkOrderDetail',
          payload: {
            articleId: item.articleId,
            merchantName: item.merchantName,
          },
        }),
      pinFunc: () => pinArticle(item.id, item.pinned ? 0 : 1),
      deleteFunc: () => deleteArticle(item.id),
    };

    return <OrderItem {...props} />;
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.searchSection}>
        <Image source={images.SEARCH_ICON} style={styles.searchIcon} />
        <TextInput
          returnKeyType="search"
          placeholder="搜索内容关键词"
          value={keyword}
          onChangeText={(val) => {
            setKeyword(val);
            if (val === '') {
              setSearchKeyword('');
              dispatch({
                type: 'clientOrder/getOrders',
                payload: {
                  page: 1,
                  mode: currentTab,
                  keyword: '',
                },
              });
            }
          }}
          onSubmitEditing={() => {
            setSearchKeyword(keyword);
            dispatch({
              type: 'clientOrder/getOrders',
              payload: {
                page: 1,
                mode: currentTab,
                keyword,
              },
            });
          }}
          style={styles.searchInput}
        />
      </View>
      <View style={styles.contentSection}>
        {currentTab === 0 && (
          <FlatList
            data={orderListTA.records}
            renderItem={renderItem}
            keyExtractor={(item) => `ta${item.id}`}
            refreshing={false}
            onRefresh={() => fetchData(1)}
            onEndReached={() => {
              if (!orderListTA.isAllLoaded) {
                fetchData(orderListTA.page);
              }
            }}
            ListEmptyComponent={<MEmptyBox title="暂无数据" />}
            ListFooterComponent={
              orderListTA.records.length && orderListTA.isAllLoaded ? (
                <MAllLoadedBox />
              ) : null
            }
            style={styles.paddingTop10}
          />
        )}
        {currentTab === 1 && (
          <FlatList
            data={orderListPeriod.records}
            renderItem={renderItem}
            keyExtractor={(item) => `period${item.id}`}
            refreshing={false}
            onRefresh={() => fetchData(1)}
            onEndReached={() => {
              if (!orderListPeriod.isAllLoaded) {
                fetchData(orderListPeriod.page);
              }
            }}
            ListEmptyComponent={<MEmptyBox title="暂无数据" />}
            ListFooterComponent={
              orderListPeriod.records.length && orderListPeriod.isAllLoaded ? (
                <MAllLoadedBox />
              ) : null
            }
            style={styles.paddingTop10}
          />
        )}
        {currentTab === 2 && (
          <FlatList
            data={orderCombo.records}
            renderItem={renderItem}
            keyExtractor={(item) => `period${item.id}`}
            refreshing={false}
            onRefresh={() => fetchData(1)}
            onEndReached={() => {
              if (!orderCombo.isAllLoaded) {
                fetchData(orderCombo.page);
              }
            }}
            ListEmptyComponent={<MEmptyBox title="暂无数据" />}
            ListFooterComponent={
              orderCombo.records.length && orderCombo.isAllLoaded ? (
                <MAllLoadedBox />
              ) : null
            }
            style={styles.paddingTop10}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  redLabelText: {
    color: colors.WHITE,
    backgroundColor: colors.RED_00,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    paddingRight: 1,
    lineHeight: 13,
    fontWeight: 'bold',
  },
  lianText: {
    color: colors.RED_00,
    backgroundColor: colors.WHITE,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    borderColor: colors.RED_00,
    borderWidth: 1,
  },
  mainContainer: {
    flex: 1,
  },
  menuSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
    backgroundColor: colors.GUARDSMAN_RED,
  },
  activeTab: { alignItems: 'center', justifyContent: 'space-between' },
  activeTabText: { color: colors.WHITE, fontSize: 18, fontWeight: '700' },
  activeTabBottomLine: {
    position: 'absolute',
    bottom: -7,
    height: 2,
    width: 36,
    backgroundColor: colors.WHITE,
  },
  passiveTab: {},
  marginL47: { marginLeft: 47 },
  passiveTabText: { color: colors.WHITE, fontSize: 14 },
  contentSection: { flex: 1 },
  paddingTop10: { paddingTop: 10 },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 8,
    backgroundColor: colors.WHITE,
    height: 35,
    borderRadius: 3,
    paddingLeft: 12,
    paddingRight: 12,
  },
  searchIcon: { width: 14, height: 14, marginRight: 8 },
  searchInput: { flex: 1, color: colors.DUSTY_GRAY, fontSize: 14, padding: 0 },
  customLabel: {
    color: colors.RED_00,
    borderColor: colors.RED_00,
    borderWidth: 1,
    borderRadius: 1,
    fontSize: 10,
    height: 15,
    paddingVertical: 1,
    paddingHorizontal: 2,
    paddingLeft: 3,
  },
  redLabel: {
    flexDirection: 'row',
    marginRight: 6,
    borderRadius: 1,
    height: 15,
  },
});

const orderItemStyles = StyleSheet.create({
  itemBox: {
    marginHorizontal: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 16,
    backgroundColor: colors.WHITE,
    borderRadius: 8,
  },
  itemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  itemHeaderProfile: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemHeaderProfileImage: {
    width: 20,
    height: 20,
    borderRadius: 20,
    marginLeft: -2,
  },
  itemHeaderTitle: { color: colors.TUNDORA, fontSize: 13, marginLeft: 4 },
  itemHeaderDesc: { color: colors.GRAY, fontSize: 12 },
  itemBody: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  itemTag: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
  itemBodyContentSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  itemBodyGuessOrNotImage: { marginTop: 4, width: 16, height: 16 },
  itemBodyContentText: {
    color: colors.GRAY20,
    fontSize: 16,
    marginLeft: 4,
    maxWidth: 240,
    lineHeight: 23,
    fontWeight: '700',
  },
  itemBodyPriceSection: { flexDirection: 'row' },
  itemBodyPriceValue: {
    color: colors.GUARDSMAN_RED,
    fontSize: 20,
    marginRight: 4,
  },
  itemBodyPriceUnit: {
    color: colors.GUARDSMAN_RED,
    fontSize: 10,
    marginTop: 9,
  },
  itemFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemStatusLabelSection: { flexDirection: 'row' },
  itemStatusLabel: { marginRight: 8, width: 52, height: 16 },
  itemActionSection: { flexDirection: 'row', alignItems: 'center' },
  itemActionBtn: {
    width: 64,
    borderColor: colors.GRAY,
    borderRadius: 19,
    borderWidth: StyleSheet.hairlineWidth,
    marginRight: 8,
    height: 28,
    justifyContent: 'center',
  },
  itemActionBtnText: { color: colors.GRAY, fontSize: 12, textAlign: 'center' },
  deleteIcon: { width: 22, height: 19.5 },
  topLeftGuessStatusSection: { position: 'absolute', top: 4 },
  topLeftPeriodSection: {
    backgroundColor: colors.MEDIUM_SLATE_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    padding: 2,
    borderRadius: 4,
  },
  topLeftPeriodText: { color: colors.WHITE, fontSize: 11 },
  topLeftGuessStatusLabel: {
    width: 16,
    height: 16,
  },
  limitTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  limitTimeDate: {
    color: colors.GUARDSMAN_RED,
    marginRight: 3,
  },
  limitTimeText: {
    color: colors.GRAY,
  },
  redLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.RED_00,
    borderWidth: 1,
    marginRight: 6,
    borderRadius: 1,
  },
  customLabel: {
    color: colors.RED_00,
    borderColor: colors.RED_00,
    borderWidth: 1,
    borderRadius: 1,
    fontSize: 12,
  },
});

const OrderItem = (props) => {
  const {
    avatar = '',
    mode = 0,
    period = 0,
    tag = 0,
    articleId = 0,
    title = '',
    content = '',
    orderPrice = 0,
    updateTime = '',
    limitTime = '',
    labelNotRefunded = true,
    labelCongrat = false,
    labelRefunded = true,
    pinTopArticle = false,
    marginBottom = 8,
    actionFunc = () => {},
    pinFunc = () => {},
    deleteFunc = () => {},
    redLabel,
    customLabel,
  } = props;

  const source =
    tag === 0
      ? null
      : tag === 1
      ? images.LABEL_RED
      : tag === 2
      ? images.LABEL_BLACK
      : images.LABEL_GREEN;

  return (
    <TouchableOpacity
      onPress={() => actionFunc(articleId)}
      style={[orderItemStyles.itemBox, { marginBottom }]}>
      <View style={orderItemStyles.itemHeader}>
        <View style={orderItemStyles.itemHeaderProfile}>
          <Image
            source={avatar ? { uri: avatar } : images.LOGO}
            style={orderItemStyles.itemHeaderProfileImage}
          />
          <Text style={orderItemStyles.itemHeaderTitle}>{title}</Text>
        </View>
        <Text style={orderItemStyles.itemHeaderDesc}>
          {dayjs(updateTime).format('YYYY-MM-DD HH:mm')}
        </Text>
      </View>
      <View style={orderItemStyles.itemBody}>
        <View style={orderItemStyles.itemBodyContentSection}>
          <View style={orderItemStyles.topLeftGuessStatusSection}>
            {source && (
              <Image
                source={source}
                style={orderItemStyles.topLeftGuessStatusLabel}
              />
            )}
          </View>
          <Text style={orderItemStyles.itemBodyContentText}>{content}</Text>
        </View>
        <View style={orderItemStyles.itemBodyPriceSection}>
          <Text style={orderItemStyles.itemBodyPriceValue}>{orderPrice}</Text>
          <Text style={orderItemStyles.itemBodyPriceUnit}>元</Text>
        </View>
      </View>
      <View style={orderItemStyles.itemTag}>
        {/* 连红自定义标签 */}
        {redLabel !== null && redLabel !== '' && (
          <>
            <View
              style={{
                width: 2,
                backgroundColor: colors.RED_00,
                marginLeft: 6,
                marginRight: 2,
                height: 15,
              }}
            />
            <View style={styles.redLabel}>
              <Text style={styles.redLabelText}>{redLabel}</Text>
              <Text style={styles.lianText}>连红</Text>
            </View>
          </>
        )}
        {customLabel !== null && customLabel !== '' && (
          <View style={{ marginLeft: 3, height: 15 }}>
            <Text style={styles.customLabel}>{customLabel}</Text>
          </View>
        )}
      </View>
      <View style={orderItemStyles.itemFooter}>
        <View style={orderItemStyles.itemStatusLabelSection}>
          {labelNotRefunded && (
            <Image
              source={images.ORDER_ITEM_LABEL_NOT_REFUNDED}
              style={orderItemStyles.itemStatusLabel}
            />
          )}
          {labelCongrat && (
            <Image
              source={images.ORDER_ITEM_LABEL_CONGRAT}
              style={orderItemStyles.itemStatusLabel}
            />
          )}
          {labelRefunded && (
            <Image
              source={images.ORDER_ITEM_LABEL_REFUNDED}
              style={orderItemStyles.itemStatusLabel}
            />
          )}
          {mode === 1 && period > 0 && (
            <View style={orderItemStyles.limitTimeContainer}>
              <Text style={orderItemStyles.limitTimeDate}>
                {dayjs(limitTime).format('YYYY-MM-DD')}
              </Text>
              <Text style={orderItemStyles.limitTimeText}>到期</Text>
            </View>
          )}
        </View>
        <View style={orderItemStyles.itemActionSection}>
          <TouchableOpacity
            style={orderItemStyles.itemActionBtn}
            onPress={pinFunc}>
            <Text style={orderItemStyles.itemActionBtnText}>
              {pinTopArticle ? '取消置顶' : '置顶'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={deleteFunc}>
            <Image
              source={images.DELETE_ICON}
              style={orderItemStyles.deleteIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const TabItem = (props) => {
  const {
    id = 0,
    isActive = true,
    onPress = () => {},
    setKeyword = () => {},
  } = props;

  return (
    <TouchableOpacity
      key={`menu${id}`}
      onPress={() => {
        onPress(id);
        setKeyword('');
      }}
      style={[
        isActive ? styles.activeTab : styles.passiveTab,
        id === 1 ? styles.marginL47 : {},
        id === 2 ? styles.marginL47 : {},
      ]}>
      {/* <Text style={isActive ? styles.activeTabText : styles.passiveTabText}>
        {id === 0 ? '即时订单' : '包时订单'}
      </Text> */}
      {id === 0 && (
        <Text style={isActive ? styles.activeTabText : styles.passiveTabText}>
          即时订单
        </Text>
      )}
      {id === 1 && (
        <Text style={isActive ? styles.activeTabText : styles.passiveTabText}>
          包时订单
        </Text>
      )}
      {id === 2 && (
        <Text style={isActive ? styles.activeTabText : styles.passiveTabText}>
          套餐订单
        </Text>
      )}
      {isActive && <View style={styles.activeTabBottomLine} />}
    </TouchableOpacity>
  );
};

export default Order;
