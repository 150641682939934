import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import HTML from 'react-native-render-html';

import { colors } from '../../../utils';

const PrivacyAgreementScreen = () => {
  const dispatch = useDispatch();

  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { ysxy: '' };

  useEffect(() => {
    dispatch({
      type: 'agreement/getByCode',
      payload: { code: 'ysxy' },
    });
  }, [dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contentContainer}>
        {agreementsByCode.ysxy?.content !== '' && (
          <HTML source={{ html: agreementsByCode.ysxy?.content ?? '' }} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});

export default PrivacyAgreementScreen;
