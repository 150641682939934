export const PRIVACY_KEY = 'agreed';
export const AGREED_PRIVACY = 'true';
import { getHistoryMessage } from '../services/client/merchant';
import { getHistoryMessage as clienHistory} from '../services/client/client'
import { getClientMessage ,getResultMessage} from '../services/client/client';
import Toast from '@ant-design/react-native/lib/toast';

const SET_ALL_HISTORY = 'SET_ALL_HISTORY';
const SET_CLIENT_HISTORY = 'SET_CLIENT_HISTORY';
const SET_ALL_TERRECE = 'SET_ALL_TERRECE';
const SET_ALL_CLIENTTERRECE = 'SET_ALL_CLIENTTERRECE';

export default {
  namespace: 'message',
  state: {
    getHistoryMessageList: [],
    history: [],
    clienHistory: [],
    online: [],
    unreadNumber: [],
    platform: [],
    terrace: {
      fotUserType: '',
      headUrl: '',
      isOnline: 0,
      nickname: '码上来料客服',
      userId: '0000',
      recods: [],
    },
    clientTerrace: {
      fotUserType: '',
      headUrl: '',
      isOnline: 0,
      nickname: '码上来料客服',
      userId: '0000',
      recods: [],
    },
  },
  effects: {
    *getHistoryMessage({ payload }, { put, call }) {
      const res = yield call(getHistoryMessage, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_HISTORY,
          payload: {
            page: payload.currentPage,
            result: res.data,
          },
        });
      }
    },
    *getClientMessage({ payload }, { put, call }) {
      const res = yield call(getClientMessage, payload);
      if (res.success) {
        yield put({
          type: SET_CLIENT_HISTORY,
          payload: {
            page: payload.currentPage,
            result: res.data,
          },
        });
      }
    },
    *getTerrace({ payload }, { put, call }) {
      Toast.loading('加载中...', 0);
      const res = yield call(getHistoryMessage, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_TERRECE,
          payload: {
            page: payload.currentPage,
            result: res.data,
          },
        });
      }
    },
    *getClientTerrace({ payload }, { put, call }) {
      const res = yield call(clienHistory, payload);
      if (res.success) {
        yield put({
          type: SET_ALL_CLIENTTERRECE,
          payload: {
            page: payload.currentPage,
            result: res.data,
          },
        });
      }
    },
    async *history({ payload }, { put }) {
      yield put({
        type: 'HISTORY',
        payload: payload,
      });
    },
  },
  reducers: {
    SET_ALL_HISTORY(state, { payload }) {
      if (payload.result) {
        state.getHistoryMessageList = payload.result || [];
      } else {
        state.getHistoryMessageList.push(payload);
      }
    },
    SET_ALL_TERRECE(state, { payload }) {
      if (payload.result) {
        state.terrace.recods = payload.result || [];
      }
    },
    SET_ALL_CLIENTTERRECE(state, { payload }) {
      if (payload.result) {
        state.clientTerrace.recods = payload.result || [];
      }
    },
    SET_CLIENT_HISTORY(state, { payload }) {
      if (payload.result) {
        state.getHistoryMessageList = payload.result || [];
      } else {
        state.getHistoryMessageList.push(payload);
      }
    },
    HISTORY(state, { payload }) {
      // state.history.push(payload.obj);
      updateMessageList(state, payload);
    },
    CLIENHISTORY(state, { payload }) {
      // state.history.push(payload.obj);
      updateMessageList1(state, payload);
    },
    ONLINE(state, { payload }) {},
    UNREADNUMBER(state, { payload }) {
      let obj;
      if (state.unreadNumber.length == 0 && payload.res.isRead == 0) {
        obj = {
          userId: payload.res.fromUserId,
          count: state.unreadNumber[0]
            ? state.unreadNumber[0].count++
            : parseInt(payload.res.isRead) + 1,
        };
        state.unreadNumber.push(obj);
      } else {
        state.unreadNumber.map((i, index) => {
          if (i.userId == payload.res.fromUserId) {
            state.unreadNumber[index].count += 1;
          }
        });
      }

      console.log(state.unreadNumber);
    },
    PLATFORM(state, { payload }) {
      state.terrace.recods.push(payload.res);
    },
    CLEAN(state, { payload }) {
      console.log(payload, '参数');
      state.clientTerrace.recods.push(payload.res);
    },
  },
};

function updateMessageList(state, payload) {
  if (payload.res.type != 1) {
    state.history = payload.res;
  }

  if (state.history.length > 0) {
    let list = JSON.parse(JSON.stringify(state.history));
    list.forEach((v, index) => {
      if (v.userId == payload.res.userId) {
        state.history[index].record.push(payload.res.record);
      }
    });
  }
}

function updateMessageList1(state, payload) {
  if (payload.res.type != 1) {
    state.clienHistory = payload.res;
  }
  if (state.clienHistory.length > 0) {
    let list = JSON.parse(JSON.stringify(state.clienHistory));
    list.forEach((v, index) => {
      if (v.userId == payload.res.userId) {
        state.clienHistory[index].record.push(payload.res.record);
      }
    });
  }
}
