/*
 * @Author: lizhengkai
 * @Date: 2022-04-13 19:57:34
 * @FilePath: /msll-client/src/screens/Client/MyPage/ValidationSuccess.tsx
 */
import React, { useEffect } from 'react';
import {
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { colors, images } from '../../../utils';
import { useDispatch } from 'react-redux';

const ValidationSuccess = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch({ type: 'merchantArticle/releaseCreateStatus' });
  // }, [dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.successImageContainer}>
        <Image source={images.SUCCESS} style={styles.successImage} />
        <Text style={styles.successImageText}>添加成功</Text>
      </View>
      <View style={styles.submitBtnContainer}>
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate('RealnameVerify');
          }}
          style={styles.submitBtn}>
          <Text style={styles.submitBtnText}>{'完  成'}</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  successImageContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 45,
  },
  successImage: {
    width: 179,
    height: 150,
  },
  successImageText: {
    color: colors.TUNDORA,
    fontSize: 18,
    fontWeight: '700',
    marginTop: 12,
  },
  submitBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    flexDirection: 'row',
    backgroundColor: colors.GUARDSMAN_RED,
    width: 160,
    height: 50,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    fontWeight: '700',
  },
});

export default ValidationSuccess;
