/*
 * @Description: 推广商家
 * @Author: lizhengkai
 * @Date: 2022-04-13 17:01:39
 * @FilePath: /msll_h5/src/screens/Client/MyPage/Extension.tsx
 */
import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  SafeAreaView,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  View,
  TextInput,
  Image,
} from 'react-native';
import Modal from '@ant-design/react-native/lib/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { colors, images } from '../../../utils';
import { MAllLoadedBox, MEmptyBox, ExtensionModal } from '../../../components';

const Extension = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const promotionMerchant = useSelector(
    (state: any) => state.client.promotionMerchant,
  );
  const currentClient = useSelector((state: any) => state.client.currentClient);
  const qrCodeUrl = useSelector((state: any) => state.client.qrCodeUrl);

  const bankCard = useSelector((state: any) => state.client.bankCard);

  const [isAlertVerifyModalVisible, setIsAlertVerifyModalVisible] =
    useState(false);
  const [wechatModalVisible, setWechatModalVisible] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  const [keyword, setKeyword] = useState('');
  const fetchData = useCallback(
    (page) => {
      dispatch({
        type: 'client/getPromotionMerchants',
        payload: {
          currentPage: page,
          pageSize: 10,
          name: '',
        },
      });
    },
    [dispatch],
  );
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={styles.menuRight}
          onPress={() => navigation.navigate('Rule')}>
          <Text style={styles.menuRightText}>活动说明</Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    dispatch({
      type: 'client/getPromotionMerchants',
      payload: {
        currentPage: 1,
        pageSize: 10,
        name: '',
      },
    });
    dispatch({
      type: 'client/getUserBindBank',
      payload: {},
    });
  }, [dispatch]);

  const userClick = (item) => {
    dispatch({
      type: 'client/getMerchantsQRCode',
      payload: {
        merchantId: item.id,
      },
    });
    setAvatar(item.avatar ? { uri: item.avatar } : images.DEFAULT_AVATAR);
    setName(item.name);
    currentClient.adapayRealnameMemberFlag && bankCard
      ? setWechatModalVisible(true)
      : setIsAlertVerifyModalVisible(true);
  };

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity
        style={styles.merchantItemMain}
        onPress={() => userClick(item)}>
        <View style={styles.merchantItemContentSection}>
          <View>
            <Image
              source={
                item.avatar ? { uri: item.avatar } : images.DEFAULT_AVATAR
              }
              style={styles.merchantItemProfileImage}
            />
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{item.name}</Text>
            <Text style={styles.merchantItemDesc}>{item.intro}</Text>
          </View>
        </View>
        <View>
          <Text style={{ color: colors.RED_00 }}>
            提成{item.promotionRatio * 100}%
          </Text>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
      <ExtensionModal
        visible={wechatModalVisible}
        avatar={avatar}
        name={name}
        customerQr={qrCodeUrl}
        onClose={() => setWechatModalVisible(false)}
      />
    </>
  );
  const AlertVerifyModal = (props) => {
    const { onConfirm = () => {}, onClose = () => {} } = props;

    return (
      <Modal visible={true} transparent onClose={onClose}>
        <View>
          <Text style={stylesModal.title}>提示</Text>
          <Text style={stylesModal.description}>
            亲爱的用户，参与拉新活动请先完成实名认证并绑定银行卡。
          </Text>
        </View>
        <TouchableOpacity style={stylesModal.btn} onPress={onConfirm}>
          <Text style={stylesModal.btnText}>去认证</Text>
        </TouchableOpacity>
      </Modal>
    );
  };
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.search}>
        <TextInput
          returnKeyType="search"
          placeholder="请输入姓名搜索"
          value={keyword}
          onChangeText={(val) => {
            setKeyword(val);
            if (val === '') {
              dispatch({
                type: 'client/getPromotionMerchants',
                payload: {
                  currentPage: 1,
                  pageSize: 10,
                  name: keyword,
                },
              });
            }
          }}
          onSubmitEditing={() => {
            dispatch({
              type: 'client/getPromotionMerchants',
              payload: {
                currentPage: 1,
                pageSize: 10,
                name: keyword,
              },
            });
          }}
          style={styles.inputStyle}
        />
        <TouchableOpacity
          style={styles.btnStyle}
          onPress={() => {
            dispatch({
              type: 'client/getPromotionMerchants',
              payload: {
                currentPage: 1,
                pageSize: 10,
                name: keyword,
              },
            });
          }}>
          <Text style={styles.searchText}>搜索</Text>
        </TouchableOpacity>
      </View>

      <FlatList
        data={promotionMerchant.records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        refreshing={false}
        onRefresh={() => fetchData(1)}
        onEndReached={() => {
          if (!promotionMerchant.isAllLoaded) {
            fetchData(promotionMerchant.page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无数据" />}
        ListFooterComponent={
          promotionMerchant.records.length && promotionMerchant.isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
      {isAlertVerifyModalVisible && (
        <AlertVerifyModal
          onConfirm={async () => {
            setIsAlertVerifyModalVisible(false);
            navigation.navigate('RealnameVerify');
          }}
          onClose={() => setIsAlertVerifyModalVisible(false)}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
    // flex: 1,
    // justifyContent: 'flex-start',
    // alignItems: 'center',
  },
  menuRight: {
    marginRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuRightText: { color: colors.WHITE, fontSize: 15 },
  header: {
    backgroundColor: '#ffffff',
  },
  searchBarContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  search: {
    width: '100%',
    height: 32,
    marginTop: 8,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: 0,
    padding: 0,
  },
  inputStyle: {
    flex: 8,
    height: 32,
    fontSize: 16,
    borderColor: 'rgba(0,0,0,.2)',
    borderWidth: 1,
    paddingVertical: 0,
    padding: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginLeft: 10,
    paddingLeft: 10,
  },
  searchText: {
    fontSize: 16,
    color: colors.WHITE,
  },
  btnStyle: {
    flex: 2,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    backgroundColor: colors.RED_00,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  searchBar: {
    backgroundColor: colors.WILD_SAND,
    paddingLeft: 34,
    height: 32,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 4,
  },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 8,
  },
  searchInput: {
    color: colors.DUSTY_GRAY,
    height: 32,
    flexDirection: 'row', // 水平排布
    justifyContent: 'center',
    flex: 1,
    borderWidth: 1,
    borderRadius: 3, // 设置圆角边
    alignItems: 'center',
  },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemProfileImage: { width: 60, height: 60, borderRadius: 60 },
  merchantItemNoticeNumberSection: {
    position: 'absolute',
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    backgroundColor: colors.RED_ORANGE,
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    top: -3.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 4,
  },
  merchantItemDesc: {
    flex: 1,
    color: colors.GRAY,
    fontSize: 16,
    lineHeight: 16,
    textAlign: 'justify',
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
});
const stylesModal = StyleSheet.create({
  title: {
    color: colors.BLACK,
    fontSize: 17,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 16,
  },
  description: {
    color: colors.TUNDORA,
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'justify',
  },
  btn: {
    width: 160,
    height: 47,
    marginTop: 20,
    borderRadius: 7.5,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  btnText: { color: colors.WHITE, fontSize: 18 },
});

export default Extension;
