/*
 * @Description:
 * @Author: Evan huang
 * @Date: 2023-12-05 17:14:02
 * @LastEditors: Eave Luo
 * @LastEditTime: 2024-04-04 16:09:41
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: \msll_h5\src\screens\DiscountCard\DiscountCardItem.tsx
 */

import dayjs from 'dayjs';
import React from 'react';
import {
  View,
  ImageBackground,
  Text,
  StyleSheet,
  Pressable,
} from 'react-native';
import { colors, images } from '../../../../utils';

const COUPON_TYPE = [
  { value: 1, label: '次卡' },
  { value: 2, label: '折扣卡' },
  { value: 3, label: '会员卡' },
];

const DiscountCardItem = ({ item, from = '', onChoose = undefined }: any) => {
  return onChoose ? (
    <Pressable style={styles.itemView} onPress={() => onChoose(item)}>
      <DiscountCardItemView from={from} item={item} />
    </Pressable>
  ) : (
    <View style={styles.itemView}>
      <DiscountCardItemView from={from} item={item} />
    </View>
  );
};

const DiscountCardItemView = ({ item, from }) => {
  const showType = from !== 'home' ? item.cardType : item.type;
  const showName = from !== 'home' ? item.cardName : item.name;
  const showValue =
    from !== 'home'
      ? showType === 1
        ? item.cardValue - item.useCardValue
        : item.cardValue
      : item.value;
  const showMaxDeductionPrice =
    item.maxDeductionPrice === 0 ? '无限制' : item.maxDeductionPrice + '元';
  return (
    <ImageBackground
      source={
        showType === 1
          ? images.DiscountCard_TYPE1_BG
          : showType === 2
          ? images.DiscountCard_TYPE2_BG
          : images.DiscountCard_TYPE3_BG
      }
      style={[styles.itemBackgroud, from !== 'home' && { borderRadius: 8 }]}>
      <View
        style={[styles.itemBackgroud, from !== 'home' && { borderRadius: 8 }]}>
        <View style={styles.itemPriceSection}>
          <Text style={styles.itemPrice}>
            ￥<Text style={styles.itemPriceNum}>{item.price}</Text>
          </Text>
        </View>
        <View style={styles.itemContent}>
          <Text style={styles.itemName}>{showName}</Text>
          <View style={styles.itemTextSection}>
            <Text style={styles.itemText}>
              类型:{COUPON_TYPE.find((type) => type.value === showType)?.label}
            </Text>
            <Text style={styles.itemText}>
              {showType === 1
                ? from !== 'home'
                  ? '剩余次数:'
                  : '次数:'
                : '折扣:'}
              {showValue}
              {showType === 1 ? '次' : '折'}
            </Text>
            <Text style={styles.itemText}>
              {showType === 1 && '最高抵扣金额:' + showMaxDeductionPrice}
            </Text>
          </View>
          {from !== 'home' ? (
            <View style={styles.itemTextSection}>
              <Text style={styles.itemText}>到期时间:{item.endTime}</Text>
            </View>
          ) : (
            <View style={styles.itemTextSection}>
              <Text style={styles.itemText}>有效天数:{item.days}天</Text>
              <Text style={styles.itemText}>时间节点:{item.hour}时</Text>
              <Text style={styles.itemText} />
            </View>
          )}
          {item.giftCouponId && (
            <View style={styles.itemTextSection}>
              <Text style={styles.itemText}>
                赠送优惠券:{item.giftCouponName}
              </Text>
            </View>
          )}
          {item.remark !== '' && (
            // 当备注字数超出一行时，会自动显示省略号
            <View style={styles.itemTextSection}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.itemText}>
                备注:{item.remark}
              </Text>
            </View>
          )}
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  itemView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: 10,
  },
  itemBackgroud: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  itemPriceSection: {
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemPrice: {
    fontSize: 12,
    color: colors.WHITE,
  },
  itemPriceNum: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  itemContent: {
    flex: 1,
    paddingRight: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingVertical: 10,
  },
  itemName: {
    color: colors.WHITE,
    fontSize: 16,
    fontWeight: 'bold',
  },
  itemTextSection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemText: {
    color: colors.WHITE,
    fontSize: 12,
    marginTop: 10,
  },
});

export default DiscountCardItem;
