/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
import { colors, images } from '../../../utils';
import { useNavigation } from '@react-navigation/native';
import { getApplieTotal } from '../../../services/client/client';
import Toast from '@ant-design/react-native/lib/toast';

const ApplySubmitWin = () => {
  const navigation = useNavigation();

  const wx = useSelector((state: any) => state.parameter.wx);

  const kf = useSelector((state: any) => state.parameter.kf);

  const [applyTotal, setApplyTotal] = useState(0);

  useEffect(() => {
    //获取商家申请数量
    const getApplyTotal = async () => {
      const { success, data, msg } = await getApplieTotal();
      if (success) {
        setApplyTotal(data);
      } else {
        Toast.fail('商家申请数获取失败:' + msg);
      }
    };
    getApplyTotal();
  });

  return (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView contentContainerStyle={styles.contentSection}>
        <Image
          source={images.MERCHANT_APPLY_SUCCESS}
          style={styles.successImage}
        />
        <Text style={styles.contentDesc}>
          您已申请{applyTotal}个商家，可退出当前账号登录“商家身份！
        </Text>
        <TouchableOpacity
          style={styles.submitBtn}
          onPress={() => navigation.replace('MerchantApply')}>
          <Text style={styles.submitBtnText}>申请新店铺</Text>
        </TouchableOpacity>
        <View style={styles.bottomSection}>
          <Image
            source={{ uri: kf.itemValue }}
            style={[
              styles.qrcodeImage,
              {
                width: 100,
                height: 100,
              },
            ]}
          />
          <View style={{ marginLeft: 20 }}>
            <View style={styles.wechatDescSection}>
              <Text style={styles.wechatDesc}>有任何问题可咨询客服哦~~</Text>
            </View>
            <View style={styles.wechatBusinessNumberSection}>
              <Text style={styles.businessNumberDesc}>平台客服微信：</Text>
              <Text style={styles.businessNumberVal}>{wx.name}</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    height: 600,
  },
  successImage: { width: 150, height: 145, marginBottom: 20 },
  contentSection: { flex: 1, marginTop: 30, alignItems: 'center' },
  contentTitle: {
    flex: 1,
    color: colors.TUNDORA,
    fontSize: 18,
    marginTop: 2,
    fontWeight: '700',
  },
  contentDesc: {
    color: colors.TUNDORA,
    fontSize: 14,
    marginTop: 10,
    maxWidth: 260,
    textAlign: 'center',
  },
  submitBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 7.5,
    marginTop: 36.5,
  },
  submitBtnText: { color: colors.WHITE, fontSize: 18 },

  bottomSection: {
    flexDirection: 'row',
    marginTop: 100,
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },

  qrcodeImage: {
    marginTop: 30,
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wechatImage: { width: 240, height: 240 },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
});

export default ApplySubmitWin;
