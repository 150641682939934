/*
 * @Description: 我的优惠券
 * @Author: Evan huang
 * @Date: 2023-11-30 21:20:27
 * @LastEditors: Eave Luo
 * @LastEditTime: 2024-03-13 03:16:19
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: \msll_h5\src\screens\Client\MyPage\Coupon\index.tsx
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { colors } from '../../../../utils';
import { MAllLoadedBox, MEmptyBox } from '../../../../components';
import { View } from 'react-native';
import CouponItem, { TabType } from './CouponItem';

const Coupon = ({ isFromOrder = false, articleId = undefined, onChoose }) => {
  const dispatch = useDispatch();
  const [tabType, setTabType] = useState(TabType[0]);

  const { myCoupons, availableCoupons } = useSelector(
    (state: any) => state.client,
  );

  const fetchData = useCallback(
    (page) => {
      if (articleId) {
        //获取可用优惠券
        dispatch({
          type: 'client/getAvailableCoupons',
          payload: { articleId },
        });
      } else {
        dispatch({
          type: 'client/getMyCoupons',
          payload: {
            currentPage: page,
            pageSize: 10,
            type: tabType.id,
          },
        });
      }
    },
    [articleId, dispatch, tabType.id],
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData, tabType, articleId]);

  //FlatList单个对象渲染，单个优惠券
  //在优惠券背景图片内采用一行左右布局，左边是优惠券的价格(红色字体，钱符号字体大小12，金额字体大小18)，右边是上下布局，名称在上(黑色强调字体)，有效期在下(灰色字体)
  const renderItem = ({ item }) => (
    <CouponItem item={item} onChoose={onChoose} />
  );

  const TabItem = (props) => {
    const { item, currentTab, goFunc = () => {} } = props;

    return (
      <TouchableOpacity
        style={tabItemStyles.tabItemSection}
        onPress={() => goFunc(item)}>
        <Text
          style={
            currentTab.id === item.id
              ? tabItemStyles.activeItem
              : tabItemStyles.passiveItem
          }>
          {item.name}
        </Text>
        {currentTab.id === item.id && (
          <View style={tabItemStyles.activeItemBottomLine} />
        )}
      </TouchableOpacity>
    );
  };

  //我的优惠券Tab(分为：有效，过期，已使用三类)进行切换过滤
  const headerView = () => {
    return (
      <View style={styles.headerRow}>
        {TabType.map((item) => (
          <TabItem
            key={item.id}
            item={item}
            currentTab={tabType}
            goFunc={setTabType}
          />
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      {!isFromOrder && headerView()}
      <FlatList
        data={articleId ? availableCoupons : myCoupons?.records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        onRefresh={() => fetchData(1)}
        refreshing={false}
        onEndReached={() => {
          if (!articleId && !myCoupons.isAllLoaded) {
            fetchData(myCoupons.page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无优惠券" />}
        ListFooterComponent={
          (myCoupons?.records.length && myCoupons.isAllLoaded) || articleId ? (
            <MAllLoadedBox />
          ) : null
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: '#F5F6F6',
  },
  headerRow: {
    height: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 20,
  },
});

const tabItemStyles = StyleSheet.create({
  tabItemSection: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeItem: {
    color: colors.TUNDORA,
    fontSize: 14,
    fontWeight: '700',
  },
  passiveItem: {
    color: colors.TUNDORA,
    fontSize: 14,
  },
  activeItemBottomLine: {
    position: 'absolute',
    bottom: 0,
    height: 3,
    width: 16,
    backgroundColor: colors.GUARDSMAN_RED,
  },
});

export default Coupon;
