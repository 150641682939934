import { PermissionsAndroid } from 'react-native';

const requestCameraPermission = async () => {
  try {
    await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA);
  } catch (err) {
    console.error(err);
  }
};

const requestWriteExternalStoragePermission = async () => {
  try {
    await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    );
  } catch (err) {
    console.error(err);
  }
};

export default {
  requestCameraPermission,
  requestWriteExternalStoragePermission,
};
