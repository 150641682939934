import React, { useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getByCode } from '../../../services/open/agreement'
import { colors, images } from '../../../utils';

const ApplySubmitSuccess = () => {
  const dispatch = useDispatch();

  const { width } = Dimensions.get('window');

  const wx = useSelector((state: any) =>
    state.parameter.wx
  );


  const kf = useSelector((state: any) =>
    state.parameter.kf
  );

  console.log(wx, kf);




  useEffect(() => {
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'platform_support_qrcode',
      },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'platform_support_wechat',
      },
    });
  }, [dispatch]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView contentContainerStyle={styles.contentSection}>
        <Image
          source={images.MERCHANT_APPLY_SUCCESS}
          style={styles.successImage}
        />
        <Text style={styles.contentDesc}>
          您已提交商家申请，请即刻截图扫码下方二维码添加平台客服，加速完成人工审核！
        </Text>
        <Text style={styles.contentDesc}>或与平台客服联系了解最新进度。</Text>
        <Image
          source={{ uri: kf.itemValue }}
          style={[
            styles.qrcodeImage,
            {
              width: width - 100,
              height: width - 100,
            },
          ]}
        />
        <View style={styles.wechatDescSection}>
          <Text style={styles.wechatDesc}>请用微信扫描二维码</Text>
        </View>
        <View style={styles.wechatBusinessNumberSection}>
          <Text style={styles.businessNumberDesc}>添加平台客服微信：</Text>
          <Text style={styles.businessNumberVal}>{wx.itemValue}</Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  successImage: { width: 150, height: 145, marginBottom: 20 },
  contentSection: { marginTop: 30, alignItems: 'center' },
  contentTitle: {
    color: colors.TUNDORA,
    fontSize: 18,
    marginTop: 2,
    fontWeight: '700',
  },
  contentDesc: {
    color: colors.TUNDORA,
    fontSize: 14,
    marginTop: 10,
    maxWidth: 260,
    textAlign: 'center',
  },
  submitBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 7.5,
    marginTop: 36.5,
  },
  submitBtnText: { color: colors.WHITE, fontSize: 18 },
  qrcodeImage: {
    marginTop: 30,
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wechatImage: { width: 240, height: 240 },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
});

export default ApplySubmitSuccess;
