import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import HTML from 'react-native-render-html';

import { colors } from '../../../utils';
import { useFocusEffect } from '@react-navigation/native';

const Agreement = () => {
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentPage',
        payload: { name: 'Agreement' },
      });
    }, [dispatch]),
  );

  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { czhgmfwxy: '' };

  useEffect(() => {
    dispatch({
      type: 'agreement/getByCode',
      payload: { code: 'czhgmfwxy' },
    });
  }, [dispatch]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contentContainer}>
        {agreementsByCode.yhxy?.content !== '' && (
          <HTML source={{ html: agreementsByCode.czhgmfwxy?.content ?? '' }} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});

export default Agreement;
