/*
 * @Author: Evan huang
 * @LastEditors: Eave Luo
 * @Description:
 */
import { request } from '../../utils';

export function getCurrentMerchant(params) {
  return request.get(`/api/client/merchants/${params.merchantId}`);
}

export function getMerchantList(params) {
  return request.get('/api/client/merchants/list', params, false);
}

export function getAllMerchantList(params) {
  return request.get('/api/client/merchants/list/all', params, false);
}

export function getMerchantArticles(params) {
  return request.get(
    `/api/client/articles/${params.merchantId}/${params.mode}`,
    params,
    false,
  );
}

export function getOrderArticle(params) {
  return request.get(`/api/client/articles/show/${params.articleId}`);
}

export function purchaseArticle(params) {
  return request.get(`/api/client/articles/order/${params.articleId}`);
}

export function followMerchant(params) {
  return request.get(`/api/client/merchants/follow/${params.merchantId}`);
}

export function unfollowMerchant(params) {
  return request.get(`/api/client/merchants/unfollow/${params.merchantId}`);
}
export function sendMerchant(params) {
  return request.get(`/api/client/merchants/send/${params.merchantId}`);
}

//查询所有好友列表信息
export function getResultMessage(params: any) {
  return request.get(
    '/api/merchant/webSocketMerchant/getResultMessage',
    params,
    false,
  );
}

//查询好友历史聊天信息
export function getHistoryMessage(params: any) {
  return request.get(
    '/api/merchant/webSocketMerchant/getHistoryMessage',
    params,
    false,
  );
}

/**获取合并码内的商户信息*/
export function getMergeCodeInfo(params: any) {
  return request.get('/api/client/merchants/getMerchantList/' + params.id);
}
