/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: lizhengkai
 * @Date: 2022-04-13 18:08:40
 * @FilePath: /msll-client/src/screens/Client/MyPage/RealnameVerify.tsx
 */
import Toast from '@ant-design/react-native/lib/toast';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../utils';
import Steps from '../../../components/Steps';

const RealnameVerify = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const currentClient = useSelector((state: any) => state.client.currentClient);
  const bankCard = useSelector((state: any) => state.client.bankCard);
  const [localRealName, setLocalRealName] = useState(
    currentClient.adapayRealnameMemberFlag ? currentClient.realName : '',
  );
  const [localIdCardNumber, setLocalIdCardNumber] = useState(
    currentClient.adapayRealnameMemberFlag ? currentClient.idCardNumber : '',
  );

  const bingBank = useCallback(() => {
    if (bankCard) {
      navigation.navigate('CardNumber', { cardId: bankCard });
    } else {
      if (currentClient.adapayRealnameMemberFlag) {
        navigation.navigate('BankCard');
      } else {
        Toast.info('绑定银行卡前，请先把姓名+身份证输入后点击保存');
      }
    }
  }, [bankCard, currentClient.adapayRealnameMemberFlag, navigation]);

  const handleSubmit = useCallback(async () => {
    if (!currentClient.adapayRealnameMemberFlag) {
      dispatch({
        type: 'clientClient/realNameIdentity',
        payload: {
          realName: localRealName,
          idCardNumber: localIdCardNumber,
        },
      });
    } else {
      bingBank();
    }
  }, [
    bingBank,
    currentClient.adapayRealnameMemberFlag,
    dispatch,
    localIdCardNumber,
    localRealName,
  ]);
  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getUserBindBank',
        payload: {},
      });
    }, [dispatch]),
  );

  return (
    <SafeAreaView style={styles.container}>
      <Steps currentIndex={0} />
      <View style={styles.mainContainer}>
        <SettingRowCustom
          title="姓名"
          keyboardType="default"
          desc={localRealName}
          onChange={setLocalRealName}
        />
        <SettingRowCustom
          title="身份证号"
          keyboardType="default"
          desc={localIdCardNumber}
          onChange={setLocalIdCardNumber}
        />
        <Text style={[styles.confirm, { marginTop: 10 }]}>
          绑定银行卡前，请先把姓名+身份证输入后点击保存
        </Text>
        <Text style={styles.confirm}>
          请确保实名认证和银行卡为同一用户，否则绑定失败！
        </Text>
        <View style={styles.marginTop12}>
          <SettingRowCustom
            title="银行卡"
            hasRightArrow={false}
            descColor={colors.GRAY}
            marginLeft={1.5}
            marginRight={-1}
            isBind={true}
            bankCard={bankCard}
            isRealName={currentClient.adapayRealnameMemberFlag}
          />
        </View>
        <TouchableOpacity onPress={handleSubmit} style={styles.saveBtn}>
          <Text style={styles.saveBtnText}>
            {currentClient.adapayRealnameMemberFlag
              ? bankCard
                ? '更换银行卡'
                : '下一步'
              : '保 存'}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const SettingRowCustom = (props) => {
  const {
    title = '',
    desc = '',
    marginRight = -5,
    marginLeft = 1.5,
    keyboardType = 'numeric',
    onChange = () => {},
    isBind = false,
    descColor = colors.TUNDORA,
    bankCard,
    isRealName = false,
  } = props;
  return !isBind ? (
    !isRealName ? (
      <View style={styles.settingRowBigSection}>
        <View style={styles.settingRowBigInnerSection}>
          <Text style={styles.settingRowBigTitleText}>{title}</Text>
          <View
            style={[
              styles.settingRowBigRightSection,
              { marginRight, marginLeft },
            ]}>
            <TextInput
              returnKeyType="done"
              value={desc}
              onChangeText={(newValue) => onChange(newValue)}
              keyboardType={keyboardType}
              style={styles.textInput}
            />
          </View>
        </View>
      </View>
    ) : (
      <TouchableOpacity style={styles.settingRowBigSection}>
        <View style={styles.settingRowBigInnerSection}>
          <Text style={styles.settingRowBigTitleText}>{title}</Text>
          <View
            style={[
              styles.settingRowBigRightSection,
              { marginRight, marginLeft },
            ]}>
            {desc.length > 0 && (
              <Text
                style={[styles.settingRowBigDescText, { color: descColor }]}>
                {desc}
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    )
  ) : (
    <TouchableOpacity style={styles.settingRowBigSection}>
      <View style={styles.settingRowBigInnerSection}>
        <Text style={styles.settingRowBigTitleText}>{title}</Text>
        <View
          style={[
            styles.settingRowBigRightSection,
            { marginRight, marginLeft },
          ]}>
          <View>
            <Text style={styles.textInput}>
              {bankCard ? '已绑定' : '未绑定'}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  mainContainer: { flex: 1 },
  settingRowBigSection: {
    width: '100%',
    paddingHorizontal: 21,
    backgroundColor: colors.WHITE,
    height: 50,
  },
  settingRowBigInnerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    borderBottomColor: colors.MERCURY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  settingRowBigTitleText: { color: colors.TUNDORA, fontSize: 14 },
  settingRowBigRightImage: { width: 45, height: 45 },
  settingRowBigRightSection: { flexDirection: 'row', alignItems: 'center' },
  settingRowBigDescText: { fontSize: 14 },
  settingRowBigRightArrowImage: { marginLeft: 9, width: 8, height: 13 },
  marginTop12: { marginTop: 12 },
  textInput: { minWidth: 200, textAlign: 'right', color: colors.TUNDORA },
  saveBtn: {
    width: 160,
    height: 47,
    borderRadius: 7.5,
    marginTop: 88,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  saveBtnText: { color: colors.WHITE, fontSize: 18 },
  confirm: {
    color: colors.RED_00,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});

export default RealnameVerify;
