/*
 * @Author: Eave Luo
 * @Date: 2021-08-26 14:23:37
 * @LastEditors: Eave Luo
 * @LastEditTime: 2021-08-27 16:48:24
 * @Description: file content
 */
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { colors, images } from '../utils';

const MEmptyBox = ({ title = '', followingStatus = null }) => {
  return (
    <View style={styles.noDataSection}>
      <Image source={images.NO_DATA} style={styles.noDataImage} />
      {followingStatus !== 1 ? (
        <Text style={styles.noDataText}>{title}</Text>
      ) : (
        <>
          <Text style={styles.noDataText}>
            您已被作者屏蔽，暂不显示任何内容！
          </Text>
          <Text style={styles.noDataTextRed}>
            温馨提示：点击右上角“+”，选择您关注的其他店铺
          </Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  noDataSection: {
    alignItems: 'center',
    marginBottom: 27,
  },
  noDataImage: { width: 235, height: 160, marginTop: 80 },
  noDataText: { color: colors.DUSTY_GRAY, fontSize: 13, marginTop: 8 },
  noDataTextRed: { color: colors.GUARDSMAN_RED, fontSize: 13, marginTop: 8 },
});

export default MEmptyBox;
