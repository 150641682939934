/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { Image, StyleSheet, TextInput, View } from 'react-native';
import { TextInputProps } from '@ant-design/react-native/lib/textarea-item';
import image from '../utils/image';
import _ from 'lodash';

type SearchBarPropsType = PropsWithoutRef<{
  debounceTimeout?: number;
  onDebounce?: (val: string | undefined) => void;
}> &
  TextInputProps;

const SearchBar = ({
  debounceTimeout = 1000,
  onDebounce,
  ...rest
}: SearchBarPropsType) => {
  const [debouncedValue, setDebouncedValue] = useState(rest.value);

  const updateDebouncedValue = useMemo(() => {
    return debounceTimeout
      ? _.debounce(
          (value: string | undefined) => setDebouncedValue(value),
          debounceTimeout,
        )
      : setDebouncedValue;
  }, [debounceTimeout]);

  useEffect(() => {
    if (onDebounce) {
      onDebounce(debouncedValue);
    }
  }, [debouncedValue, onDebounce]);

  useEffect(() => {
    updateDebouncedValue(rest.value);
  }, [rest.value, updateDebouncedValue]);

  return (
    <View style={styles.container}>
      <Image source={image.SEARCH_ICON} style={styles.icon} />
      <TextInput
        returnKeyType="search"
        placeholder="搜索内容关键词"
        placeholderTextColor="#999999"
        style={styles.input}
        {...rest}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 12.5,
    paddingVertical: 7.5,
    backgroundColor: '#f5f5f5',
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: 6,
  },
  input: {
    fontSize: 14,
    flex: 1,
    padding: 0,
  },
});

export default SearchBar;
