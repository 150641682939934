/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  Image,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  AppState,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import { colors, images } from '../../../utils';
import dayjs from 'dayjs';
const Notice = () => {
  const navigation = useNavigation();

  const flatListRef = useRef<any>(null);

  const notificationHistoryList = useSelector(
    (state: any) => state.client.notificationHistoryList,
  );

  const renderItem = ({ item }) => (
    <Item
      data={item}
      onPress={() => {
        navigation.navigate('OrderDetail', {
          id: item.article.id,
          merchantName: item.merchant.name,
          from: 'Notice',
        });
        dispatch({
          type: 'clientMerchant/getGameData',
          payload: {
            gameData: item.description,
          },
        });
      }}
    />
  );
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (page, flag = false) => {
      dispatch({
        type: 'client/getNotificationHistory',
        payload: { page },
      });
      if (page === 1 && flag) {
        dispatch({
          type: 'client/tests',
          payload: {
            height: -110,
          },
        });
        let currentMerchantId;
        try {
          currentMerchantId = await AsyncStorage.getItem('currentMerchantId');
        } catch (e) {
          currentMerchantId = null;
        }
        if (currentMerchantId) {
          dispatch({
            type: 'clientMerchant/getArticleList',
            payload: {
              page,
              merchantId: currentMerchantId,
              mode: 0,
            },
          });
        }
      }
    },
    [dispatch],
  );
  // 进入页面触发方法
  // useFocusEffect(
  //   useCallback(() => {
  //     console.log('进入');
  //   }, []),
  // );
  useEffect(() => {
    fetchData(1);
    dispatch({
      type: 'client/batchMarkAsRead',
      payload: {},
    });
  }, []);

  /** 检测APP状态， 进入APP刷新页面 */
  const _handleAppStateChange = useCallback((state) => {
    if (state === 'active') {
      fetchData(1, true);
      dispatch({
        type: 'client/batchMarkAsRead',
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      _handleAppStateChange,
    );
    return () => {
      subscription.remove();
    };
  }, [_handleAppStateChange]);

  const handlePressBackToTop = () => {
    flatListRef.current?.scrollToOffset({
      animated: true,
      viewPosition: 0,
      index: 0,
    });
  };

  return <SafeAreaView style={styles.mainContainer} />;
};

const SMSListStyles = StyleSheet.create({
  noticeItem: {
    flexBasis: 58,
    paddingLeft: 15,
    paddingRight: 20,
    paddingVertical: 12,
    backgroundColor: colors.WHITE,
    borderRadius: 16,
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  noticeItemImage: {
    width: 22,
    height: 22,
    borderRadius: 20,
  },
  noticeItemBody: { flex: 1, marginLeft: 4, justifyContent: 'space-between' },
  noticeItemDesc: {
    marginTop: 5,
    color: colors.TUNDORA,
    fontSize: 16,
    fontWeight: '700',
  },
  noticeItemDate: { color: colors.GRAY, fontSize: 12 },
  noticeItemIntro: {
    color: colors.GRAY,
    fontSize: 12,
    marginBottom: 5,
  },
  noticeItemHr: {
    width: '100%',
    height: 1,
    backgroundColor: '#E5E5E5',
    marginTop: 20,
    marginBottom: 10,
  },
  noticeBottom: {
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  noticeBottomText: {
    color: colors.AZURE_RADIANCE,
    fontSize: 12,
  },
  noticeBottomArrow: {
    width: 10,
    height: 10,
  },
  noticeItemBodyTop: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noticeItemBodyTopImgTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noticeMerchantName: {
    fontSize: 14,
    color: colors.TUNDORA,
    textAlign: 'left',
    marginLeft: 10,
  },
});

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  hasContainer: {
    flex: 1,
    top: '6%',
  },
  backToTop: {
    width: 40,
    height: 40,
    left: '86%',
    bottom: '7%',
  },
});

const Item = ({ data, onPress }) => {
  const {
    article,
    merchant: { name, avatar },
    createTime,
  } = data;
  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.7}
        style={SMSListStyles.noticeItem}
        onPress={onPress}>
        <View style={SMSListStyles.noticeItemBodyTop}>
          <View style={SMSListStyles.noticeItemBodyTopImgTitle}>
            <Image
              source={avatar ? { uri: avatar } : images.LOGO}
              style={SMSListStyles.noticeItemImage}
            />
            <Text style={SMSListStyles.noticeMerchantName}>{name}</Text>
          </View>
          <Text style={SMSListStyles.noticeItemDate}>
            {dayjs(createTime).format('YYYY-MM-DD HH:mm')}
          </Text>
        </View>
        <View style={SMSListStyles.noticeItemBody}>
          <Text style={SMSListStyles.noticeItemDesc}>{article.title}</Text>
        </View>
        <View>
          <View style={SMSListStyles.noticeItemHr} />
          {article.intro ? (
            <Text style={SMSListStyles.noticeItemIntro}>{article.intro}</Text>
          ) : null}
          <View style={SMSListStyles.noticeBottom}>
            <Text style={SMSListStyles.noticeBottomText}>点击查看详情</Text>
            <Image
              style={SMSListStyles.noticeBottomArrow}
              source={images.PERSONAL_ARROW}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default Notice;
