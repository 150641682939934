/* eslint-disable react-native/no-inline-styles */
/*
 * @Description: 优惠卡
 * @Author: Evan huang
 * @Date: 2023-11-30 21:20:27
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-16 02:44:16
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/Client/MyPage/DiscountCard/index.tsx
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  SectionList,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { MAllLoadedBox, MEmptyBox } from '../../../../components';
import DiscountCardItem from './DiscountCardItem';
import BaseButton from '../../../../components/BaseButton';
import Modal from '@ant-design/react-native/lib/modal';
import Toast from '@ant-design/react-native/lib/toast';
import PayModal, { PayMethodItem } from '../../../PayModal';
import dayjs from 'dayjs';
import color from '../../../../utils/color';
import { colors } from '../../../../utils';

// 声明 DiscountCard 组件的属性类型
interface DiscountCardProps {
  from?: string;
  route?: any;
  navigation: any;
  onChoose?: any;
  listHeaderView?: any;
  sectionHeaderView?: any;
}
const DiscountCard: React.FC<DiscountCardProps> = (
  props: DiscountCardProps,
) => {
  const from = props.route?.params?.from || props.from;
  const dispatch = useDispatch();
  const navigation = props.navigation;

  const listHeaderView = props?.listHeaderView;
  const sectionHeaderView = props?.sectionHeaderView;

  const dataList = useSelector((state: any) =>
    from !== 'home'
      ? state.client.discountCardOrder
      : state.client.discountCardList,
  );

  // 用于做购买前操作的判断
  const discountCardOrder = useSelector(
    (state: any) => state.client.discountCardOrder,
  );

  const { id: merchantId } = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );

  const TabOption = [
    { id: 1, name: '已生效' },
    { id: 2, name: '已过期' },
  ];
  const [tabType, setTabType] = useState(TabOption[0]); // 1=生效，2=过期

  const fetchData = useCallback(
    (page) => {
      // 获取首页优惠卡列表
      from === 'home' &&
        dispatch({
          type: 'client/getDiscountCardList',
          payload: {
            page,
            size: 10,
            merchantId,
          },
        });
      // 获取我的订单列表，用于判断用户是否已经购买了某种类型的优惠卡，来做购买前操作
      dispatch({
        type: 'client/getDiscountCardOrder',
        payload: {
          page,
          size: 10,
          merchantId,
          type: from === 'article' ? 2 : 1, // type 1=查询所有优惠卡类型，2=查询次卡类型优惠卡
          tabType: tabType.id, // tabType 1=生效，2=过期
        },
      });
    },
    [dispatch, merchantId, from, tabType],
  );

  useEffect(() => {
    fetchData(1);
    // 避免一个场景：用户进入优惠卡列表，切换到已过期，然后直接回到首页去购买折扣卡，比对的是已过期的折扣卡
    if (tabType.id === 2) {
      return () => {
        dispatch({
          type: 'clientClient/getDiscountCardOrder',
          payload: {
            page: 1,
            size: 10,
            merchantId,
            type: 1, // type 1=查询所有优惠卡类型，2=查询次卡类型优惠卡
            tabType: 1, // tabType 1=生效，2=过期
          },
        });
      };
    }
  }, [tabType]);

  const TabItem = (props) => {
    const { item, currentTab, goFunc = () => {} } = props;

    return (
      <TouchableOpacity
        style={tabItemStyles.tabItemSection}
        onPress={() => goFunc(item)}>
        <Text
          style={
            currentTab.id === item.id
              ? tabItemStyles.activeItem
              : tabItemStyles.passiveItem
          }>
          {item.name}
        </Text>
        {currentTab.id === item.id && (
          <View style={tabItemStyles.activeItemBottomLine} />
        )}
      </TouchableOpacity>
    );
  };

  const headerView = () => {
    return (
      <View style={styles.headerRow}>
        {TabOption.map((item) => (
          <TabItem
            key={item.id}
            item={item}
            currentTab={tabType}
            goFunc={setTabType}
          />
        ))}
      </View>
    );
  };

  //FlatList单个对象渲染，单个优惠卡
  const renderItem = ({ item }) => {
    return (
      <View
        style={{
          marginTop: 10,
          marginHorizontal: 16,
          marginBottom: 5,
        }}>
        <DiscountCardItem
          from={from}
          item={item}
          onChoose={from === 'article' ? props?.onChoose : undefined}
        />
        {from === 'home' && (
          <BaseButton
            style={{
              backgroundColor: color.N1500,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              paddingTop: 10,
              paddingBottom: 10,
            }}
            text="购买优惠卡"
            onPress={() => {
              Modal.alert(
                '提示',
                item.type === 3 ? (
                  <View
                    style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text
                      style={{ color: 'red', fontSize: 18, marginBottom: 5 }}>
                      {`${dayjs()
                        .add(
                          Number(
                            dayjs().isBefore(
                              dayjs().hour(item.hour).startOf('hour'),
                            )
                              ? item.days - 1
                              : item.days,
                          ),
                          'day',
                        )
                        .format('YYYY-MM-DD')} ${item.hour}:00到期`}
                    </Text>
                    <Text>(请仔细查看到期时间)</Text>
                  </View>
                ) : (
                  '因为此套餐卡为优惠力度大，一经购买不可退款，请确认是否购买？'
                ),
                [
                  { text: '取消', style: 'cancel', onPress: () => {} },
                  {
                    text: '确认',
                    onPress: () => {
                      onBeforePurchase(item);
                    },
                  },
                ],
              );
            }}
          />
        )}
      </View>
    );
  };

  //支付相关数据与函数
  const [isPayModal, setIsPayModal] = useState(false);
  const [isBuyDoing, setIsBuyDoing] = useState(false);
  const [currentDiscountCard, setCurrentDiscountCard] = useState({} as any);

  //获取支付通道
  useEffect(() => {
    from === 'home' &&
      dispatch({
        type: 'client/getAdapayVosByMerchantId',
        payload: { merchantId },
      });
  }, []);

  //多通道不考虑预选支付方式
  // 默认支付方式勾选，若是ios则默认勾选3余额支付，若余额不足则默认勾选4充值，若是Android再看微信通道是否打开，打开则1，否则看支付宝通道是否打开，打开则2，否则看余额是否够。不够则4，否则3
  const [buyMethod, setBuyMethod] = useState<PayMethodItem>(
    null as unknown as PayMethodItem,
  );

  const setPayModal = (isVisible, isControllLoading = true, item = null) => {
    setCurrentDiscountCard(item);
    setIsPayModal(isVisible);
    if (isControllLoading) {
      setIsBuyDoing(isVisible);
    } else {
      setIsBuyDoing(!isVisible);
    }
  };

  /** 支付前处理 */
  const onBeforePurchase = (item) => {
    // 如果是重复折扣卡，折扣卡不能购买比当前已购买的折扣更高的卡、只能购买新的较低折扣卡
    if (item.type === 2) {
      const isHigherDiscount = discountCardOrder.records.some(
        (i) => i.cardType === 2 && i.cardValue <= item.value,
      );
      if (isHigherDiscount) {
        Toast.info('您已拥有更低折扣的折扣卡，不能购买', 1);
        return;
      }
    }
    setPayModal(true, false, item);
  };

  /**
   * 支付成功后的处理
   */
  const onPaySuccess = (payStatus) => {
    // 1为支付成功，进行购买操作
    if (payStatus === 1) {
      setPayModal(false);
      fetchData(1);
      Modal.alert('提示', '购买成功，是否前往我的优惠卡查看？', [
        { text: '取消', style: 'cancel', onPress: () => {} },
        {
          text: '确认',
          onPress: () => {
            navigation.navigate('DiscountCard', { from: 'myPage' });
          },
        },
      ]);
    } else {
      setPayModal(true, false);
    }
  };

  // 点击立即付款事件
  const confirmPurchase = async (money: number) => {
    if (!buyMethod) {
      Toast.info('请选择支付方式');
      return;
    }
    money ? setPayModal(true) : null;
    const payCallBack = onPaySuccess;
    // 打印相关信息
    // 考虑打折情况会出现小数，此处向上取整
    switch (buyMethod.id) {
      // 微信支付
      case 1:
        dispatch({
          type: 'client/doWechatPay',
          payload: {
            payChannel: 'wx_pub',
            cardId: currentDiscountCard.id,
            appId: buyMethod.appid,
            payType: 3,
            clientType: 2,
            from: 'discountCard',
          },
          payCallBack,
        });
        return;
      // 支付宝支付
      case 2:
        dispatch({
          type: 'client/doAlipayPay',
          payload: {
            payChannel: 'alipay_wap',
            cardId: currentDiscountCard.id,
            appId: buyMethod.appid,
            payType: 3,
            clientType: 2,
            from: 'discountCard',
          },
          payCallBack,
        });
        return;
      // 余额支付
      case 3:
        dispatch({
          type: 'client/doBalancePay',
          payload: {
            payChannel: 'wallet',
            cardId: currentDiscountCard.id,
            appId: buyMethod.appid,
            payType: 4,
            clientType: 2,
            from: 'discountCard',
          },
          payCallBack,
        });
        return;
      // 云闪付支付
      case 4:
        dispatch({
          type: 'client/doYSPay',
          payload: {
            payChannel: 'union',
            cardId: currentDiscountCard.id,
            appId: buyMethod.appid,
            payType: 3,
            clientType: 2,
            from: 'discountCard',
          },
          payCallBack,
        });
        return;
    }
  };

  return (
    <SafeAreaView
      style={[styles.mainContainer, from === 'home' && { flex: 1 }]}>
      {from === 'myPage' && headerView()}
      <SectionList
        stickySectionHeadersEnabled={true}
        sections={[{ data: dataList?.records }]}
        renderSectionHeader={() =>
          from === 'home' ? sectionHeaderView() : null
        }
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        onRefresh={() => fetchData(1)}
        refreshing={false}
        onEndReached={() => {
          if (!dataList.isAllLoaded) {
            fetchData(dataList.current);
          }
        }}
        ListHeaderComponent={listHeaderView}
        ListEmptyComponent={<MEmptyBox title="暂无优惠卡" />}
        ListFooterComponent={
          dataList.records.length && dataList.isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
      {currentDiscountCard?.id && (
        <PayModal
          visible={isPayModal}
          setPayModal={setPayModal}
          onTopup={() => {
            navigation.navigate('my', { screen: 'TopUp' });
            setIsPayModal(false);
          }}
          articleAmount={currentDiscountCard?.price}
          isBuyDoing={isBuyDoing}
          confirmPurchase={confirmPurchase}
          setBuyMethod={setBuyMethod}
          buyMethod={buyMethod}
          from="discountCard"
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#F5F6F6',
  },
  headerRow: {
    height: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 20,
  },
});

const tabItemStyles = StyleSheet.create({
  tabItemSection: {
    height: '100%',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeItem: {
    color: colors.TUNDORA,
    fontSize: 14,
    fontWeight: '700',
  },
  passiveItem: {
    color: colors.TUNDORA,
    fontSize: 14,
  },
  activeItemBottomLine: {
    position: 'absolute',
    bottom: 0,
    height: 3,
    width: 16,
    backgroundColor: colors.GUARDSMAN_RED,
  },
});

export default DiscountCard;
