/*
 * @Description: 步骤条
 * @Author: Evan huang
 * @Date: 2023-01-17 14:57:01
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-10 04:27:20
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/components/Steps.tsx
 */
import React from 'react';
import { StyleSheet, View } from 'react-native';

type PropsType = {
  currentIndex: number;
  labels?: string[];
  // config?: StepsConfigs;
};

export default function ({}: PropsType) {
  return <View style={styles.stepCard} />;
}

const styles = StyleSheet.create({
  stepCard: {
    width: '100%',
    height: 70,
    backgroundColor: 'white',
    marginBottom: 10,
    justifyContent: 'center',
  },
});
