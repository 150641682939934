/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: your name
 * @Date: 2021-07-20 19:23:36
 * @LastEditTime: 2023-07-15 15:33:51
 * @LastEditors: Evan huang
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/screens/Suggest.tsx
 */
import React, { useState } from 'react';
import {
  Picker,
  // Picker,
  StyleSheet,
} from 'react-native';
import Flex from '@ant-design/react-native/lib/flex';
import List from '@ant-design/react-native/lib/list';
import InputItem from '@ant-design/react-native/lib/input-item';
import Button from '@ant-design/react-native/lib/button';
import Toast from '@ant-design/react-native/lib/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'react-native';

const SuggestScreen = () => {
  const dispatch = useDispatch();
  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );
  const { id, name } = currentMerchant;

  const [content, setContent] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [typeValue, setTypeValue] = useState<string>();

  const onSubmit = () => {
    if (!typeValue || typeValue.trim().length === 0) {
      Toast.info('请选择投诉类型');
      return;
    }
    if (!content || content.trim().length === 0) {
      Toast.info('请填写投诉内容');
      return;
    }
    if (!phone || phone.trim().length === 0) {
      Toast.info('请填写手机号');
      return;
    }

    Toast.loading('提交中', 0);
    //投诉完整内容：【商家信息ID-NAME】+投诉内容
    const contentAll =
      '[' + id + '-' + name + ']-[' + typeValue.trim() + ']' + content.trim();
    dispatch({
      type: 'client/submitSuggest',
      payload: { content: contentAll, phone: phone.trim() },
    });
  };

  return (
    <Flex justify="center" style={styles.layout}>
      <List style={{ flex: 1 }}>
        <Text
          style={{
            marginHorizontal: 15,
            fontSize: 17,
            lineHeight: 50,
            color: '#D90000',
          }}>
          当前商家：{name}
        </Text>
        <Flex
          style={{
            height: 50,
            backgroundColor: '#FFF',
            borderTopWidth: 0.5,
            borderBottomWidth: 0.5,
            borderColor: '#999',
            marginHorizontal: 15,
          }}>
          <Text style={{ fontSize: 17, lineHeight: 50 }}>投诉类型</Text>
          {
            <Picker
              onValueChange={setTypeValue}
              style={{ flex: 1, height: 49, backgroundColor: '#FFF' }}
              itemStyle={{ height: 50 }}>
              <Picker.Item label="请选择投诉类型" value={undefined} />
              <Picker.Item label="商家做假、欺骗" value="商家做假、欺骗" />
              <Picker.Item label="支付成功未显示" value="支付成功未显示" />
              <Picker.Item label="包含违法违规信息" value="包含违法违规信息" />
              <Picker.Item label="包含谣言信息" value="包含谣言信息" />
            </Picker>
          }
        </Flex>
        <InputItem
          maxLength={200}
          onChange={setContent}
          placeholder="请输入相关文章标题或关键内容部分">
          投诉内容
        </InputItem>
        <InputItem onChange={setPhone} placeholder="请输入联系电话">
          联系电话
        </InputItem>
      </List>
      <Button
        style={{ width: '100%', backgroundColor: '#D90000' }}
        onPress={onSubmit}>
        <Text style={{ color: '#FFF' }}>提交投诉</Text>
      </Button>
    </Flex>
  );
};

const styles = StyleSheet.create({
  layout: { flex: 1, flexDirection: 'column', alignItems: 'stretch' },
  tipText: {
    color: '#444',
    fontSize: 20,
    fontWeight: '800',
  },
});

export default SuggestScreen;
