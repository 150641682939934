/* eslint-disable react-native/no-inline-styles */
/*
 * @Description: 删选排序过滤组件
 * @Author: Evan huang
 * @Date: 2023-11-30 19:25:00
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-10 04:21:49
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/components/FilterButton.tsx
 */
import {
  Dimensions,
  Pressable,
  StyleSheet,
  Text,
  View,
  ScrollView,
} from 'react-native';
import { colors } from '../../utils';
import React from 'react';

const { height } = Dimensions.get('window');
const FilterView = ({ itemList, onClose, onSubmit }) => {
  return (
    <View style={styles.filterContainer}>
      <View style={{ height: height * 0.35, backgroundColor: '#fff' }}>
        <ScrollView style={{ flex: 1 }}>
          {itemList.map((item, index) => (
            <FilterButton key={index} {...item} />
          ))}
        </ScrollView>
      </View>

      <View style={styles.filterRowButton}>
        <Pressable
          style={styles.filterButton}
          onPress={() => {
            //确认=true
            onSubmit(true);
            onClose();
          }}>
          <Text style={styles.filterButtonText}>确认</Text>
        </Pressable>

        <Pressable
          style={styles.filterButton}
          onPress={() => {
            //重置=false
            onSubmit(false);
            onClose();
          }}>
          <Text style={styles.filterButtonText}>取消</Text>
        </Pressable>
      </View>
      <Pressable style={styles.filterBackDrop} />
    </View>
  );
};
const FilterButton = ({ value, setValue, data, title }) => {
  return (
    <View style={styles.filterRowContainer}>
      <Text style={styles.filterRowTitle}>{title}</Text>
      <View style={styles.filterBtnContainer}>
        {data.map((item) => (
          <Pressable
            style={
              value === item.id
                ? styles.filterBlurredBtn
                : styles.filterFocusedBtn
            }
            key={item.id}
            onPress={() => {
              setValue(item.id);
            }}>
            <Text
              style={
                value === item.typeName
                  ? styles.filterBlurredBtnText
                  : styles.filterFocusedBtnText
              }>
              {item.typeName}
            </Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  filterContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  },
  filterRowContainer: {
    backgroundColor: colors.WHITE,
    flexDirection: 'column',
    padding: 8,
    borderColor: colors.MERCURY,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  filterRowTitle: {
    fontSize: 12,
    color: colors.GRAY20,
  },
  filterBtnContainer: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterRowButton: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    padding: 8,
    borderColor: colors.MERCURY,
    borderTopWidth: StyleSheet.hairlineWidth,
    justifyContent: 'space-around',
  },
  filterButton: {
    width: '40%',
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    backgroundColor: colors.RED_ORANGE,
    borderRadius: 4,
  },
  filterButtonText: {
    fontSize: 12,
    color: colors.WHITE,
  },
  filterBlurredBtn: {
    backgroundColor: colors.CHABLIS,
    margin: 8,
    minWidth: (Dimensions.get('window').width - 120) / 3,
    height: 30,
    borderRadius: 4,
    borderColor: colors.GUARDSMAN_RED,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterFocusedBtn: {
    backgroundColor: colors.ALABASTER,
    margin: 8,
    minWidth: (Dimensions.get('window').width - 120) / 3,
    height: 30,
    borderRadius: 4,
    borderColor: colors.ALABASTER,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterFocusedBtnText: {
    color: colors.GRAY,
    fontSize: 11,
  },
  filterBlurredBtnText: {
    color: colors.GUARDSMAN_RED,
    fontSize: 11,
  },
  filterBackDrop: {
    backgroundColor: colors.BLACK,
    height: 700,
    opacity: 0.5,
  },
});

export default FilterView;
