import React, { useEffect, useState } from 'react';
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  ImageBackground,
} from 'react-native';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode-generator';
import { images } from '../utils';
import { encode as base64_encode } from 'base-64';

const { width } = Dimensions.get('window');
const imgRatio = width / 375;
const imgHeight = imgRatio * 500;

const FollowQrCode = () => {
  const jumpH5Url = useSelector((state: any) => state.parameter.h5url);

  console.log(jumpH5Url);
  const myInfo = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );

  const bottom = Platform.OS === 'ios' ? 70 : 35;

  const [base64Img, setBase64Img] = useState('');

  const downloadMaterial = () => {
    // const filePath = `${RNFS.DocumentDirectoryPath}/推广.png`;
    // RNFS.writeFile(filePath, base64Img)
    //   .then(() => {
    //     console.log('成功保存Base64数据');
    //   })
    //   .catch((error) => {
    //     console.log(`保存失败：${error}`);
    //   });
  };
  useEffect(() => {
    const typeNumber = 4;
    const errorCorrectionLevel = 'L';
    const qr = QRCode(typeNumber, errorCorrectionLevel);
    qr.addData(
      `${
        jumpH5Url.itemValue
      }/api/client/share/article?merchantId=${base64_encode(myInfo.id)}`,
    );
    qr.make();
    // console.log(qr.createDataURL());
    setBase64Img(qr.createDataURL());
  }, []);

  return (
    <SafeAreaView style={styles.layout}>
      {/* <ViewShot ref={viewShotRef} options={{ format: 'jpg', quality: 1 }}> */}
      <ImageBackground
        source={images.ARTICLE_QRCODE_BG}
        style={{ width, height: imgHeight }}
        resizeMode="contain">
        <View style={styles.contentContainer}>
          <Image
            style={styles.avatar}
            source={myInfo.avatar ? { uri: myInfo.avatar } : images.LOGO}
          />
          <Text style={styles.title}>{myInfo.name}</Text>
          {base64Img ? (
            <Image
              source={{ uri: base64Img }}
              style={styles.ewm}
              resizeMode="contain"
            />
          ) : null}
          <Text style={styles.text}>
            长按识别二维码，进入我的主页。可以下载登录APP。保存收藏此图片，永不失联！
          </Text>
          <Text style={styles.text}>
            如二维码故障，请在应用商店搜索“码上来料”下载后，添加搜索商家“
            {myInfo.name}”即可
          </Text>
        </View>
      </ImageBackground>
      {/* </ViewShot> */}
      <View style={styles.buttomButton}>
        {/* <View style={[styles.buttonContainer, { bottom }]}> */}
        {/* <TouchableOpacity onPress={downloadMaterial} style={styles.button}> */}
        {/* <Text style={styles.buttonText}>保存图片</Text> */}
        {/* </TouchableOpacity> */}
        {/* </View> */}
        <View style={[styles.buttonContainer, { bottom }]} />
        {myInfo.homeSwitchFlag !== 1 ? (
          <Text style={styles.switchText}>
            微信公众号主页已被关闭,请联系平台开通
          </Text>
        ) : null}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
  },
  contentContainer: {
    width: '90%',
    height: '75%',
    top: 90,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    marginHorizontal: 17.5,
    borderRadius: 12,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginBottom: 8,
    marginTop: 11.5,
  },
  ewm: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: 100,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  text: {
    color: '#D90000',
    marginVertical: 5,
    textAlign: 'center',
    justifyContent: 'center',
    width: 260,
    fontSize: 13,
    fontWeight: 'bold',
  },
  qrCode: {
    marginTop: 7,
    marginBottom: 8,
  },
  buttomButton: {
    flexDirection: 'column',
    marginTop: 70,
  },
  buttonContainer: {
    paddingHorizontal: 45,
    flexDirection: 'row',
    marginBottom: 5,
  },
  button: {
    flex: 1,
    margin: 5,
    height: 37,
    borderRadius: 24,
    backgroundColor: '#D90000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 14,
    color: '#ffffff',
  },
  shareModal: {
    height: 178.5,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#F5F5F5',
  },
  shareModalHeader: {
    alignItems: 'center',
    marginTop: 8,
  },
  shareModalHeaderTitle: {
    fontSize: 13,
    color: '#999999',
  },
  shareModalContainer: {
    flexDirection: 'row',
    marginTop: 12,
  },
  shareModalContainerButton: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
  },
  shareButton: {
    width: 50,
    height: 50,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButtonIcon: {
    width: 37,
    height: 34,
  },
  shareButtonTitle: {
    fontSize: 11,
    color: '#999999',
    marginTop: 6,
  },
  cancleShareButton: {
    width: '100%',
    height: 57,
    backgroundColor: '#ffffff',
    marginTop: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancleShareButtonTitle: {
    fontSize: 18,
    color: '#333333',
  },
  switchText: {
    fontSize: 16,
    color: 'red',
    textAlign: 'center',
  },
});

export default FollowQrCode;
