/*
 * @Description:
 * @Author: Evan huang
 * @Date: 2023-12-05 17:14:02
 * @LastEditors: Eave Luo
 * @LastEditTime: 2024-03-13 03:26:15
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: \msll_h5\src\screens\Client\MyPage\Coupon\CouponItem.tsx
 */

import dayjs from 'dayjs';
import React from 'react';
import {
  View,
  ImageBackground,
  Text,
  StyleSheet,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import { colors, images } from '../../../../utils';
import Toast from '@ant-design/react-native/lib/toast';

/** TabType类型切换 */
export const TabType = [
  { id: 0, name: '未使用' },
  { id: 1, name: '已使用' },
  { id: 2, name: '已过期' },
];

/** 优惠券状态图片 */
const StatusImages = [
  images.COUPON,
  images.COUPON_INVALID,
  images.COUPON_INVALID,
];

const CouponItem = ({ item, onChoose = undefined }: any) => {
  return onChoose ? (
    <Pressable style={styles.itemView} onPress={() => onChoose(item)}>
      <CouponItemView item={item} />
    </Pressable>
  ) : (
    <View style={styles.itemView}>
      <CouponItemView item={item} />
    </View>
  );
};

const CouponItemView = ({ item }) => {
  return (
    <ImageBackground
      source={StatusImages[item.useStatus]}
      style={styles.itemBackgroud}>
      <TouchableOpacity
        onPress={() => Toast.info('下载APP使用优惠券')}
        style={styles.itemBackgroud}>
        <View style={styles.itemPriceSection}>
          <Text style={styles.itemPrice}>
            ¥<Text style={styles.itemPriceNum}>{item.couponAmount}</Text>
          </Text>
        </View>
        <View style={styles.itemContent}>
          <Text style={styles.itemName}>{item.couponName}</Text>
          <Text style={styles.itemDate}>商家:{item.merchantName}</Text>
          {item.expireTime && (
            <Text style={styles.itemDate}>
              有效期至：
              {dayjs(item.expireTime).format('YYYY-MM-DD')}
            </Text>
          )}
          {item.useStatus > 0 && (
            <Text style={styles.itemUsed}>
              优惠券
              {TabType.find((tabItem) => tabItem.id === item.useStatus)?.name}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  itemView: {
    width: '100%',
    height: 90,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  itemBackgroud: {
    width: 347,
    height: 90,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPriceSection: {
    width: 98,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemPrice: {
    fontSize: 12,
    color: colors.WHITE,
  },
  itemPriceNum: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  itemContent: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  itemName: {
    color: colors.BLACK,
    fontSize: 16,
    fontWeight: 'bold',
  },
  itemDate: {
    marginTop: 10,
    color: colors.GRAY,
  },
  itemUsed: {
    marginTop: 5,
    color: colors.RED_00,
    fontSize: 14,
  },
});

export default CouponItem;
