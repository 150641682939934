/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Pressable,
  Animated,
  Easing,
  Dimensions,
  SectionList,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { encode as base64_encode } from 'base-64';
import Modal from '@ant-design/react-native/lib/modal';
import Toast from '@ant-design/react-native/lib/toast';
import { colors, images } from '../utils';
import {
  MAllLoadedBox,
  MEmptyBox,
  MOrderItem,
  ExtensionModal,
} from '../components';
import DiscountCard from './Client/MyPage/DiscountCard';
import MerchantHeader from './MerchantHeader';
import FilterView from './components/FilterButton';
import MergeMerchantListModal from './MergeMerchantListModal';
import SuggestButton from '../components/SuggestButton';
const fadeInAnimated = new Animated.Value(0.4);

const animateGoBack = () => {
  Animated.timing(fadeInAnimated, {
    toValue: 0.4,
    duration: 800,
    easing: Easing.linear,
    useNativeDriver: true,
  }).start(() => animateGoAhead());
};

const animateGoAhead = () => {
  Animated.timing(fadeInAnimated, {
    toValue: 1,
    duration: 800,
    easing: Easing.linear,
    useNativeDriver: true,
  }).start(() => animateGoBack());
};

const HomeScreen = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const height = useSelector((state: any) => state.screen.height);

  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );

  const articleListTA = useSelector(
    (state: any) => state.clientMerchant.articleListTA,
  );
  const articleListPeriod = useSelector(
    (state: any) => state.clientMerchant.articleListPeriod,
  );
  const currentClient = useSelector(
    (state: any) => state?.client?.currentClient,
  );

  //是否绑定银行卡
  const bankCard = useSelector((state: any) => state.client.bankCard);

  const gzFlag = useSelector((state: any) => state?.client?.gzFlag);

  const [currentTab, setCurrentTab] = useState('0');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [shouwPop, setShowPop] = useState(true);
  const [visible, setVisible] = useState(true);
  const [gzhFlag, setGzhFlag] = useState(false);
  const [sortMode, setSortMode] = useState('');
  const [filterMode, setFilterMode] = useState(0);
  const [wechatModalVisible, setWechatModalVisible] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  const [isMergeModalVisible, setIsMergeModalVisible] = useState(false);

  const [width, setWidth] = useState(0);

  const fetchData = useCallback(
    async (page = 1) => {
      let localMerchantId = await AsyncStorage.getItem('currentMerchantId');
      let mergeCodeId = await AsyncStorage.getItem('mergeCodeId');
      setIsMergeModalVisible(mergeCodeId !== null);
      dispatch({
        type: 'clientMerchant/getArticleList',
        payload: {
          page,
          merchantId: currentMerchant.id || localMerchantId,
          mode: currentTab,
          keyword: searchKeyword,
          typeId:
            filterMode !== 0 && currentTab !== '2' ? filterMode : undefined,
          sortName: currentTab !== '2' ? sortMode : undefined,
        },
      });
    },
    [
      dispatch,
      currentMerchant,
      currentTab,
      searchKeyword,
      filterMode,
      sortMode,
    ],
  );

  const SORT_MODE = [
    { id: '', typeName: '默认', index: 0 },
    { id: 'ks', typeName: '开赛时间排序', index: 1 },
    { id: 'lh', typeName: '连红排序', index: 2 },
  ];

  const articleType = useSelector((state: any) => state.client.articleType);

  const qrCodeUrl = useSelector((state: any) => state.client.qrCodeUrl);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const getApplyStatus = useCallback(async () => {
    dispatch({
      type: 'client/getApplyStatus',
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (!currentMerchant.id) {
      return;
    }
    fetchData(1);
  }, [currentClient, currentMerchant]);

  useEffect(() => {
    if (!currentMerchant.id) {
      return;
    }
    dispatch({
      type: 'client/articleType',
      payload: {
        currentPage: 1,
        merchantId: currentMerchant.id,
        pageSize: 50,
      },
    });
  }, [currentMerchant, dispatch]);

  useEffect(() => {
    getApplyStatus();
  }, [getApplyStatus]);

  useEffect(() => {
    dispatch({
      type: 'client/getUserBindBank',
      payload: {},
    });
  }, [dispatch]);

  const tabChangeFunc = (tabIndex) => {
    if (currentTab !== tabIndex) {
      setCurrentTab(tabIndex);
      setSearchKeyword('');
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [currentTab, searchKeyword]);

  const renderItem = ({ item }) => {
    return (
      <MOrderItem
        id={item.id}
        title={item.title}
        createTime={item.createTime}
        updateTime={item.updateTime}
        lastTime={item.lastTime}
        mode={item.mode}
        redLabel={item.redLabel}
        customLabel={item.customLabel}
        published={item.published}
        price={item.price}
        refundable={item.refundable}
        goDetail={() => {
          props.navigation.push('OrderDetail', {
            articleId: base64_encode(item.id),
          });
          dispatch({
            type: 'clientMerchant/getGameData',
            payload: {
              gameData: item.description,
            },
          });
        }}
        period={item?.period}
        tag={item.tag}
        gameData={item.description}
        isPresale={item.isPresale}
        presaleTime={item.presaleTime}
      />
    );
  };

  const dataList = currentTab === '0' ? articleListTA : articleListPeriod;
  const newRecords = dataList.records.reduce((tempArr, item) => {
    if (tempArr.findIndex((ele) => ele.id === item.id) === -1) {
      tempArr.push(item);
    }
    return tempArr;
  }, []);

  useEffect(() => {
    animateGoAhead();
  }, []);

  const listHeaderView = () => {
    return (
      <>
        <MerchantHeader merchantInfo={currentMerchant} isHome={true} />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            height: 30,
            marginTop: -30,
          }}>
          <View
            style={{
              backgroundColor: colors.GUARDSMAN_RED,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <View style={{ marginLeft: 9, marginRight: 9 }}>
              <TouchableOpacity
                onPress={() => {
                  if (currentMerchant.gzhImg) {
                    setGzhFlag(true);
                  } else {
                    Toast.info('商家暂未传公众号');
                  }
                }}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Image
                  source={images.MORE_ICON}
                  style={styles.headerRightItemImage}
                />
                <Text style={{ color: 'white', marginLeft: 5 }}>公众号</Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                borderWidth: 0.8,
                width: 1,
                height: 10,
                borderColor: 'white',
              }}
            />
            <View style={{ marginLeft: 9, marginRight: 9 }}>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={async () => {
                  if (bankCard && currentClient.adapayRealnameMemberFlag) {
                    dispatch({
                      type: 'client/getMerchantsQRCode',
                      payload: {
                        merchantId: currentMerchant.id,
                      },
                    });
                    setAvatar(
                      currentMerchant.avatar
                        ? { uri: currentMerchant.avatar }
                        : images.DEFAULT_AVATAR,
                    );
                    setName(currentMerchant.name);
                    setWechatModalVisible(true);
                  } else {
                    Toast.info('参加拉新活动请先完成实名认证并绑定银行卡');
                  }
                }}>
                <Image
                  source={images.EWM}
                  style={styles.headerRightItemImage}
                />
                <Text style={{ color: 'white', marginLeft: 5 }}>推广TA</Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                borderWidth: 0.8,
                width: 1,
                height: 10,
                borderColor: 'white',
              }}
            />
            <View style={{ marginLeft: 9, marginRight: 9 }}>
              <TouchableOpacity
                onPress={() => {
                  Toast.info('下载APP提供私信功能');
                }}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Image
                  source={images.WECHAT_LOGIN}
                  style={styles.headerRightItemImage}
                />
                <Text style={{ color: 'white', marginLeft: 5 }}>私信TA</Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                borderWidth: 0.8,
                width: 1,
                height: 10,
                borderColor: 'white',
              }}
            />
            <SuggestButton />
          </View>
        </View>
      </>
    );
  };

  const sectionHeaderView = () => {
    return (
      <View
        style={{ backgroundColor: colors.DEFAULT_BG_COLOR, marginBottom: 5 }}>
        <View style={styles.tabSection}>
          <TabItem
            tabText="TA的料"
            isActive={currentTab === '0'}
            goFunc={() => tabChangeFunc('0')}
          />
          <TabItem
            tabText="包时套餐"
            isActive={currentTab === '2'}
            goFunc={() => tabChangeFunc('2')}
          />
          <TabItem
            tabText="优惠卡"
            currentTab={currentTab}
            isActive={currentTab === '3'}
            goFunc={() => tabChangeFunc('3')}
          />
        </View>
        {currentTab === '0' && (
          <>
            <View style={styles.searchBarContainer}>
              <TouchableOpacity
                style={styles.filterSelector}
                onPress={() => {
                  if (articleType.records.length > 0) {
                    setIsFilterModalVisible((state) => !state);
                  }
                }}>
                <Text style={styles.filterSelectorText}>筛选排序</Text>
                <Image source={images.DOWN_ICON} style={styles.filterSelDown} />
              </TouchableOpacity>
              <View style={styles.filterInputContainer}>
                <Image source={images.SEARCH_ICON} style={styles.searchImage} />
                <TextInput
                  returnKeyType="done"
                  placeholder="搜索内容关键词"
                  style={styles.filterInput}
                  value={searchKeyword}
                  onChangeText={(val) => {
                    setSearchKeyword(val);
                  }}
                  onSubmitEditing={() => {
                    fetchData();
                  }}
                />
              </View>
            </View>
            <View
              style={[
                styles.flatListContainer,
                isFilterModalVisible ? styles.flatListHeight : null,
              ]}>
              {isFilterModalVisible && (
                <FilterView
                  itemList={[
                    {
                      title: '类型筛选',
                      data: articleType.records,
                      value: filterMode,
                      setValue: setFilterMode,
                    },
                    {
                      title: '排序方式（只展示24小时内）',
                      data: SORT_MODE,
                      value: sortMode,
                      setValue: setSortMode,
                    },
                  ]}
                  onSubmit={(isSubmit) => {
                    if (!isSubmit) {
                      setFilterMode(0);
                      setSortMode('');
                    } else {
                      fetchData();
                    }
                  }}
                  onClose={() => setIsFilterModalVisible(false)}
                />
              )}
            </View>
          </>
        )}

        {currentTab === '2' && (
          <View style={styles.searchBarContainer}>
            <Image source={images.SEARCH_ICON} style={styles.searchIcon} />
            <TextInput
              returnKeyType="search"
              placeholder="搜索内容关键词"
              value={searchKeyword}
              onChangeText={(val) => {
                setSearchKeyword(val);
              }}
              onSubmitEditing={() => {
                fetchData();
              }}
              style={styles.searchInput}
            />
          </View>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView style={[styles.mainContainer, { height }]}>
      <Modal
        visible={gzhFlag}
        maskClosable
        transparent
        onClose={() => setGzhFlag(false)}
        style={styles.container}>
        <View
          onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
          style={styles.viewShotContainer}>
          <Image
            source={{ uri: currentMerchant.gzhImg }}
            style={{
              width: width,
              height: width,
            }}
          />
        </View>
      </Modal>
      <ExtensionModal
        visible={wechatModalVisible}
        avatar={avatar}
        name={name}
        customerQr={qrCodeUrl}
        onClose={() => setWechatModalVisible(false)}
      />
      {currentClient?.status === 0 ? (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text
            style={{
              fontSize: 22,
              textAlign: 'center',
              color: 'red',
            }}>
            网络连接错误，请稍后重试
          </Text>
        </View>
      ) : currentMerchant.homeSwitchFlag === 0 ? (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text
            style={{
              fontSize: 24,
              textAlign: 'center',
              color: 'red',
            }}>
            主页已被平台封禁
            <p>
              <a href="/download.html">点我下载APP进行使用</a>
            </p>
          </Text>
        </View>
      ) : (
        <>
          {
            // 优惠卡列表
            currentTab === '3' ? (
              <DiscountCard
                from="home"
                listHeaderView={listHeaderView()}
                sectionHeaderView={sectionHeaderView}
                navigation={navigation}
              />
            ) : (
              <SectionList
                stickySectionHeadersEnabled={true}
                sections={[{ data: newRecords }]}
                renderSectionHeader={() => sectionHeaderView()}
                renderItem={renderItem}
                keyExtractor={(item) => `period${item.id}`}
                refreshing={false}
                onRefresh={() => fetchData(1)}
                onEndReached={() => {
                  if (!dataList.isAllLoaded) {
                    fetchData(dataList.page);
                  }
                }}
                ListHeaderComponent={listHeaderView()}
                ListEmptyComponent={
                  <MEmptyBox
                    title="暂无数据"
                    followingStatus={currentMerchant.followingStatus}
                  />
                }
                ListFooterComponent={
                  dataList.records.length && dataList.isAllLoaded ? (
                    <MAllLoadedBox />
                  ) : null
                }
              />
            )
          }
          {currentMerchant.pushPic && !gzFlag ? (
            <>
              <View style={styles.push}>
                <Animated.View
                  style={[styles.around, { opacity: fadeInAnimated }]}
                />
                <TouchableOpacity
                  style={styles.smallAround}
                  onPress={() => setVisible(true)}>
                  <View style={{ width: 16, height: 16, marginTop: 9 }}>
                    <Image
                      source={images.BELL}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </View>
                  <Text style={styles.p2}>接收推送</Text>
                </TouchableOpacity>
              </View>
              <Modal
                visible={visible}
                maskClosable
                transparent
                onClose={() => setVisible(false)}
                style={styles.container}>
                <View>
                  <View
                    onLayout={(event) =>
                      setWidth(event.nativeEvent.layout.width)
                    }
                    style={[styles.viewShotContainer, { paddingVertical: 0 }]}>
                    <View
                      style={{
                        position: 'absolute',
                        // top: 2,
                        right: 2,
                        zIndex: 9999,
                      }}>
                      <Pressable
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: 10,
                          backgroundColor: colors.WHITE,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          setVisible(false);
                        }}>
                        <Image
                          source={images.CLOSE_ICON}
                          style={{ width: 30, height: 30 }}
                        />
                      </Pressable>
                    </View>
                    <Image
                      source={images.PUSH_UTOP}
                      style={{ width: width, height: 50 }}
                    />
                    <Image
                      source={{ uri: currentMerchant.pushPic }}
                      style={{
                        width: width - 100,
                        height: width - 100,
                        marginTop: 5,
                      }}
                    />
                    <Image
                      source={images.PUSH_U1135}
                      style={{ width: width, height: 80, marginTop: 10 }}
                    />
                  </View>
                </View>
              </Modal>
            </>
          ) : null}
        </>
      )}
      {!currentClient?.isDownloadApp && shouwPop && (
        <View style={styles.popLayout}>
          <Pressable style={styles.closeBtn} onPress={() => setShowPop(false)}>
            <Image source={images.WHITE_CLOSE_ICON} style={styles.closeIcon} />
          </Pressable>

          <Pressable
            style={styles.downloadBtn}
            onPress={() => {
              const random = Math.random();
              window.location.href = '/download.html?mt=' + random;
            }}>
            <Text style={styles.downloadText}>APP下载</Text>
          </Pressable>
          <Image source={images.BOTTOM_LOGO_ICON} style={styles.logoIcon} />
        </View>
      )}
      {isMergeModalVisible && <MergeMerchantListModal />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    // height,
    backgroundColor: colors.DEFAULT_BG_COLOR,
    paddingBottom: 47,
  },
  headerLeftSection: { flex: 0, marginLeft: 16 },
  headerLeftItemImage: { width: 23, height: 23 },
  headerRightSection: {
    flex: 0,
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerRightItemImage: { width: 16, height: 16 },
  headerRightText: {
    color: colors.GUARDSMAN_RED,
    fontSize: 16,
    fontWeight: '700',
  },
  filterBlurredBtnText: {
    color: colors.GUARDSMAN_RED,
    fontSize: 13,
  },
  filterInput: {
    flex: 1,
    borderRadius: 4,
    fontSize: 14,
    paddingHorizontal: 35,
    paddingVertical: 8,
  },
  tabSection: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 8,
    backgroundColor: colors.WHITE,
    height: 35,
    borderRadius: 3,
    paddingLeft: 12,
    paddingRight: 12,
    borderBottomColor: colors.MERCURY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  searchIcon: { width: 14, height: 14, marginRight: 8 },
  searchInput: { flex: 1, color: colors.DUSTY_GRAY, fontSize: 14, padding: 0 },
  contentSection: {
    flex: 1,
    alignItems: 'center',
    marginTop: 8,
    paddingTop: 50,
    backgroundColor: colors.WHITE,
    alignSelf: 'stretch',
  },
  contentErrorImg: { width: 235, height: 160 },
  contentErrorTxt01: { color: colors.GRAY, fontSize: 14 },
  contentErrorTxt02: {
    color: colors.GUARDSMAN_RED,
    fontSize: 13,
    marginTop: 9,
  },
  filterBtnContainer: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: colors.MERCURY,
    borderTopWidth: StyleSheet.hairlineWidth,
    padding: 8,
  },
  noDataSection: {
    alignItems: 'center',
    marginBottom: 27,
  },
  noDataImage: { width: 235, height: 160, marginTop: 124 },
  noDataText: { color: colors.DUSTY_GRAY, fontSize: 13, marginTop: 8 },
  scanQRBtn: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 47,
    width: 160,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  scanQRBtnText: { color: colors.WHITE, fontSize: 18 },
  modalContainer: {
    alignSelf: 'center',
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  modalTitle: {
    color: colors.BLACK,
    fontSize: 17,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 16,
  },
  searchImage: {
    width: 14,
    height: 14,
    position: 'absolute',
    zIndex: 1,
    alignSelf: 'center',
    marginLeft: 11.5,
  },
  modalDescription: {
    color: colors.TUNDORA,
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'justify',
  },
  modalBtn: {
    width: 160,
    height: 47,
    marginTop: 20,
    borderRadius: 7.5,
    backgroundColor: colors.GUARDSMAN_RED,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  modalBtnText: { color: colors.WHITE, fontSize: 18 },
  policyModalContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  policyModalHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  policyModalTitleText: {
    color: colors.TUNDORA,
    fontSize: 17,
    fontWeight: '700',
    paddingBottom: 20,
  },
  policyModalText: {
    color: colors.TUNDORA,
    lineHeight: 20,
  },
  policyModalBtnText: {
    color: '#108EE9',
    lineHeight: 20,
  },
  popLayout: {
    zIndex: 99,
    width: '100%',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 50,
    marginBottom: 47,
    backgroundColor: 'rgba(0,0,0,0.5);',
  },
  filterInputContainer: {
    flex: 0.75,
    flexDirection: 'row',
  },
  filterSelector: {
    flex: 0.25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterFocusedBtnText: {
    color: colors.GRAY,
    fontSize: 13,
  },
  filterBackdrop: {
    backgroundColor: colors.BLACK,
    height: 700,
    opacity: 0.5,
  },
  filterBlurredBtn: {
    backgroundColor: colors.CHABLIS,
    margin: 8,
    width: (Dimensions.get('window').width - 100) / 3,
    height: 35,
    borderRadius: 4,
    borderColor: colors.GUARDSMAN_RED,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterFocusedBtn: {
    backgroundColor: colors.ALABASTER,
    margin: 8,
    width: (Dimensions.get('window').width - 100) / 3,
    height: 35,
    borderRadius: 4,
    borderColor: colors.ALABASTER,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterSelDown: { width: 12, height: 7 },
  filterSelectorText: {
    color: colors.TUNDORA,
    fontSize: 13,
    marginRight: 12,
  },
  container: { flex: 1, borderWidth: 0 },

  closeBtn: { position: 'absolute', right: 5, top: 5, padding: 5 },
  closeIcon: {
    width: 12,
    height: 12,
  },
  logoIcon: {
    width: 156.5,
    height: 61.5,
  },
  downloadBtn: {
    width: '100%',
    borderRadius: 24,
    marginBottom: 16,
    paddingVertical: 10,
    backgroundColor: '#D90000',
  },
  downloadText: {
    color: '#FFF',
    fontSize: 18,
    textAlign: 'center',
  },
  rechargeBtn: {
    width: '100%',
    borderRadius: 24,
    marginBottom: 16,
    paddingVertical: 10,
    backgroundColor: '#FFF',
  },
  rechargeText: {
    color: '#D90000',
    fontSize: 18,
    textAlign: 'center',
  },
  around: {
    backgroundColor: '#FFD591',
    borderRadius: 31,
    width: 62,
    height: 62,
    justifyContent: 'center',
  },
  smallAround: {
    height: 48,
    width: 48,
    backgroundColor: '#FFA940',
    borderRadius: 24,
    position: 'absolute',
    top: 7,
    right: 7,
    alignItems: 'center',
  },
  flatListContainer: {
    position: 'relative',
    zIndex: 100,
    left: 0,
    top: 0,
    right: 0,
    marginHorizontal: 16,
  },
  flatListHeight: {
    height: 360,
  },
  modalCloseBtn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 24,
  },
  modalCloseBtnImage: { width: 30, height: 30 },
  wechatImage: { width: 240, height: 240 },
  wechatDesc: { color: colors.TUNDORA, fontSize: 14, marginTop: 16 },
  wechatBusinessNumberSection: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  businessNumberDesc: { color: colors.TUNDORA, fontSize: 16 },
  businessNumberVal: { color: colors.GUARDSMAN_RED, fontSize: 16 },
  downloadIcon: { position: 'absolute', bottom: -75 },
  downloadIconImage: {
    width: 45,
    height: 45,
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 24,
  },
  viewShotContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingTop: 10,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  wechatDescSection: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  closeIconImage: { width: 12, height: 12 },
  textView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
  },
  text: {
    padding: 3,
    flexWrap: 'wrap',
    paddingLeft: 8,
  },
  p1: {
    fontSize: 12,
    borderColor: '#FA7C05',
    backgroundColor: '#F9F2D6',
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    color: '#444444',
  },
  p2: {
    fontSize: 9,
    color: colors.WHITE,
  },
  imgStyle: {
    position: 'absolute',
    right: 13,
  },
  push: {
    position: 'absolute',
    right: 20,
    top: 170,
    zIndex: 99999,
  },
  footerColor: {
    backgroundColor: '#FAF7BA',
  },
  footerPColor: {
    color: '#D8370B',
  },
  filterRowButton: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    padding: 8,
    borderColor: colors.MERCURY,
    borderTopWidth: StyleSheet.hairlineWidth,
    justifyContent: 'space-around',
  },
});

const TabItemStyles = StyleSheet.create({
  tabItemSection: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeItem: {
    color: colors.TUNDORA,
    fontSize: 14,
    fontWeight: '700',
  },
  passiveItem: {
    color: colors.TUNDORA,
    fontSize: 14,
  },
  activeItemBottomLine: {
    position: 'absolute',
    bottom: 0,
    height: 3,
    width: 16,
    backgroundColor: colors.GUARDSMAN_RED,
  },
  filterRowButton: {
    backgroundColor: colors.WHITE,
    flexDirection: 'row',
    padding: 8,
    borderColor: colors.MERCURY,
    borderTopWidth: StyleSheet.hairlineWidth,
    justifyContent: 'space-around',
  },
});

const TabItem = (props) => {
  const { tabText = 'TA的料', isActive = true, goFunc = () => {} } = props;

  return (
    <TouchableOpacity style={TabItemStyles.tabItemSection} onPress={goFunc}>
      <Text
        style={isActive ? TabItemStyles.activeItem : TabItemStyles.passiveItem}>
        {tabText}
      </Text>
      {isActive && <View style={TabItemStyles.activeItemBottomLine} />}
    </TouchableOpacity>
  );
};

export default HomeScreen;
