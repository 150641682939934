/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import { request } from '../../utils';

export function getOrders(params) {
  return request.get(`/api/client/orders/${params.mode}`, params, false);
}

export function pinOrder(params) {
  return request.put(
    `/api/client/orders/pin/${params.transactionId}`,
    params,
    true,
  );
}

export function removeOrder(params) {
  return request.remove(
    `/api/client/orders/${params.transactionId}`,
    params,
    true,
  );
}

export function wechatDeposit(params) {
  return request.post('/api/client/deposit', params, false);
}

export function alipayDeposit(params) {
  return request.post('/api/client/deposit', params, false);
}
/** 聚合支付-微信小程序 */
export function adaWxPay(params) {
  return request.post('/api/client/deposit/adapayPayment', params, false);
}
/** 聚合支付-支付宝 */
export function adaAliPay(params) {
  return request.post('/api/client/deposit/adapayPayment', params, false);
}
/** 聚合支付-余额 */
export function walletPayment(params) {
  return request.post('/api/client/deposit/walletPayment', params, false);
}

/** 聚合支付-支付宝充值 */
export function adaAliRecharge(params) {
  return request.post('/api/client/userWallet/userWalletTopUp', params, false);
}

/** 聚合支付-微信小程序 */
export function adapayWxLiteRecharge(params) {
  return request.post('/api/client/userWallet/userWalletTopUp', params, false);
}
//用户钱包退款
export function userWalletRefund() {
  return request.post('/api/client/userWallet/userWalletRefund', false);
}
// 用户余额查询
export function userWalletBalance() {
  return request.get('/api/client/userWallet/userWalletBalance', false);
}
/** 查询充值结果 */
export function getTopUpState(params) {
  return request.get('/api/client/deposit/getTopUpState', params, false);
}
/** 获取支付配置 */
export function getPayStatus(params) {
  return request.get('/api/client/deposit/getPayState', params, false);
}
/** 提交投诉 */
export function submitSuggest(params) {
  return request.post('/api/client/suggest/add', params, false);
}

export function iapDeposit(params) {
  return request.post('/api/client/deposit', params, false);
}

// export function getProducts(params) {
//   return request.get('/api/client/deposit/prices', params, false);
// }

export function getTransactions(params) {
  return request.get('/api/client/orders/transactions', params, false);
}

/** 获取微信签名 */
export function getWechatSign(params) {
  return request.get('/api/client/wechat/gzh/signInfo', params, false);
}

//获取充值说明文案
export function getPayDesc() {
  return request.get('/api/client/agreement/getPayDesc');
}

export function getProducts(params) {
  return request.get('/api/client/deposit/prices', params, false);
}
