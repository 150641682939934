/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Text, View } from 'react-native';

/*
 * @Description: 用户Disable页面
 * @Author: Evan huang
 * @Date: 2024-05-10 19:59:50
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-11 02:30:00
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/DisableUser.tsx
 */
const DisableUser = () => {
  window.location.href = 'https://www.google.com';
  return <View />;
};

export default DisableUser;
