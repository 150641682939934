/*
 * @Description: 基础按钮组件
 * @Author: Evan huang
 * @Date: 2023-12-07 23:09:55
 * @LastEditors: Evan huang
 * @LastEditTime: 2023-12-12 16:38:28
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll-client/src/components/BaseButton.tsx
 */

import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { colors } from '../utils';
import { ActivityIndicator } from 'react-native';
import React from 'react';

const BaseButton = ({
  isLoading = false,
  onPress,
  text = '确定',
  style = undefined,
  textStyle = undefined,
}: any) => {
  return (
    <TouchableOpacity
      disabled={isLoading}
      onPress={onPress}
      style={[styles.buttonView, style]}>
      {isLoading ? (
        <ActivityIndicator color={colors.WHITE} />
      ) : (
        <Text style={[styles.buttonText, textStyle]}>{text}</Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonView: {
    flex: 1,
    height: 44,
    maxHeight: 44,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
  },
  buttonText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
  },
});

export default BaseButton;
