import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  TextInput,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@ant-design/react-native/lib/modal';
// import Picker from '@ant-design/react-native/lib/picker';
import Toast from '@ant-design/react-native/lib/toast';

import { colors, images, permission } from '../../../utils';
import { upload } from '../../../services/client/client';
import { useEffect } from 'react';

const genders = [
  {
    label: '男',
    value: 1,
  },
  {
    label: '女',
    value: 0,
  },
];

const AccInfo = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const [nickname, setNickname] = useState('');
  const [isNicknameModalVisible, setIsNicknameModalVisible] = useState(false);

  const goModContNumber = () => {
    navigation.navigate('ModContNumber');
  };

  const handleImagePicker = useCallback(
    async (data) => {
      if (data.didCancel || data.errorCode) {
        return;
      }
      if (!data.assets) {
        return;
      }
      if (data.assets.length < 1) {
        return;
      }
      if (data.assets && data.assets.length > 0) {
        const formData = new FormData();
        // formData.append('file', {
        //   name: data.assets[0].fileName as string,
        //   type: data.assets[0].type as string,
        //   uri:
        //     Platform.OS === 'ios'
        //       ? (data.assets[0].uri as string).replace('file://', '')
        //       : (data.assets[0].uri as string),
        // });
        const response = await upload(formData);
        if (response.success) {
          dispatch({
            type: 'client/changeAvatar',
            payload: {
              avatar: response.data,
            },
          });
        } else {
          Toast.info(response.msg, 1);
        }
      }
      return;
    },
    [dispatch],
  );

  // const onSelfieSelect = useCallback(() => {
  //   const pickerOption: CameraOptions = {
  //     mediaType: 'photo',
  //     maxWidth: 1920,
  //     maxHeight: 1920,
  //   };
  //   Modal.operation([
  //     {
  //       text: '进行拍照',
  //       onPress: () => {
  //         launchCamera(pickerOption, handleImagePicker);
  //       },
  //     },
  //     {
  //       text: '选择照片',
  //       onPress: () => {
  //         launchImageLibrary(pickerOption, handleImagePicker);
  //       },
  //     },
  //   ]);
  // }, [handleImagePicker]);

  const updateNickname = useCallback(() => {
    if (nickname.trim() === '') {
      Toast.info('请输入昵称');
    } else if (nickname.trim().length > 10) {
      Toast.info('昵称最多智能输入20个字符', 1);
    } else {
      dispatch({
        type: 'client/changeNickname',
        payload: { nickname },
      });
    }
  }, [dispatch, nickname]);

  const onUpdateGender = useCallback(
    (value) => {
      dispatch({
        type: 'client/changeGender',
        payload: {
          gender: value[0] ?? 1,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (Platform.OS === 'android') {
      permission.requestCameraPermission();
      permission.requestWriteExternalStoragePermission();
    }
  }, []);

  return (
    <SafeAreaView style={styles.flex1}>
      <TouchableOpacity
        // onPress={onSelfieSelect}
        style={styles.profileContainer}>
        <View style={styles.profileContentsItem}>
          <Text style={styles.profileItemText}>头像</Text>
          <View style={styles.profileItemGoArrow}>
            <Image
              source={
                currentClient.avatar
                  ? { uri: currentClient.avatar }
                  : images.DEFAULT_CLIENT_AVATAR
              }
              style={styles.profileImage}
            />
            {/* <Image
              source={images.PERSONAL_ARROW}
              style={[styles.arrowImage, styles.marginL8]}
            /> */}
          </View>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        // onPress={() => setIsNicknameModalVisible(true)}
        style={styles.profileContainer}>
        <View style={styles.profileContentsItem}>
          <Text style={styles.profileItemText}>昵称</Text>
          <View style={styles.profileItemGoArrow}>
            <Text style={styles.itemSecondText}>{currentClient.nickname}</Text>
            {/* <Image
              source={images.PERSONAL_ARROW}
              style={[styles.arrowImage, styles.marginL8]}
            /> */}
          </View>
        </View>
      </TouchableOpacity>
      <Modal
        title="修改昵称"
        visible={isNicknameModalVisible}
        transparent
        footer={[
          { text: '取消', onPress: () => { }, style: 'cancel' },
          { text: '确认', onPress: updateNickname },
        ]}
        onClose={() => setIsNicknameModalVisible(false)}>
        <TextInput
          autoFocus
          returnKeyType="done"
          placeholder="请输入昵称"
          defaultValue={currentClient.nickname}
          onChangeText={(val) => setNickname(val)}
          style={styles.nicknameInput}
        />
      </Modal>
      {/* <Picker
        title="性别选择"
        cols={1}
        data={genders}
        value={[currentClient.gender]}
        onChange={onUpdateGender}>
        <TouchableOpacity style={styles.profileContainer}>
          <View style={styles.profileContentsItem}>
            <Text style={styles.profileItemText}>性别</Text>
            <View style={styles.profileItemGoArrow}>
              <Text style={styles.itemSecondText}>
                {currentClient.gender === 1 ? '男' : '女'}
              </Text>
              <Image
                source={images.PERSONAL_ARROW}
                style={[styles.arrowImage, styles.marginL8]}
              />
            </View>
          </View>
        </TouchableOpacity>
      </Picker> */}
      <TouchableOpacity
        onPress={goModContNumber}
        style={styles.profileContainer}>
        <View style={styles.profileContentsItem}>
          <Text style={styles.profileItemText}>手机号</Text>
          <View style={styles.profileItemGoArrow}>
            <Text style={styles.itemSecondText}>
              {currentClient.phoneNumber ? currentClient.phoneNumber : '未绑定'}
            </Text>
            <Image
              source={images.PERSONAL_ARROW}
              style={[styles.arrowImage, styles.marginL8]}
            />
          </View>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('RealnameVerify');
        }}
        style={[styles.profileContainer, { marginTop: 16 }]}>
        <View style={styles.profileContentsItem}>
          <Text style={styles.profileItemText}>实名认证</Text>
          <View style={styles.profileItemGoArrow}>
            <Text style={styles.itemSecondText}>
              {currentClient.adapayRealnameMemberFlag ? '已认证' : '去认证'}
            </Text>
            <Image
              source={images.PERSONAL_ARROW}
              style={[styles.arrowImage, styles.marginL8]}
            />
          </View>
        </View>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  profileContainer: {
    paddingLeft: 16,
    backgroundColor: colors.WHITE,
  },
  profileImage: { width: 45, height: 45, borderRadius: 45 },
  arrowImage: { width: 8, height: 13 },
  profileContentsItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.MERCURY,
  },
  profileItemText: { color: colors.TUNDORA, fontSize: 15 },
  profileItemGoArrow: {
    padding: 16,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginL8: {
    marginLeft: 8,
  },
  itemSecondText: { color: colors.GRAY, fontSize: 14 },
  nicknameInput: {
    color: colors.TUNDORA,
    fontSize: 18,
    height: 26,
    marginTop: 20,
    marginHorizontal: 4,
    paddingVertical: 4,
    borderColor: colors.GUARDSMAN_RED,
    borderBottomWidth: 1,
  },
});

export default AccInfo;
