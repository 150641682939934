/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: lizhengkai
 * @Date: 2022-04-28 17:51:49
 * @FilePath: /msll_h5/src/screens/1.tsx
 */
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import MerchantHeader from './MerchantHeader';
import {
  Dimensions,
  FlatList,
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getArticlesByRuleId } from '../services/client/client';
import { colors, images } from '../utils';
import { MAllLoadedBox, MEmptyBox } from '../components';
import { encode as base64_encode } from 'base-64';
const { width } = Dimensions.get('window');
const EventMaterial = (props) => {
  const id = props.route.params.id;
  const ruleStartTime = props.route.params.ruleStartTime;
  const descriptionPk = props.route.params.descriptionPk;
  const business = props.route.params?.business;
  const merchantInfo = props.route.params?.merchantInfo;
  const navigation = useNavigation();
  const [data, setData] = useState([]);
  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );
  const height = useSelector((state: any) => state.screen.height);
  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      const res = await getArticlesByRuleId({
        currentPage: 1,
        pageSize: 100,
        ruleId: id,
        merchantId: currentMerchant.id,
      });

      setData(res.data.records);
    };
    fn();
  }, [business, currentMerchant.id, id]);

  const goDetail = (item) => {
    if (business) {
      dispatch({
        type: 'merchantArticle/getCurrentArticle',
        payload: {
          id: item.id,
        },
      });
      navigation.navigate('ArticlePreview');
    } else {
      dispatch({
        type: 'clientMerchant/getOrderDetail',
        payload: {
          articleId: item.id,
          merchantId: currentMerchant.id,
        },
      });
      props.navigation.push('OrderDetail', {
        articleId: base64_encode(item.id),
      });
    }
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.all} onPress={() => goDetail(item)}>
      {item.published && (
        <Image
          source={images.ORDER_PUBLIC_ICON}
          style={styles.topLeftLabelPublic}
        />
      )}
      <View style={styles.main}>
        <View style={{ flexDirection: 'row' }}>
          {item.tag && (
            <Image
              source={
                item.tag === '0'
                  ? null
                  : item.tag === '1'
                  ? images.LABEL_RED
                  : item.tag === '2'
                  ? images.LABEL_BLACK
                  : images.LABEL_GREEN
              }
              style={styles.topLeftGuessStatusLabel}
            />
          )}
          <Text style={{ fontSize: 16, width: width - 150 }}>{item.title}</Text>
        </View>
        <Text style={{ color: colors.RED_00, fontSize: 12 }}>
          {item.price}元
        </Text>
      </View>
      <View style={[styles.main, { marginTop: 10 }]}>
        <View style={{ flexDirection: 'row', marginLeft: 20 }}>
          <Text style={{ color: '#888888' }}>
            {dayjs(item.lastTime).format('MM-DD HH:mm')}
          </Text>
          {item.redLabel !== null && item.redLabel !== '' && (
            <>
              <View
                style={{
                  width: 2,
                  backgroundColor: colors.RED_00,
                  marginLeft: 6,
                  marginRight: 2,
                  height: 15,
                }}
              />
              <View style={styles.redLabel}>
                <Text style={styles.redLabelText}>{item.redLabel}</Text>
                <Text style={styles.lianText}>连红</Text>
              </View>
            </>
          )}
          {item.customLabel !== null && item.customLabel !== '' && (
            <View style={{ marginLeft: 3 }}>
              <Text style={styles.customLabel}>{item.customLabel}</Text>
            </View>
          )}
        </View>
        {item.refundable === 1 && (
          <Image
            source={images.ORDER_ITEM_LABEL_NOT_REFUNDED}
            style={styles.footerLabelImage}
          />
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <SafeAreaView style={[styles.mainContainer]}>
      <MerchantHeader merchantInfo={merchantInfo} isSmallSize={true} />
      <View style={styles.container}>
        <View style={styles.top}>
          <Text style={[styles.text]}>
            {dayjs(ruleStartTime).format('MM-DD HH:mm')}
          </Text>
          <Text style={[styles.text2, { fontSize: 20 }]}>{descriptionPk}</Text>
        </View>
        <Text style={styles.text}>以下为同一场赛事的所有料列表</Text>
        <FlatList
          style={{ height, paddingBottom: 250 }}
          data={data}
          renderItem={renderItem}
          ListEmptyComponent={
            <MEmptyBox
              title="暂无数据"
              followingStatus={currentMerchant.followingStatus}
            />
          }
          ListFooterComponent={<MAllLoadedBox />}
        />
      </View>
    </SafeAreaView>
  );
};
export default EventMaterial;

const styles = StyleSheet.create({
  redLabelText: {
    color: colors.WHITE,
    backgroundColor: colors.RED_00,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    fontWeight: 'bold',
  },
  lianText: {
    color: colors.RED_00,
    backgroundColor: colors.WHITE,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    borderColor: colors.RED_00,
    borderWidth: 1,
  },
  mainContainer: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
  },
  topLeftLabelPublic: {
    position: 'absolute',
    width: 34,
    height: 34,
    top: 0,
    left: 0,
  },
  topLeftGuessStatusLabel: {
    width: 16,
    height: 16,
    marginTop: 1,
    marginRight: 4,
  },
  top: {
    backgroundColor: colors.WHITE,
    height: 100,
    alignItems: 'center',
    width: '100%',
  },
  text: {
    marginTop: 16,
    color: '#888888',
  },
  text2: {
    marginTop: 16,
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  all: {
    width: width - 40,
    padding: 20,
    backgroundColor: colors.WHITE,
    marginTop: 16,
    borderRadius: 10,
    flex: 1,
  },
  footerLabelImage: { width: 52, height: 16 },
  customLabel: {
    color: colors.RED_00,
    borderColor: colors.RED_00,
    borderWidth: 1,
    borderRadius: 1,
    fontSize: 10,
    height: 15,
    paddingVertical: 1,
    paddingHorizontal: 2,
    paddingLeft: 2,
    lineHeight: 13,
  },
  redLabel: {
    flexDirection: 'row',
    marginRight: 6,
    borderRadius: 1,
    height: 15,
  },
});
