/* eslint-disable react-native/no-inline-styles */
/*
 * @Description: 商家合并码弹窗-合并码中商户列表
 * @Author: Eave Luo
 * @Date: 2024-03-16 01:05:36
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-05-10 04:23:28
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/MergeMerchantListModal.tsx
 */
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Modal from '@ant-design/react-native/lib/modal';

import { images } from '../utils';
import { MAllLoadedBox, MMerchantInfoItem } from '../components';

const MergeMerchantListModal = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const mergedMerchantList = useSelector(
    (state: any) => state.clientMerchant.mergedMerchantList,
  );

  const [modalVisible, setModalVisible] = useState(false);

  const fetchData = useCallback(async () => {
    let mergeCodeId = await AsyncStorage.getItem('mergeCodeId');
    mergeCodeId &&
      dispatch({
        type: 'clientMerchant/getMergeCodeInfo',
        payload: {
          id: mergeCodeId,
        },
      });
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setTimeout(() => {
      setModalVisible(mergedMerchantList.length > 0);
    }, 500);
  }, [mergedMerchantList]);

  const switchCurrentMerchant = useCallback(
    (id) => {
      Modal.alert('提示', '是否切换到该商家？', [
        {
          text: '取消',
          style: 'cancel',
        },
        {
          text: '确认',
          onPress: () => {
            dispatch({
              type: 'clientMerchant/getCurrentMerchant',
              payload: { merchantId: id },
            });
            dispatch({
              type: 'clientMerchant/getArticleList',
              payload: { merchantId: id, mode: '0' },
            });
            setModalVisible(false);
            navigation.navigate('home');
          },
        },
      ]);
    },
    [dispatch, navigation],
  );

  const closeModal = async () => {
    setModalVisible(false);
    // 关闭弹窗后清除合并码
    await AsyncStorage.removeItem('mergeCodeId');
  };

  const renderItem = ({ item }) => (
    <MMerchantInfoItem
      from="home"
      profileIcon={item.avatar ? { uri: item.avatar } : images.LOGO}
      name={item.name}
      desc={item.intro}
      noticeNumber={item.articleCount}
      onClick={() => switchCurrentMerchant(item.id)}
    />
  );

  return (
    <Modal
      visible={modalVisible}
      maskClosable
      transparent
      onClose={() => closeModal()}
      style={styles.modalStyle}>
      <View>
        <View style={styles.centeredView}>
          <FlatList
            data={mergedMerchantList}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            refreshing={false}
            ListFooterComponent={<MAllLoadedBox style={{ marginBottom: 10 }} />}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalStyle: {
    flex: 1,
    borderWidth: 0,
    zIndex: 9999,
  },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default MergeMerchantListModal;
