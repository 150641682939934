import React, { useCallback, useLayoutEffect, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import dayjs from 'dayjs';
import { colors } from '../../../utils';
import { MOrderDetails } from '../../../components';
const OrderDetail = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const route = useRoute();

  const { id: articleId } = route.params as any;

  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );
  const currentOrderDetail = useSelector(
    (state: any) => state.clientOrder.currentOrderDetail,
  );
  const isLoading = useSelector((state: any) => state.clientMerchant.isLoading);
  const [scrollWidth, setScrollWidth] = useState(0);
  useLayoutEffect(() => {
    navigation.setOptions({
      title: `${currentOrderDetail?.headerTitle ?? '订单'}`,
      headerTitle: currentOrderDetail?.headerTitle ?? '订单',
    });
  }, [currentOrderDetail, navigation]);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'client/getCurrentClient',
        payload: {},
      });
      dispatch({
        type: 'clientOrder/getProducts',
        payload: {},
      });
    }, [dispatch]),
  );

  const onHome = () => {
    props.navigation.push('Home');
  };
  useFocusEffect(
    useCallback(() => {
      if (articleId) {
        dispatch({
          type: 'clientMerchant/getOrderDetail',
          payload: {
            articleId,
            merchantId: currentMerchant.id,
          },
        });
        dispatch({
          type: 'parameter/getByCode',
          payload: {
            code: 'is_open_wechat_pay',
          },
        });
        dispatch({
          type: 'parameter/getByCode',
          payload: {
            code: 'is_open_alipay',
          },
        });
      }
    }, [currentMerchant.id, dispatch, articleId]),
  );
  return Object.keys(currentOrderDetail.data).length === 0 ||
    isLoading ? null : (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView
        style={styles.mainBody}
        onLayout={(evt) => {
          const { width } = evt.nativeEvent.layout;
          if (!scrollWidth || width !== scrollWidth) {
            setScrollWidth(width);
          }
        }}>
        <MOrderDetails
          isShowTitle={false}
          merchantInfo={currentMerchant}
          onHomeClick={onHome}
          articleId={articleId}
          title={currentOrderDetail.data.title}
          mode={currentOrderDetail.data.mode}
          period={currentOrderDetail.data.period}
          refundable={currentOrderDetail.data.refundable === 1}
          isWatermarked={currentOrderDetail.data.isWatermarked}
          limitTime={currentOrderDetail.data.limitTime}
          createTime={currentOrderDetail.data.createTime}
          updateTime={currentOrderDetail.updateTime}
          publishTime={currentOrderDetail.data.publishTime}
          date={
            currentOrderDetail.data.updateTime
              ? currentOrderDetail.data.updateTime
              : currentOrderDetail.data.createTime
          }
          intro={currentOrderDetail.data.intro}
          introImages={currentOrderDetail.data.introImages}
          articleChunks={currentOrderDetail.data.articleChunks ?? []}
          watermarkUrl={currentOrderDetail.data.watermarkUrl}
          redLabel={currentOrderDetail.data.redLabel}
          customLabel={currentOrderDetail.data.customLabel}
          tag={currentOrderDetail.data.tag}
          isPay={currentOrderDetail.data.isPay}
          articlePlanId={currentOrderDetail.data.articlePlanId}
          merchantId={currentOrderDetail.data.merchantId}
          publishFlg={currentOrderDetail.data.publishFlg}
        />
      </ScrollView>
      {(!currentOrderDetail.data.articleChunks ||
        currentOrderDetail.data.articleChunks.length === 0) && (
        <View style={styles.purchaseSection}>
          <View>
            <View style={styles.purchasePriceSection}>
              {currentOrderDetail.data.amount !==
                currentOrderDetail.data.price && (
                <View>
                  <Text style={styles.purchasePriceOrigin}>
                    原价 {currentOrderDetail.data.price}元 您为
                    {currentOrderDetail.data.amount <
                    currentOrderDetail.data.price
                      ? `${
                          parseFloat(
                            (
                              currentOrderDetail.data.amount /
                              currentOrderDetail.data.price
                            ).toPrecision(12),
                          ) * 10
                        }折`
                      : `${parseFloat(
                          (
                            currentOrderDetail.data.amount /
                            currentOrderDetail.data.price
                          ).toPrecision(12),
                        )}倍`}
                    用户
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      )}
      {currentOrderDetail.data.limitTime !== '' &&
        currentOrderDetail.data.limitTime !== null && (
          <View style={styles.purchaseSection}>
            <View>
              <View style={styles.purchasePriceSection}>
                <View style={styles.purchasePriceSectionRow}>
                  <Text style={styles.limitTime}>
                    {dayjs(currentOrderDetail.data.limitTime).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                  </Text>
                  <Text style={styles.purchasePriceTitle}>到期</Text>
                </View>
              </View>
            </View>
          </View>
        )}
    </SafeAreaView>
  );
};

// 点击支付时弹出层样式

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  mainBody: {
    paddingHorizontal: 16,
  },
  headerTitle: {
    color: colors.GRAY20,
    fontSize: 18,
    lineHeight: 25,
    marginBottom: 4,
    fontWeight: '700',
  },
  labelSection: { flexDirection: 'row' },
  topLeftPeriodSection: {
    backgroundColor: colors.MEDIUM_SLATE_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    borderRadius: 4,
    marginRight: 8,
    padding: 2.5,
  },
  topLeftPeriodText: { color: colors.WHITE, fontSize: 11 },
  labelNotRefundedImage: { width: 52, height: 16 },
  articleDate: { color: colors.GRAY, fontSize: 13, marginTop: 8 },
  watermarkBG: {
    resizeMode: 'repeat',
  },
  contentSection: { flex: 1, marginTop: 8, marginBottom: 8 },
  orderLogo: {
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 8,
    width: 151,
    height: 63,
  },
  contentText: { color: colors.TUNDORA, fontSize: 14, lineHeight: 20 },
  noDataSection: { alignItems: 'center', marginTop: 40 },
  noDataImage: { width: 235, height: 160 },
  noDataText: { color: colors.DUSTY_GRAY, fontSize: 13 },
  purchaseSection: {
    backgroundColor: colors.WILD_SAND,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    width: '100%',
    bottom: 0,
  },
  purchasePriceSectionRow: { flexDirection: 'row', alignItems: 'center' },
  purchasePriceSection: { flexDirection: 'column', alignItems: 'center' },
  purchasePriceTitle: { color: colors.TUNDORA, fontSize: 16, marginRight: 4 },
  purchasePriceDiscount: {
    color: colors.RED_00,
    fontSize: 16,
    fontWeight: '700',
    marginRight: 7.5,
  },
  purchasePriceOrigin: {
    color: colors.GRAY,
    fontSize: 12,
    marginRight: 7.5,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  purchasePriceDiscountDesc: { color: colors.GRAY, fontSize: 12 },
  purchaseBtnSection: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 35,
    width: 120,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  purchaseBtnText: { color: colors.WHITE, fontSize: 18 },
  limitTime: {
    color: colors.RED_00,
    fontSize: 16,
    fontWeight: '700',
    marginRight: 7.5,
  },
  disabledModal: {
    height: 200,
  },
  confirmModalMainBody: {
    flex: 0,
    backgroundColor: colors.WHITE,
    height: 240,
    width: 290,
    alignSelf: 'center',
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  isDisabledModalTitle: {
    color: colors.TUNDORA,
    fontSize: 20,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  isDisabledModalText: {
    color: colors.TUNDORA,
    fontSize: 16,
    alignSelf: 'center',
  },
  isDisabledModalBtn: {
    width: 160,
    height: 47,
    alignSelf: 'center',
    borderRadius: 7.5,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
    marginBottom: 70,
  },
  isDisabledModalBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
  },
});

export default OrderDetail;
