/*
 * @Author: your name
 * @Date: 2021-07-20 19:23:36
 * @LastEditTime: 2023-02-04 05:18:25
 * @LastEditors: Evan huang
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/screens/Link.tsx
 */
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import Flex from '@ant-design/react-native/lib/flex';

const LinkScreen = () => {
  return (
    <Flex justify="center" style={styles.layout}>
      <Text style={styles.tipText}>当前访问的链接授权无效</Text>
      <Text style={styles.tipText}>请使用正确的链接打开</Text>
    </Flex>
  );
};

const styles = StyleSheet.create({
  layout: { flex: 1, flexDirection: 'column' },
  tipText: {
    color: '#444',
    fontSize: 20,
    fontWeight: '800',
  },
});

export default LinkScreen;
