import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import Modal from '@ant-design/react-native/lib/modal';

import { colors, images } from '../utils';

const MMerchantInfoItem = (props) => {
  const {
    profileIcon = images.MERCHANT_PROFILE_DEFAULT,
    name = '',
    desc = '',
    from = '',
    following = null,
    noticeNumber = 0,
    onClick = () => {},
    onFollow = () => {},
    onUnfollow = () => {},
  } = props;

  const follow = () => {
    Modal.alert('提示', '确认关注该作者？', [
      {
        text: '取消',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: '确认',
        onPress: onFollow,
      },
    ]);
  };

  const unfollow = () => {
    Modal.alert('提示', '确认不再关注？', [
      {
        text: '取消',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: '确认',
        onPress: onUnfollow,
      },
    ]);
  };

  return (
    <>
      <TouchableOpacity onPress={onClick} style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View>
            <Image
              source={profileIcon}
              style={styles.merchantItemProfileImage}
            />
            {noticeNumber > 0 && (
              <View style={styles.merchantItemNoticeNumberSection}>
                <Text style={styles.merchantItemNoticeNumber}>
                  {noticeNumber > 99 ? '99+' : noticeNumber}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>{name}</Text>
            <Text style={styles.merchantItemDesc}>{desc}</Text>
          </View>
        </View>
        {from !== 'home' && (
          <View style={styles.merchantItemBtnSection}>
            {following === 0 || following === 1 ? (
              <TouchableOpacity
                style={styles.merchantItemBtn}
                onPress={unfollow}>
                <Text style={styles.merchantItemBtnText}>已关注</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.merchantItemBtnFocused}
                onPress={follow}>
                <Text style={styles.merchantItemBtnTextFocused}>关注</Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );
};

const styles = StyleSheet.create({
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemProfileImage: { width: 60, height: 60, borderRadius: 60 },
  merchantItemNoticeNumberSection: {
    position: 'absolute',
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    backgroundColor: colors.RED_ORANGE,
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    top: -3.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  merchantItemNoticeNumber: { color: colors.WHITE, fontSize: 11 },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 4,
  },
  merchantItemDesc: {
    flex: 1,
    color: colors.GRAY,
    fontSize: 12,
    lineHeight: 16,
    textAlign: 'justify',
  },
  merchantItemBtnSection: { marginLeft: 12 },
  merchantItemBtn: {
    width: 60,
    height: 20,
    borderRadius: 19,
    borderColor: colors.GRAY,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  merchantItemBtnFocused: {
    backgroundColor: colors.GUARDSMAN_RED,
    width: 60,
    height: 20,
    borderRadius: 19,
    borderColor: colors.GUARDSMAN_RED,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  merchantItemBtnText: {
    color: colors.GRAY,
    fontSize: 12,
  },
  merchantItemBtnTextFocused: {
    color: colors.WHITE,
    fontSize: 12,
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
});

export default MMerchantInfoItem;
