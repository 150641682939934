/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  Dimensions,
} from 'react-native';
import dayjs from 'dayjs';

import { colors, images } from '../utils';
const { width } = Dimensions.get('window');

const OrderItem = (props) => {
  const {
    id = 0,
    title = '',
    lastTime = '',
    mode = 0,
    published = true,
    price = 0,
    refundable = false,
    period = 0,
    tag = '0',
    goDetail = () => {},
    gameData = '',
    redLabel,
    customLabel,
    isPresale = 1,
    presaleTime = '',
  } = props;

  const source =
    tag === '0'
      ? null
      : tag === '1'
      ? images.LABEL_RED
      : tag === '2'
      ? images.LABEL_BLACK
      : images.LABEL_GREEN;

  const paddingLeft = mode === 1 ? 75 : 36;

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={[orderItemStyles.itemBody, { paddingLeft }]}
      onPress={() => goDetail(id)}>
      <View style={orderItemStyles.topLeftLabel}>
        {published && (
          <Image
            source={images.ORDER_PUBLIC_ICON}
            style={orderItemStyles.topLeftLabelPublic}
          />
        )}
        {!published &&
          isPresale === 1 &&
          presaleTime !== null &&
          presaleTime !== '' &&
          dayjs(presaleTime).isAfter(dayjs()) && (
            <Image
              source={images.ORDER_PRESALE_ICON}
              style={orderItemStyles.topLeftLabelPublic}
            />
          )}
        <View style={orderItemStyles.topLeftGuessStatusSection}>
          {mode === 1 && period > 0 && (
            <View style={orderItemStyles.topLeftPeriodSection}>
              <Text style={orderItemStyles.topLeftPeriodText}>
                包时{period}天
              </Text>
            </View>
          )}
          {source && (
            <Image
              source={source}
              style={orderItemStyles.topLeftGuessStatusLabel}
            />
          )}
        </View>
      </View>
      <View style={orderItemStyles.mainContentSection}>
        <Text style={orderItemStyles.mainContentTitle}>{title}</Text>
        <View style={orderItemStyles.mainContentPriceSection}>
          <Text style={orderItemStyles.mainContentPriceValue}>{price}</Text>
          <Text style={orderItemStyles.mainContentPriceDesc}>元</Text>
        </View>
      </View>
      <View style={orderItemStyles.footerSection}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={orderItemStyles.footerDate}>
            {isPresale === 1 &&
            presaleTime !== null &&
            presaleTime !== '' &&
            dayjs(presaleTime).isAfter(dayjs())
              ? dayjs(presaleTime).format('YYYY-MM-DD HH:mm')
              : gameData
              ? gameData
              : dayjs(lastTime).format('YYYY-MM-DD HH:mm')}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            {redLabel !== null && redLabel !== '' && (
              <>
                <View
                  style={{
                    width: 2,
                    backgroundColor: colors.RED_00,
                    marginLeft: 6,
                    marginRight: 2,
                    height: 15,
                  }}
                />
                <View style={styles.redLabel}>
                  <Text style={styles.redLabelText}>{redLabel}</Text>
                  <Text style={styles.lianText}>连红</Text>
                </View>
              </>
            )}
            {customLabel !== null && customLabel !== '' && (
              <View style={{ marginLeft: 3 }}>
                <Text style={styles.customLabel}>{customLabel}</Text>
              </View>
            )}
          </View>
        </View>
        {refundable === 1 && (
          <Image
            source={images.ORDER_ITEM_LABEL_NOT_REFUNDED}
            style={orderItemStyles.footerOrderStatus}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const orderItemStyles = StyleSheet.create({
  itemBody: {
    flex: 1,
    marginHorizontal: 16,
    paddingVertical: 16,
    paddingRight: 12,
    borderRadius: 8,
    backgroundColor: colors.WHITE,
    marginBottom: 8,
  },
  topLeftLabel: { position: 'absolute', left: 0, top: 0 },
  topLeftLabelPublic: { position: 'absolute', width: 34, height: 34 },
  topLeftGuessStatusSection: {
    position: 'absolute',
    left: 16,
    top: 20,
    width: 51,
  },
  //包时样式
  topLeftPeriodSection: {
    backgroundColor: colors.MEDIUM_SLATE_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    padding: 2,
    borderRadius: 4,
    width: '100%',
  },
  topLeftPeriodText: { color: colors.WHITE, fontSize: 11 },
  topLeftGuessStatusLabel: {
    width: 16,
    height: 16,
  },
  mainContentSection: { flexDirection: 'row', justifyContent: 'space-between' },
  mainContentTitle: {
    flex: 1,
    color: colors.GRAY20,
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'justify',
    marginRight: 21,
    lineHeight: 23,
    width: width - 140,
  },
  mainContentPriceSection: { flexDirection: 'row' },
  mainContentPriceValue: {
    color: colors.GUARDSMAN_RED,
    fontSize: 20,
    marginRight: 4,
  },
  mainContentPriceDesc: {
    color: colors.GUARDSMAN_RED,
    fontSize: 10,
    marginTop: 9,
  },
  footerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    height: 13,
  },
  footerDate: { color: colors.GRAY, fontSize: 12 },
  footerOrderStatus: { width: 52, height: 16 },
});

const styles = StyleSheet.create({
  redLabelText: {
    color: colors.WHITE,
    backgroundColor: colors.RED_00,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    fontWeight: 'bold',
  },
  lianText: {
    color: colors.RED_00,
    backgroundColor: colors.WHITE,
    fontSize: 10,
    paddingLeft: 2,
    paddingTop: 1,
    lineHeight: 13,
    borderColor: colors.RED_00,
    borderWidth: 1,
  },
  customLabel: {
    color: colors.RED_00,
    borderColor: colors.RED_00,
    borderWidth: 1,
    borderRadius: 1,
    fontSize: 10,
    height: 15,
    paddingHorizontal: 2,
    paddingLeft: 2,
    lineHeight: 13,
  },
  redLabel: {
    flexDirection: 'row',
    marginRight: 6,
    borderRadius: 1,
    height: 15,
  },
});

export default OrderItem;
