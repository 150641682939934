import { create } from 'dva-core';
import immer from 'dva-immer';
// import createLoading from 'dva-loading';
// import { createLogger } from 'redux-logger';

let app;

const createApp = (opt) => {
  // opt.onAction = [createLogger()];

  app = create(opt);

  // app.use(createLoading({}));
  app.use(immer());

  if (!(global as any).registered) {
    opt.models.forEach((model) => {
      app.model(model);
    });
  }

  (global as any).registered = true;

  app.start();

  const store = app._store;
  app.getStore = () => store;

  const dispatch = store.dispatch;
  app.dispatch = dispatch;

  return app;
};

export default {
  createApp,
  getDispatch() {
    return app.dispatch;
  },
};
