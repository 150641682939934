/*
 * @Description: 投诉按钮
 * @Author: Evan huang
 * @Date: 2024-09-05 14:02:36
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-09-05 14:37:41
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/components/SuggestButton.tsx
 */
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { images } from '../utils';
import { useNavigation } from '@react-navigation/native';
const SuggestButton = ({ isDetail = false }) => {
  const navigation = useNavigation();
  const suggestView = (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('Suggest');
      }}
      style={[styles.button, isDetail && styles.articleButton]}>
      <Image source={images.TOUSU} style={styles.headerRightItemImage} />
      <Text style={isDetail ? styles.articleButtonLabel : styles.buttonLabel}>
        投诉TA
      </Text>
    </TouchableOpacity>
  );
  return isDetail ? (
    suggestView
  ) : (
    <View style={styles.buttonView}>{suggestView}</View>
  );
};

const styles = StyleSheet.create({
  buttonView: { marginLeft: 9, marginRight: 9 },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRightItemImage: {
    width: 16,
    height: 16,
    backgroundColor: '#D90000',
    borderRadius: 8,
  },
  buttonLabel: { color: 'white', marginLeft: 5 },
  articleButton: {
    borderRadius: 15,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: '#D90000',
    backgroundColor: 'transparent',
  },
  articleButtonLabel: {
    color: '#D90000',
    fontSize: 12,
    lineHeight: 18.5,
    textAlign: 'justify',
  },
});

export default SuggestButton;
