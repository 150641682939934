import Toast from '@ant-design/react-native/lib/toast';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  followMerchant,
  getAllMerchantList,
  getCurrentMerchant,
  getMerchantArticles,
  getMerchantList,
  getMergeCodeInfo,
  getOrderArticle,
  sendMerchant,
  unfollowMerchant,
} from '../../services/client/merchant';

const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_CURRENT_MERCHANT = 'SET_CURRENT_MERCHANT';
const SET_MERCHANT_ARTICLES_TA = 'SET_MERCHANT_ARTICLES_TA';
const SET_MERCHANT_ARTICLES_PERIOD = 'SET_MERCHANT_ARTICLES_PERIOD';
const SET_SEARCHED_MERCHANT_ARTICLES = 'SET_SEARCHED_MERCHANT_ARTICLES';
const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';
const SET_CURRENT_GAME = 'SET_CURRENT_GAME';
const SET_MERCHANT_LIST = 'SET_MERCHANT_LIST';
const SET_ALL_MERCHANT_LIST = 'SET_ALL_MERCHANT_LIST';
const CURRENT_MERCHANT_KEY = 'currentMerchantId';
const SET_MERGECHANT_LIST = 'SET_MERGECHANT_LIST';

export default {
  namespace: 'clientMerchant',
  state: {
    isLoading: true,
    merchantList: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    allMerchantList: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    currentMerchant: {},
    articleListTA: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    articleListPeriod: {
      page: 1,
      isAllLoaded: false,
      records: [],
    },
    searchedMerchantArticleList: {
      page: 0,
      isAllLoaded: false,
      records: [],
    },
    currentArticle: {},
    currentGame: '',
    /** 合并码商家信息列表 */
    mergedMerchantList: [],
  },
  effects: {
    //获取指定商户信息
    *getCurrentMerchant({ payload, callback }, { put, call }) {
      const { success, code, data, msg } = yield call(
        getCurrentMerchant,
        payload,
      );
      if (success) {
        yield put({
          type: SET_CURRENT_MERCHANT,
          payload: data,
        });
        callback && callback(data);
      } else {
        //未关注则先做关注后刷新
        if (code === 39) {
          yield put({
            type: 'followMerchant',
            payload,
            callback,
          });
        } else {
          Toast.info(msg, 1);
        }
      }

      yield put({
        type: SET_IS_LOADING,
        payload: false,
      });
    },
    /** 根据合并码id获取合并码商家信息 */
    *getMergeCodeInfo({ payload }, { put, call }) {
      const { success, data, msg } = yield call(getMergeCodeInfo, payload);
      if (success) {
        yield put({
          type: SET_MERGECHANT_LIST,
          payload: data,
        });
      } else {
        Toast.info(msg, 1);
      }
    },
    *getArticleList({ payload }, { put, call }) {
      const { success, data, code, msg } = yield call(
        getMerchantArticles,
        payload,
      );

      if (success) {
        if (payload.keyword) {
          yield put({
            type: SET_SEARCHED_MERCHANT_ARTICLES,
            payload: {
              page: payload.page,
              records: data,
            },
          });
        }
        if (payload.mode === '0') {
          yield put({
            type: SET_MERCHANT_ARTICLES_TA,
            payload: {
              page: payload.page,
              records: data,
            },
          });
        } else if (payload.mode === '2') {
          yield put({
            type: SET_MERCHANT_ARTICLES_PERIOD,
            payload: {
              page: payload.page,
              records: data,
            },
          });
        }
      } else {
        Toast.info(msg, 1);
        if (code === 40) {
          yield put({
            type: 'getCurrentMerchant',
            payload: {
              merchantId: payload.merchantId,
            },
          });
          yield put({
            type: SET_MERCHANT_ARTICLES_TA,
            payload: {
              page: payload.page,
              records: [],
            },
          });
          yield put({
            type: SET_MERCHANT_ARTICLES_PERIOD,
            payload: {
              page: payload.page,
              records: [],
            },
          });
        }
      }
    },
    *getOrderDetail({ payload }, { put, call }) {
      const { success, data, code, msg } = yield call(getOrderArticle, payload);

      if (success) {
        yield put({
          type: SET_CURRENT_ARTICLE,
          payload: data,
        });
      } else {
        yield put({
          type: SET_CURRENT_ARTICLE,
          payload: {},
        });
        Toast.info(msg, 1);
        if (code === 40) {
          yield put({
            type: SET_MERCHANT_ARTICLES_TA,
            payload: {
              page: payload.page,
              records: [],
            },
          });
          yield put({
            type: SET_MERCHANT_ARTICLES_PERIOD,
            payload: {
              page: payload.page,
              records: [],
            },
          });
        }
      }
    },
    *getGameData({ payload }, { put }) {
      yield put({
        type: SET_CURRENT_GAME,
        payload: payload.gameData,
      });
    },
    /** 查询关注商家 */
    *getMerchantList({ payload }, { put, call }) {
      const response = yield call(getMerchantList, payload);
      if (response.success) {
        yield put({
          type: SET_MERCHANT_LIST,
          payload: {
            page: payload.page,
            records: response.data,
          },
        });
      }
    },
    /** 查询所有商家 */
    *getAllMerchantList({ payload }, { put, call }) {
      const { success, data = [] } = yield call(getAllMerchantList, payload);
      if (success) {
        yield put({
          type: SET_ALL_MERCHANT_LIST,
          payload: {
            page: payload.page,
            records: data,
          },
        });
        const currentMerchantId = yield AsyncStorage.getItem(
          CURRENT_MERCHANT_KEY,
        );
        //当前商家不存在时自动加载第一个已关注商家
        if (!currentMerchantId && data) {
          const followMerchants = data.filter(
            (merchant) => merchant.following === 1,
          );
          let newMerchantId: string = '';
          if (followMerchants.length > 0) {
            newMerchantId = followMerchants[0].id;
            const { data: currentMerchantInfo } = yield call(
              getCurrentMerchant,
              {
                merchantId: newMerchantId,
              },
            );
            yield put({
              type: SET_CURRENT_MERCHANT,
              payload: currentMerchantInfo,
            });
            //获取TA的料
            yield put({
              type: 'getArticleList',
              payload: { merchantId: newMerchantId, mode: '0' },
            });
          }
        }
      }
    },
    *followMerchant({ payload, callback }, { put, call }) {
      const response = yield call(followMerchant, payload);
      if (response.success) {
        yield put({
          type: 'getCurrentMerchant',
          payload: { merchantId: response.data.merchantId },
          callback,
        });
      } else {
        Toast.fail(response.msg, 1);
      }
    },
    *unfollowMerchant({ payload, callback }, { call, put }) {
      const { success, msg } = yield call(unfollowMerchant, payload);
      if (!success) {
        Toast.info(msg, 1);
      } else {
        //正常情况，进行是否当前商家切换，是的则进行清空，后续查询商家列表会自动切换至第一个已关注商户
        const currMerchantId = yield AsyncStorage.getItem(CURRENT_MERCHANT_KEY);
        //如果取消关注和当前商户一致则清除当前商户
        if (Number(currMerchantId) === payload.merchantId) {
          yield put({
            type: SET_CURRENT_MERCHANT,
            payload: {},
          });
        }
        callback && callback();
      }
    },
    *sendMerchant({ payload }, { call }) {
      const response = yield call(sendMerchant, payload);
      if (!response.success) {
        Toast.info(response.msg, 1);
      }
    },
  },
  reducers: {
    SET_IS_LOADING(state, { payload }) {
      state.isLoading = payload;
    },
    SET_MERCHANT_LIST(state, { payload }) {
      state.merchantList.page = payload.page + 1;
      if (payload.page > 1) {
        state.merchantList.records = state.merchantList.records.concat(
          payload.records || [],
        );
      } else {
        state.merchantList.records = payload.records || [];
      }
      state.merchantList.isAllLoaded = (payload.records || []).length < 10;
      if ((payload.records || []).length < 10) {
        state.merchantList.page = 1;
      }
      if (!state.currentMerchant.id) {
        if (state.merchantList.records.length > 0) {
          state.currentMerchant = state.merchantList.records[0];
        }
      }
    },
    SET_ALL_MERCHANT_LIST(state, { payload }) {
      state.allMerchantList.page = payload.page + 1;
      if (payload.page > 1) {
        state.allMerchantList.records = state.allMerchantList.records.concat(
          payload.records || [],
        );
      } else {
        state.allMerchantList.records = payload.records || [];
      }
      state.allMerchantList.isAllLoaded = (payload.records || []).length < 10;
      if ((payload.records || []).length < 10) {
        state.allMerchantList.page = 1;
      }
    },
    SET_CURRENT_MERCHANT(state, { payload }) {
      if (payload?.id) {
        AsyncStorage.setItem(CURRENT_MERCHANT_KEY, payload.id.toString());
      } else {
        AsyncStorage.removeItem(CURRENT_MERCHANT_KEY);
        const defaultList = {
          page: 1,
          isAllLoaded: false,
          records: [],
        };
        state.articleListTA = { ...defaultList };
        state.articleListPeriod = { ...defaultList };
      }
      state.currentMerchant = payload || {};
    },
    SET_MERCHANT_ARTICLES_TA(state, { payload }) {
      if (state.articleListTA.page === payload.page || payload.page === 1) {
        state.articleListTA.page = payload.page + 1;
        if (payload.page > 1) {
          state.articleListTA.records = state.articleListTA.records.concat(
            payload.records || [],
          );
        } else {
          state.articleListTA.records = payload.records || [];
        }
        state.articleListTA.isAllLoaded = (payload.records || []).length < 10;
        if ((payload.records || []).length < 10) {
          state.articleListTA.page = 1;
        }
      }
    },
    SET_MERCHANT_ARTICLES_PERIOD(state, { payload }) {
      if (state.articleListPeriod.page === payload.page || payload.page === 1) {
        state.articleListPeriod.page = payload.page + 1;
        if (payload.page > 1) {
          state.articleListPeriod.records =
            state.articleListPeriod.records.concat(payload.records || []);
        } else {
          state.articleListPeriod.records = payload.records || [];
        }
        state.articleListPeriod.isAllLoaded = (payload.records || []) < 10;
        if ((payload.records || []) < 10) {
          state.articleListPeriod.page = 1;
        }
      }
    },
    SET_SEARCHED_MERCHANT_ARTICLES(state, { payload }) {
      state.searchedMerchantArticleList.page = payload.page + 1;
      if (payload.page > 1) {
        state.searchedMerchantArticleList.records =
          state.searchedMerchantArticleList.records.concat(
            payload.records || [],
          );
      } else {
        state.searchedMerchantArticleList.records = payload.records || [];
      }
      state.searchedMerchantArticleList.isAllLoaded =
        (payload.records || []).length < 10;
      if ((payload.records || []).length < 10) {
        state.searchedMerchantArticleList.page = 1;
      }
    },
    SET_CURRENT_ARTICLE(state, { payload }) {
      state.currentArticle = payload;
    },
    SET_CURRENT_GAME(state, { payload }) {
      state.currentGame = payload;
    },
    SET_MERGECHANT_LIST(state, { payload }) {
      state.mergedMerchantList = payload;
    },
  },
};
