/*
 * @Description: 兼容IOS在微信中获取高度问题
 * @Author: Evan huang
 * @Date: 2021-08-03 20:57:35
 * @LastEditors: Evan huang
 * @LastEditTime: 2021-08-03 20:58:25
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/models/screen.ts
 */
export default {
  namespace: 'screen',
  state: {
    height: 0,
  },
  reducers: {
    setScreenHeight(state, { payload }) {
      state.height = payload;
    },
  },
};
