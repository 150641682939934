/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Animated,
  Easing,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from '@react-navigation/native';
import { MCustomerSupportModal, MOrderDetails } from '../components';
import { getByCode } from '../services/open/parameter';
import dayjs from 'dayjs';
import Modal from '@ant-design/react-native/lib/modal';
import { colors, images } from '../utils';
import { decode as base64_decode } from 'base-64';
import PayModal, { PayMethodItem } from './PayModal';
import Toast from '@ant-design/react-native/lib/toast';
import { navigate } from '../navigators/RootNavigation';

const fadeInAnimated = new Animated.Value(0.4);
const animateGoBack = () => {
  Animated.timing(fadeInAnimated, {
    toValue: 0.4,
    duration: 800,
    easing: Easing.linear,
    useNativeDriver: true,
  }).start(() => animateGoAhead());
};

const animateGoAhead = () => {
  Animated.timing(fadeInAnimated, {
    toValue: 1,
    duration: 800,
    easing: Easing.linear,
    useNativeDriver: true,
  }).start(() => animateGoBack());
};

const OrderDetailScreen = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const route = useRoute();

  const [width, setWidth] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [isPayModal, setIsPayModal] = useState(false);
  const [pingModalVisible, setPingModalVisible] = useState(false);
  const [wechatModalVisible, setWechatModalVisible] = useState(false);
  const [isBuyDoing, setIsBuyDoing] = useState(false);
  const [isDisabledModal, setIsDisabledModal] = useState(false);
  const [kf, setKf] = useState('');
  const [weichat, setWeichat] = useState('');

  useEffect(() => {
    setEwm();
  }, [dispatch]);

  const setEwm = async () => {
    let res = await getByCode({ code: 'platform_support_qrcode' });
    let resObj = await getByCode({ code: 'platform_support_wechat' });
    let ewm = res.data[0].itemValue;
    let wx = resObj.data[0].itemValue;
    setKf(ewm);
    setWeichat(wx);
  };
  //多通道不考虑预选支付方式
  // 默认支付方式勾选，若是ios则默认勾选3余额支付，若余额不足则默认勾选4充值，若是Android再看微信通道是否打开，打开则1，否则看支付宝通道是否打开，打开则2，否则看余额是否够。不够则4，否则3
  const [buyMethod, setBuyMethod] = useState<PayMethodItem>(
    null as unknown as PayMethodItem,
  );

  // 支付前处理
  const onBeforePurchase = () => {
    if (!currentArticle.isPurchaseAllowed) {
      Toast.fail(
        `抱歉，该商家设置需要累计消费达到${currentArticle.thresholdAmount}元后方可购买此资料`,
      );
      return;
    }
    //超过24小时提示的判断提示，createTime时间格式为：2023-11-01 22:00:00
    if (
      currentArticle.createTime &&
      dayjs().diff(dayjs(currentArticle.createTime), 'hour') > 24
    ) {
      Modal.alert('购买提示', '商品已发布超过24小时，请确认是否需要购买？', [
        {
          text: '取消',
        },
        {
          text: '确认购买',
          style: '',
          onPress: onPurchase,
        },
      ]);
    } else {
      onPurchase();
    }
  };

  // trade_no 支付跳转使用，点金计划兼容 10/18
  const { articleId, from, trade_no } = route.params as any;

  const height = useSelector((state: any) => state.screen.height);

  const currentClient = useSelector((state: any) => state.client.currentClient);

  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );
  const currentArticle = useSelector(
    (state: any) => state.clientMerchant.currentArticle,
  );

  const { merchantId } = currentArticle;
  const { id: currMerchantId } = currentMerchant;

  const onPurchase = () => {
    if (
      (currentArticle.refundable === 1 && currentArticle.result > 0) ||
      currentArticle.tag !== '0'
    ) {
      setIsDisabledModal(true);
      return;
    }
    currentArticle.amount !== 0
      ? setPayModal(true, false)
      : confirmPurchase(currentArticle.amount);
  };

  /**
   * 控制PayModal显示状态
   * @param isVisible
   * @param isControllLoading
   */
  const setPayModal = (isVisible, isControllLoading = true) => {
    setIsPayModal(isVisible);
    if (isControllLoading) {
      setIsBuyDoing(isVisible);
    } else {
      setIsBuyDoing(!isVisible);
    }
  };
  /**
   * 支付成功后的处理
   */
  const onPaySuccess = (isSuccess: any) => {
    // isSuccess=1 刷新文章
    if (isSuccess) {
      dispatch({
        type: 'clientMerchant/getOrderDetail',
        payload: {
          articleId: Number(base64_decode(articleId))
            ? base64_decode(articleId)
            : articleId,
        },
      });
    }
    setPayModal(false);
  };

  // 点击立即付款事件
  const confirmPurchase = async (money: number, discountCard: any = {}) => {
    const payCallBack = onPaySuccess;
    // 若使用了优惠卡中的次卡和会员卡，则直接解锁
    if (discountCard.cardType === 1 || discountCard.cardType === 3) {
      dispatch({
        type: 'client/doDiscountCardPay',
        payload: {
          payChannel: discountCard.cardType === 1 ? 'times' : 'vip',
          articleId: Number(base64_decode(articleId))
            ? base64_decode(articleId)
            : articleId,
          payCardId: discountCard.id,
        },
        merchantId: currentMerchant.id,
        payCallBack,
      });
      return;
    }
    if (!buyMethod) {
      Toast.info('请选择支付方式');
      return;
    }
    money ? setPayModal(true) : null;
    // 考虑打折情况会出现小数，此处向上取整
    // 当文章金额为0时,必须要产生免费购买行为
    if (money === 0) {
      dispatch({
        type: 'client/payFreeArticle',
        payload: {
          payChannel: 'alipay',
          articleId: Number(base64_decode(articleId))
            ? base64_decode(articleId)
            : articleId,
        },
        merchantId: currentMerchant.id,
        payCallBack,
      });
      return;
    }

    switch (buyMethod.id) {
      // 微信支付
      case 1:
        await dispatch({
          type: 'client/doWechatPay',
          payload: {
            payChannel: 'wx_pub',
            articleId: Number(base64_decode(articleId))
              ? base64_decode(articleId)
              : articleId,
            appId: buyMethod.appid,
          },
          payCallBack,
        });

        return;
      // 支付宝支付
      case 2:
        await dispatch({
          type: 'client/doAlipayPay',
          payload: {
            payChannel: 'alipay_wap',
            articleId: Number(base64_decode(articleId))
              ? base64_decode(articleId)
              : articleId,
            appId: buyMethod.appid,
          },
          payCallBack,
        });
        return;
      // 余额支付
      case 3:
        await dispatch({
          type: 'client/doBalancePay',
          payload: {
            articleId: Number(base64_decode(articleId))
              ? base64_decode(articleId)
              : articleId,
          },
          merchantId: currentMerchant.id,
          payCallBack,
        });
        return;
    }
  };

  const onHome = () => {
    props.navigation.push('Home');
  };

  useFocusEffect(
    useCallback(() => {
      const listener = () => {
        const alipayOrderId = window.sessionStorage.getItem('alipayOrderId');
        if (alipayOrderId) {
          const payCallBack = onPaySuccess;
          window.sessionStorage.removeItem('alipayOrderId');
          if (alipayOrderId) {
            dispatch({
              type: 'client/getPayStatus',
              payload: { uuid: alipayOrderId, payCallBack },
            });
          }
        }
      };

      listener();
      //解决IOS 回退时导致的Pop状态问题
      window.addEventListener('popstate', listener);

      return () => window.removeEventListener('popstate', listener);
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      if (trade_no) {
        //通过 tradeNo 获取文章信息
        dispatch({
          type: 'client/getArticleIdByTradeNo',
          payload: { partyOrderId: trade_no },
        });
      } else if (articleId && currentClient.id) {
        //通过 articleId 获取文章信息
        dispatch({
          type: 'clientMerchant/getOrderDetail',
          payload: {
            articleId: Number(base64_decode(articleId))
              ? base64_decode(articleId)
              : articleId,
          },
        });
      }
    }, [dispatch, articleId, currentClient, from]),
  );

  useEffect(() => {
    // 避免文章id获取的作者ID和传入参数不一致以文章为准，导致内容混乱问题 ,
    if (currMerchantId && merchantId && merchantId !== currMerchantId) {
      //不一致直接切回首页
      navigation.goBack();
    }
  }, [currMerchantId, merchantId, dispatch, navigation]);

  const gzFlag = useSelector((state: any) => state?.client?.gzFlag);

  useEffect(() => {
    dispatch({
      type: 'client/setPurchaseArticleData',
      payload: {
        isClickBuyModal: false,
        isBuyDone: false,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    animateGoAhead();
  }, []);

  return currentMerchant.articleSwitchFlag === 0 ? (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text
        style={{
          fontSize: 22,
          textAlign: 'center',
          color: 'red',
          marginBottom: 15,
        }}>
        文章页已被平台封禁,请联系客服！
        <p>
          <a href="/download.html">点我下载APP进行使用</a>
        </p>
      </Text>
    </View>
  ) : (
    <SafeAreaView style={[styles.layout, { height: height - 48 }]}>
      <ScrollView style={styles.scrollLayout}>
        <MOrderDetails
          merchantInfo={currentMerchant}
          onHomeClick={onHome}
          id={currentArticle.id}
          title={currentArticle.title}
          mode={currentArticle.mode}
          period={currentArticle.period}
          refundable={currentArticle.refundable === 1}
          isWatermarked={currentArticle.isWatermarked}
          watermarkUrl={currentArticle.watermarkUrl}
          limitTime={currentArticle.limitTime}
          createTime={currentArticle.createTime}
          updateTime={currentArticle.updateTime}
          date={currentArticle.createTime}
          intro={currentArticle.intro}
          introImages={currentArticle.introImages}
          articleChunks={currentArticle.articleChunks ?? []}
          articleGame={currentArticle.description ?? ''}
          discountable={currentArticle.discountable}
          publishTime={currentArticle.publishTime}
          contestRules={currentArticle.contestRules}
          redLabel={currentArticle.redLabel}
          customLabel={currentArticle.customLabel}
          business={false}
          tag={currentArticle.tag}
          isPay={currentArticle.isPay}
          isPresale={currentArticle.isPresale}
          presaleTime={currentArticle.presaleTime}
          articleId={currentArticle.id}
          articlePlanId={currentArticle.articlePlanId}
          merchantId={currentArticle.merchantId}
          publishFlg={currentArticle.publishFlg}
        />
      </ScrollView>
      {(!currentArticle.articleChunks ||
        currentArticle.articleChunks.length === 0) &&
        currentArticle.mode == 0 &&
        currentArticle.articleChunks !== undefined && (
          <View style={styles.purchaseSection}>
            <View>
              <View style={styles.purchasePriceSection}>
                <View style={styles.purchasePriceSectionRow}>
                  <Text style={styles.purchasePriceTitle}>支付金额:</Text>
                  <Text style={styles.purchasePriceDiscount}>
                    {Math.ceil(currentArticle.amount)}元
                  </Text>
                </View>
                {currentArticle.discountable === 0 ? (
                  <View>
                    <Text style={styles.purchasePriceOrigin1}>
                      此料商家设置无折扣，所有优惠失效
                    </Text>
                  </View>
                ) : currentArticle.amount !== currentArticle.price ? (
                  <View>
                    <Text style={styles.purchasePriceOrigin}>
                      原价 {currentArticle.price}元 您为
                      {currentArticle.amount < currentArticle.price
                        ? `${currentArticle.discount}折`
                        : `${parseFloat(
                            (
                              currentArticle.amount / currentArticle.price
                            ).toPrecision(12),
                          )}倍`}
                      用户
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
            <TouchableOpacity
              onPress={onBeforePurchase}
              style={styles.purchaseBtnSection}>
              <Text style={styles.purchaseBtnText}>立即支付</Text>
            </TouchableOpacity>
          </View>
        )}
      {currentArticle.isPay == null && currentArticle.mode == 2 && (
        <View style={styles.purchaseSection}>
          <View>
            <View style={styles.purchasePriceSection}>
              <View style={styles.purchasePriceSectionRow}>
                <Text style={styles.purchasePriceTitle}>支付金额:</Text>
                <Text style={styles.purchasePriceDiscount}>
                  {Math.ceil(currentArticle.amount)}元
                </Text>
              </View>
              {currentArticle.discountable === 0 ? (
                <View>
                  <Text style={styles.purchasePriceOrigin1}>
                    此料商家设置无折扣
                  </Text>
                </View>
              ) : currentArticle.amount !== currentArticle.price ? (
                <View>
                  <Text style={styles.purchasePriceOrigin}>
                    原价 {currentArticle.price}元 您为
                    {currentArticle.amount < currentArticle.price
                      ? `${currentArticle.discount}折`
                      : `${parseFloat(
                          (
                            currentArticle.amount / currentArticle.price
                          ).toPrecision(12),
                        )}倍`}
                    用户
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
          <TouchableOpacity
            onPress={onBeforePurchase}
            style={styles.purchaseBtnSection}>
            <Text style={styles.purchaseBtnText}>立即支付</Text>
          </TouchableOpacity>
        </View>
      )}
      {currentArticle.isPay != null &&
        currentArticle.limitTime !== '' &&
        currentArticle.limitTime !== null && (
          <View style={styles.purchaseSection}>
            <View>
              <View style={styles.purchasePriceSection}>
                <View style={styles.purchasePriceSectionRow}>
                  <Text style={styles.limitTime}>
                    {dayjs(currentArticle.limitTime).format('YYYY-MM-DD HH:mm')}
                  </Text>
                  <Text style={styles.purchasePriceTitle}>到期</Text>
                </View>
              </View>
            </View>
            <TouchableOpacity
              onPress={onPurchase}
              style={styles.purchaseBtnSection}>
              <Text style={styles.purchaseBtnText}>立即续费</Text>
            </TouchableOpacity>
          </View>
        )}
      <Modal
        style={styles.disabledModal}
        visible={isDisabledModal}
        onClose={() => setIsDisabledModal(false)}
        transparent
        maskClosable>
        <View style={styles.confirmModalMainBody}>
          <Text style={styles.isDisabledModalTitle}>提示</Text>
          <Text style={styles.isDisabledModalText}>文章结果已出，停止购买</Text>
          <TouchableOpacity
            style={styles.isDisabledModalBtn}
            onPress={() => setIsDisabledModal(false)}>
            <Text style={styles.isDisabledModalBtnText}>知道了</Text>
          </TouchableOpacity>
        </View>
      </Modal>
      <PayModal
        visible={isPayModal}
        setPayModal={setPayModal}
        onTopup={() => {
          navigation.navigate('TopUp');
          setIsPayModal(false);
        }}
        articleAmount={currentArticle.amount}
        isBuyDoing={isBuyDoing}
        confirmPurchase={confirmPurchase}
        setBuyMethod={setBuyMethod}
        buyMethod={buyMethod}
      />
      {currentMerchant.pushPic && !gzFlag ? (
        <View style={styles.push}>
          <Animated.View style={[styles.around, { opacity: fadeInAnimated }]} />
          <TouchableOpacity
            style={styles.smallAround}
            onPress={() => setVisible(true)}>
            <View style={{ width: 16, height: 16, marginTop: 9 }}>
              <Image
                source={images.BELL}
                style={{ width: '100%', height: '100%' }}
              />
            </View>
            <Text style={styles.p2}>接收推送</Text>
          </TouchableOpacity>
        </View>
      ) : null}
      <Modal
        visible={visible2}
        maskClosable
        style={{ width: 300, height: 200, backgroundColor: 'white' }}
        transparent
        onClose={() => setVisible2(false)}>
        <View>
          <View style={styles.viewShotContainer1}>
            <TouchableOpacity
              style={{
                padding: 6,
                borderRadius: 8,
                backgroundColor: '#02A7F0',
              }}
              onPress={() => {
                if (currentMerchant.gzhImg) {
                  setWechatModalVisible(true);
                } else {
                  Toast.info('商家暂未传二维码');
                }
              }}>
              <Text style={styles.p3}>联系商家</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                padding: 6,
                borderRadius: 8,
                backgroundColor: '#70B603',
              }}
              onPress={() => setPingModalVisible(true)}>
              <Text style={styles.p3}>联系平台客服</Text>
            </TouchableOpacity>
          </View>
          <View style={{ marginTop: 15 }}>
            <Text style={{ fontSize: 12, color: '#F59A23' }}>
              注意：请优先联系商家处理，处理不好的，平台再次介入处理。
            </Text>
          </View>
        </View>
      </Modal>
      <MCustomerSupportModal
        visible={wechatModalVisible}
        desc="添加商家微信"
        gzhImg={currentMerchant.customerQr}
        wechatNumber={currentMerchant.wechatNumber}
        customerQr={currentMerchant.customerQr}
        onClose={() => setWechatModalVisible(false)}
      />
      <MCustomerSupportModal
        visible={pingModalVisible}
        desc="平台客服微信"
        customerQr={kf}
        wechatNumber={weichat}
        onClose={() => setPingModalVisible(false)}
      />
      <Modal
        visible={visible}
        maskClosable
        transparent
        onClose={() => setVisible(false)}
        style={styles.container}>
        <View>
          <View
            onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
            style={styles.viewShotContainer}>
            <Image
              source={images.PUSH_UTOP}
              style={{ width: width, height: 80 }}
            />
            <Image
              source={{ uri: currentMerchant.pushPic }}
              style={{
                width: width - 100,
                height: width - 100,
                border: '.5px solid #a36d6d',
              }}
            />
            <Image
              source={images.PUSH_U1135}
              style={{ width: width, height: 110 }}
            />
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  layout: {
    // height,
    backgroundColor: colors.WHITE,
  },
  scrollLayout: {
    paddingHorizontal: 16,
  },
  headerTitle: {
    color: colors.GRAY20,
    fontSize: 18,
    lineHeight: 25,
    marginBottom: 4,
    fontWeight: '700',
  },
  labelSection: { flexDirection: 'row' },
  topLeftPeriodSection: {
    backgroundColor: colors.MEDIUM_SLATE_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    borderRadius: 4,
    marginRight: 8,
    padding: 2.5,
  },
  topLeftPeriodText: { color: colors.WHITE, fontSize: 11 },
  labelNotRefundedImage: { width: 52, height: 16 },
  articleDate: { color: colors.GRAY, fontSize: 13, marginTop: 8 },
  watermarkBG: {
    resizeMode: 'repeat',
  },
  contentSection: { flex: 1, marginTop: 8, marginBottom: 8 },
  orderLogo: {
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 8,
    width: 151,
    height: 63,
  },
  contentText: { color: colors.TUNDORA, fontSize: 14, lineHeight: 20 },
  noDataSection: { alignItems: 'center', marginTop: 40 },
  noDataImage: { width: 235, height: 160 },
  noDataText: { color: colors.DUSTY_GRAY, fontSize: 13 },
  purchaseSection: {
    position: 'absolute',
    backgroundColor: colors.WILD_SAND,
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    width: '100%',
    bottom: 0,
    zIndex: 999,
  },
  purchasePriceSectionRow: { flexDirection: 'row', alignItems: 'center' },
  purchasePriceSection: { flexDirection: 'column', alignItems: 'center' },
  purchasePriceTitle: { color: colors.TUNDORA, fontSize: 16, marginRight: 4 },
  purchasePriceDiscount: {
    color: colors.RED_00,
    fontSize: 16,
    fontWeight: '700',
    marginRight: 7.5,
  },
  purchasePriceOrigin1: {
    color: colors.GRAY,
    fontSize: 12,
    marginRight: 7.5,
    textDecorationStyle: 'solid',
  },
  purchasePriceOrigin: {
    color: colors.GRAY,
    fontSize: 12,
    marginRight: 7.5,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  purchasePriceDiscountDesc: { color: colors.GRAY, fontSize: 12 },
  purchaseBtnSection: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 35,
    width: 120,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  purchaseBtnText: { color: colors.WHITE, fontSize: 18 },
  limitTime: {
    color: colors.RED_00,
    fontSize: 16,
    fontWeight: '700',
    marginRight: 7.5,
  },

  disabledModal: {
    height: 200,
  },
  confirmModalMainBody: {
    flex: 0,
    backgroundColor: colors.WHITE,
    height: 240,
    width: 290,
    alignSelf: 'center',
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  isDisabledModalTitle: {
    color: colors.TUNDORA,
    fontSize: 20,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  isDisabledModalText: {
    color: colors.TUNDORA,
    fontSize: 16,
    alignSelf: 'center',
  },
  isDisabledModalBtn: {
    width: 160,
    height: 47,
    alignSelf: 'center',
    borderRadius: 7.5,
    backgroundColor: colors.RED_ALIZARIN,
    justifyContent: 'center',
    marginBottom: 20,
  },
  isDisabledModalBtnText: {
    color: colors.WHITE,
    fontSize: 18,
    alignSelf: 'center',
  },
  paymentMethodContainer: {
    paddingRight: 15,
    borderColor: colors.MERCURY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  paymentMethodItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  paymentMethodHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentName: {
    fontSize: 14,
    color: colors.TUNDORA,
    fontWeight: '700',
    marginLeft: 12,
  },
  payIcon: { height: 40, width: 40 },
  selectIcon: { height: 18, width: 18 },
  push: {
    position: 'absolute',
    right: 20,
    zIndex: 999,
    top: 100,
  },
  p2: {
    fontSize: 9,
    color: 'white',
  },
  btn3: {
    backgroundColor: '#FFA940',
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginTop: 15,
    borderRadius: 20,
    width: '70%',
    textAlign: 'center',
  },
  p3: {
    fontSize: 15,
    color: 'white',
  },
  around: {
    backgroundColor: '#FFD591',
    borderRadius: 31,
    width: 62,
    height: 62,
    justifyContent: 'center',
  },
  smallAround: {
    height: 48,
    width: 48,
    backgroundColor: '#FFA940',
    borderRadius: 24,
    position: 'absolute',
    top: 7,
    right: 7,
    // justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  smallAround1: {
    height: 48,
    width: 48,
    borderRadius: 4,
    position: 'absolute',
    top: 7,
    right: 7,

    // justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'transparent',
  },
  viewShotContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 20,
    paddingTop: 10,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  viewShotContainer1: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 40,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  disputeBtnText: {
    position: 'absolute',
    right: 0,
    color: colors.BLACK,
    backgroundColor: '#E5E5E5',
    borderRadius: 3,
    padding: 3,
    marginTop: -3,
  },
  imgStyle: {
    position: 'absolute',
    right: 13,
  },
  closeIconImage: { width: 12, height: 12 },
  textView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
  },
  p1: {
    fontSize: 12,
    borderColor: '#FA7C05',
    backgroundColor: '#F9F2D6',
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    color: '#444444',
  },
  footerColor: {
    backgroundColor: '#FAF7BA',
  },
  footerPColor: {
    color: '#D8370B',
  },
});

export default OrderDetailScreen;
