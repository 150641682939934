/*
 * @Author: lizhengkai
 * @Date: 2022-04-14 15:54:38
 * @FilePath: /msll-client/src/screens/Client/MyPage/PromotionWallet.tsx
 */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  ScrollView,
} from 'react-native';
// import { Flex } from '@ant-design/react-native';
import Flex from '@ant-design/react-native/lib/flex';
import { colors, images } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { MAllLoadedBox, MEmptyBox } from '../../../components';
import dayjs from 'dayjs';
import { getWithdrawRatio } from '../../../services/client/client';
import HTML from 'react-native-render-html';
import Modal from '@ant-design/react-native/lib/modal';

const PromotionWallet = () => {
  const earning = useSelector((state: any) => state.client.earning);
  const withdraw = useSelector((state: any) => state.client.withdraw);
  const promotionIncome = useSelector(
    (state: any) => state.client.promotionIncome,
  );
  const [orderType, setOrderType] = useState(1);
  const dispatch = useDispatch();
  const [withdrawRatio, setWithdrawRatio] = useState<number>(0);

  const fetchData = useCallback(
    (page) => {
      orderType === 1
        ? dispatch({
            type: 'client/earningPageList',
            payload: {
              currentPage: page,
              pageSize: 10,
            },
          })
        : dispatch({
            type: 'client/withdrawPageList',
            payload: {
              currentPage: page,
              pageSize: 10,
            },
          });
    },
    [dispatch, orderType],
  );

  useEffect(() => {
    dispatch({ type: 'client/getPromotionEarnings', payload: {} });
    orderType === 1
      ? dispatch({
          type: 'client/earningPageList',
          payload: {
            currentPage: 1,
            pageSize: 10,
          },
        })
      : dispatch({
          type: 'client/withdrawPageList',
          payload: {
            currentPage: 1,
            pageSize: 10,
          },
        });
  }, [dispatch, orderType]);

  /** 查询推广分成 */
  const getWithdrawRatioData = useCallback(async () => {
    const { data } = await getWithdrawRatio();
    setWithdrawRatio(data);
  }, []);
  useEffect(() => {
    getWithdrawRatioData();
  }, [getWithdrawRatioData]);

  const agreementsByCode = useSelector(
    (state: any) => state.agreement.agreementsByCode,
  ) || { promotion_note: '' };

  const [tgsmModalVisible, setTgsmModalVisible] = useState(false);

  useEffect(() => {
    tgsmModalVisible &&
      dispatch({
        type: 'agreement/getByCode',
        payload: { code: 'promotion_note' },
      });
  }, [dispatch, tgsmModalVisible]);

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>提成</Text>
            <Text style={styles.merchantItemDesc}>
              下单时间：{dayjs(item.orderTime).format('YYYY-MM-DD HH:mm')}
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ color: colors.RED_00 }}>+{item.fee}</Text>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );
  const withdrawal = ({ item }) => (
    <>
      <TouchableOpacity style={styles.merchantItemMain}>
        <View style={styles.merchantItemContentSection}>
          <View style={styles.merchantItemInfoSection}>
            <Text style={styles.merchantItemName}>提现</Text>
            <Text style={styles.merchantItemDesc}>
              {dayjs(item.updateTime).format('YYYY-MM-DD HH:mm')}
            </Text>
          </View>
        </View>
        <View>
          <Text>-{item.fee}</Text>
        </View>
      </TouchableOpacity>
      <View style={styles.merchantItemBottomLine} />
    </>
  );
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={{ padding: 16 }}>
        <TouchableOpacity activeOpacity={1} style={styles.header}>
          <Image
            style={styles.background}
            source={images.PERSONAL_CENTER_WALLET_BG}
          />
          <View style={styles.headerWrapper}>
            <View style={styles.headerSection}>
              <Flex>
                <Flex.Item>
                  <View style={styles.headerTextContainer}>
                    <View style={styles.headerRight}>
                      <Text style={styles.headerText}>累计收益</Text>
                      <TouchableOpacity
                        style={styles.tgsmButton}
                        onPress={() => setTgsmModalVisible(true)}>
                        <Text style={styles.headerText}>推广说明</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <Text style={styles.figure}>
                    {promotionIncome?.sum?.toFixed(2) ?? '0.00'}
                  </Text>
                </Flex.Item>
              </Flex>
            </View>
            <View style={styles.borderContainer}>
              <View style={styles.border} />
            </View>
            <View style={styles.headerSection}>
              <Flex>
                <Flex.Item>
                  <View style={styles.headerTextContainer}>
                    <Text style={styles.headerText}>今日收益</Text>
                  </View>
                  <Text style={styles.figure}>
                    {promotionIncome?.today?.toFixed(2) ?? '0.00'}
                  </Text>
                  <Text style={styles.footText}>
                    日收益第二天自动到卡 收益提现需{withdrawRatio * 100}
                    %的手续费
                  </Text>
                </Flex.Item>
                <Flex.Item style={styles.leftText}>
                  <View style={styles.headerTextContainer}>
                    <Text style={styles.headerText}>昨日收益</Text>
                  </View>
                  <Text style={styles.figure}>
                    {promotionIncome?.yesterday?.toFixed(2) ?? '0.00'}
                  </Text>
                </Flex.Item>
              </Flex>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.menuSection}>
        <TabItem
          isActive={orderType === 1}
          text="收益记录"
          onPress={() => setOrderType(1)}
        />
        <TabItem
          isActive={orderType === 2}
          text="日收益统计"
          onPress={() => setOrderType(2)}
        />
      </View>
      {orderType === 1 ? (
        <FlatList
          data={earning?.records}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          refreshing={false}
          onRefresh={() => fetchData(1)}
          onEndReached={() => {
            if (!earning?.isAllLoaded) {
              fetchData(earning?.page);
            }
          }}
          ListEmptyComponent={<MEmptyBox title="暂无数据" />}
          ListFooterComponent={
            earning?.records.length && earning?.isAllLoaded ? (
              <MAllLoadedBox />
            ) : null
          }
        />
      ) : (
        <FlatList
          data={withdraw?.records}
          renderItem={withdrawal}
          keyExtractor={(item) => item.id}
          refreshing={false}
          onRefresh={() => fetchData(1)}
          onEndReached={() => {
            if (!withdraw?.isAllLoaded) {
              fetchData(withdraw?.page);
            }
          }}
          ListEmptyComponent={<MEmptyBox title="暂无数据" />}
          ListFooterComponent={
            withdraw?.records.length && withdraw?.isAllLoaded ? (
              <MAllLoadedBox />
            ) : null
          }
        />
      )}
      <Modal
        title="推广说明"
        transparent
        maskClosable
        onClose={() => setTgsmModalVisible(false)}
        visible={tgsmModalVisible}
        footer={[{ text: '取消', onPress: () => setTgsmModalVisible(false) }]}>
        <ScrollView
          style={{
            maxHeight: 200,
            marginTop: 5,
          }}>
          {agreementsByCode.promotion_note?.content !== '' && (
            <HTML
              source={{ html: agreementsByCode.promotion_note?.content ?? '' }}
            />
          )}
        </ScrollView>
      </Modal>
    </SafeAreaView>
  );
};
const TabItem = (props) => {
  const { isActive = true, text = '', onPress } = props;
  return (
    <TouchableOpacity style={styles.tabItem} onPress={onPress}>
      <Text style={isActive ? styles.activeMenuText : styles.passiveMenuText}>
        {text}
      </Text>
      {isActive && <View style={styles.activeTabBottomline} />}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  header: {
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 12,
  },
  tgsmButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.WHITE,
    borderWidth: 0.5,
    borderRadius: 16,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  headerWrapper: {},
  headerSection: {
    padding: 16,
  },
  headerFirstSection: {
    borderBottomColor: '#ffffff',
    borderBottomWidth: 1,
  },
  headerText: {
    fontSize: 14,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  headerTextMiddle: {
    fontSize: 11,
    color: '#ffffff',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footText: {
    fontSize: 11,
    color: '#ffffff',
    marginTop: 10,
  },
  leftText: {
    position: 'absolute',
    top: 0,
    left: 165,
  },
  headerTextContainer: {
    marginBottom: 12,
  },
  todayIncomeContainer: {
    flexDirection: 'row',
  },
  figure: {
    fontSize: 24,
    color: '#ffffff',
  },
  borderContainer: {
    paddingHorizontal: 16,
  },
  border: { borderTopColor: '#ffffff', borderTopWidth: 1 },
  tabItem: {
    height: '100%',
    justifyContent: 'center',
  },
  activeTabBottomline: {
    backgroundColor: colors.GUARDSMAN_RED,
    height: 3,
    width: 16,
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
  },
  activeMenuText: { color: colors.TUNDORA, fontSize: 14, fontWeight: '700' },
  passiveMenuText: { color: colors.TUNDORA, fontSize: 13 },
  menuSection: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottomColor: colors.N1500,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  merchantItemMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  },
  merchantItemContentSection: { flex: 1, flexDirection: 'row', marginTop: 4 },
  merchantItemProfileImage: { width: 60, height: 60, borderRadius: 60 },
  merchantItemNoticeNumberSection: {
    position: 'absolute',
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    backgroundColor: colors.RED_ORANGE,
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    top: -3.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  merchantItemInfoSection: { flex: 1, marginLeft: 8, marginTop: 4 },
  merchantItemName: {
    color: colors.TUNDORA,
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 4,
  },
  merchantItemDesc: {
    flex: 1,
    color: colors.GRAY,
    fontSize: 13,
    lineHeight: 16,
    textAlign: 'justify',
    marginTop: 5,
  },
  merchantItemBottomLine: {
    flex: 0,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 16,
    height: 1,
    backgroundColor: colors.MERCURY,
  },
});

export default PromotionWallet;
