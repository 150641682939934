import dvaCore from './dva';
import models from '../models';

const dva = dvaCore.createApp({
  initialState: {},
  models: models,
});

const store = dva.getStore();

export default store;
