/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react';
import {
  Image,
  ImageProps,
  ImageStyle,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
import imageUtil from '../utils/image';

type BackgroundViewProps = PropsWithChildren<{
  image?: keyof typeof imageUtil;
  Props?: {
    Image?: Partial<ImageProps>;
  };
  Style?: {
    Image?: ImageStyle;
  };
}> &
  ViewProps;

const BackgroundView = ({
  image,
  Props: { Image: imageProps = {} } = {},
  Style: { Image: imagePropsStyle = {} } = {},
  style: propsStyle,
  children,
  ...rest
}: BackgroundViewProps) => {
  return (
    <View style={[styles.container, propsStyle]} {...rest}>
      {!!image && (
        <Image
          style={[styles.background, imagePropsStyle]}
          source={imageUtil[image]}
          {...imageProps}
        />
      )}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    resizeMode: 'cover',
  },
});

export default BackgroundView;
