/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import Wechat from 'weixin-js-sdk';
import { getWechatSign } from '../services/client/order';

const config = {
  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  appId: 'wxff5fd711160025da', // 必填，公众号的唯一标识
  jsApiList: ['chooseWXPay', 'hideMenuItems'], // 必填，需要使用的JS接口列表
};

export default {
  namespace: 'wechat',
  state: {
    agreements: [],
    agreementsByCode: {},
  },
  effects: {
    *initWechat({}, { call }) {
      const reqUrl = encodeURIComponent(document.location.href);
      const { success, data } = yield call(getWechatSign, { reqUrl });
      if (success) {
        Wechat.config({ ...config, ...data, nonceStr: data.noncestr });
        Wechat.ready(() => {
          Wechat.hideMenuItems({
            menuList: [
              'menuItem:copyUrl',
              'menuItem:editTag',
              'menuItem:delete',
              'menuItem:favorite',
              'menuItem:originPage',
              'menuItem:readMode',
              'menuItem:openWithQQBrowser',
              'menuItem:openWithSafari',
              'menuItem:share:email',
              'menuItem:share:brand',
              'menuItem:share:qq',
              'menuItem:share:QZone',
              'menuItem:share:appMessage',
              'menuItem:share:timeline',
              'menuItem:share:qq',
              'menuItem:share:weiboApp',
              'menuItem:share:facebook',
            ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
          });
          console.info('wechat pay init success!');
        });
        Wechat.error(function (res) {
          console.error('wechat pay init Failed!', res);
        });
      } else {
        console.error('wechat request sign Failed!');
      }
    },
  },
  reducers: {},
};
